import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import DoctorIcon from "../../images/newsletter-icon.png";
import ClickHere from "../../images/click-here.gif";
import usePostData from "../../hooks/usePostData";
import { data } from "autoprefixer";

const NewsLetterPopup = ({ setShowPopup }) => {
  const url = "pop-up";
  const [formdata, setFormdata] = useState(null);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  //Function to download the pdf after the success message
  const download = () => {
    window.open(
      "https://s3.us-east-2.amazonaws.com/ringofhires.com/static/media/Top_3_Tips_for_Landing_Your_Dream_Job.pdf",
      "_blank"
    );
  };

  const processForm = (e) => {
    e.preventDefault();
    setFormdata({
      name: name,
      email: email,
    });
  };

  //Cookie function declaration
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const { data, error, message } = usePostData(url, formdata);

  useEffect(() => {
    if (data) {
      setMessage({ message: data.message, class: "newsletter-success" });
      setName("");
      setEmail("");
      setCookie("isPopupShow", "true", 365);
      setTimeout(() => {
        setShowPopup(false);
        download();
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({
        message: error.non_field_errors,
        class: "newsletter-error",
      });
    }
    if (error?.message) {
      setMessage({ message: error.message, class: "newsletter-error" });
    }
  }, [error]);

  //Hide the popup once the modal closed by the user without submit it
  const closePopup = () => {
    setCookie("isPopupShow", "true", 1);
  };

  return (
    <div>
      <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
        <div className="relative w-auto md:mx-auto md:my-6 max-w-lg m-auto">
          <div className="relative p-10 pt-14 flex flex-col w-full bg-red-500 border-0 rounded-lg custom_shadow outline-none focus:outline-none space-y-5">
            <h3 className="text-lg font-bold md:text-3xl text-white">
              Top 3 Tips For Your Dream <br /> Healthcare Jobs
            </h3>
            <button
              className="absolute -top-2 right-2 font-semibold text-white w-auto rounded-full border-2 border-white"
              onClick={() => {
                setShowPopup(false);
                closePopup();
              }}
            >
              <AiOutlineClose className="text-white" size="1.2em" />
            </button>

            <div>
              <form>
                <div className="mb-3">
                  <input
                    type="text"
                    id="contact_name"
                    name="contact_name"
                    value={name}
                    class="block w-full rounded-md border-yellow-400 border-2 h-14 p-5 focus:outline-none focus:shadow-outline bg-yellow-400 text-white placeholder-gray-100 font-medium"
                    placeholder="Your Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span className="block mt-1 text-left text-yellow-200 text-sm font-semibold">
                    {errors?.name}
                  </span>
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    id="email_address"
                    name="email_address"
                    value={email}
                    class="block w-full rounded-md border-yellow-400 border-2 h-14 p-5 focus:outline-none focus:shadow-outline bg-yellow-400 text-white placeholder-gray-100 font-medium"
                    placeholder="Your Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="block mt-1 text-left text-yellow-200 text-sm font-semibold">
                    {errors?.email}
                  </span>
                </div>
              </form>
            </div>

            <img
              src={ClickHere}
              className="w-36 md:w-44 h-full mx-auto cursor-pointer"
              onClick={processForm}
            />

            <p className="text-lg md:text-2xl font-semibold text-white">
              Get Your <span className="text-yellow-400">FREE</span> PDF
            </p>
            {commonMessage && (
              <p className={`capitalize ${commonMessage.class}`}>
                {commonMessage.message}
              </p>
            )}
            <img
              src={DoctorIcon}
              className="w-20 md:w-28 h-auto absolute right-0 bottom-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetterPopup;
