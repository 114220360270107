const fields = {
  user: "",
  position: { id: 0, name: "Position" },
  qualification: [],
  min_salary: 1,
  min_yearly_pay: "",
  max_salary: 50,
  max_yearly_pay: "",
  work_location: {
    address: "",
    latitude: "",
    longtitude: "",
  },
  benefit: [],
  speciality: "",
  image: "",
  no_of_hires: [],
  job_type_schedule: [],
  supplemental_pay: [],
  description: "",
  min_experience_years: 1,
  max_experience_years: 10,
  career_level: 1,
  employment_status: 1,
};

const checkFields = (data) => {
  const errors = {};
  // for (let key in fields) {
  //   if (!data[key]) {
  //     errors[key] = "This field is required";
  //   }
  // }
  if (!data.position || data.position?.id === 0) {
    errors["position"] = "Please choose a position";
  }
  if (data.work_location.address === "") {
    errors["address"] = "Please choose a location";
  }
  if (data.work_location.address === "invalid") {
    errors["address"] = "Please choose a valid location";
  }
  if (data.no_of_hires.length === 0) {
    errors["no_of_hires"] = "Please choose an option";
  }
  if (data.description === "") {
    errors["description"] = "Please enter the description";
  }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
