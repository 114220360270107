const fields = {
  old_password: "",
  new_password: "",
};

const checkFields = (data) => {
  const errors = {};
  const old_password = "";
  for (let key in fields) {
    if (!data[key]) {
      errors[key] = "This field is required";
    }
  }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
