import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FaWindowClose } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import useGetData from "../../../hooks/useGetData";
import checkCircle from "../../../images/JobApply/check-circle.png";
import { useEffect } from "react";
import usePostData from "../../../hooks/usePostData";

const JobReport = ({ showModal, setShowModal }) => {
  const user_id = localStorage.getItem("user_id");
  const [modalNo, setModalNo] = useState(1);
  const [reportData, setReportData] = useState({});
  const [err, setErr] = useState("");
  const user_role = localStorage.getItem("user_role");
  const [reportFormData, setReportFormData] = useState(null);
  const { jobid } = useParams();

  //hooks
  const { data: reportList } = useGetData(`reports/jobpost`);
  const { data, error, loading } = usePostData(
    `employees/${user_id}/report-job-posts`,
    reportFormData
  );

  useEffect(() => {
    console.log(`error`, error);
  }, [error]);

  const checkIsLoggedIn = () => {
    if (!user_id) {
      localStorage.setItem("job_id", jobid);
      window.location.href = "/login";
      return;
    }
  };

  const updateReport = (message, id) => {
    setModalNo(2);
    setReportData({ report: id, report_details: [], jobpost: jobid });
  };

  const updateReportDetails = (e, message, id) => {
    if (e.target.checked) {
      setErr("");
      setReportData((prev) => ({
        ...prev,
        report_details: [...prev.report_details, id],
      }));
    } else {
      let filterReportDetails = reportData?.report_details?.filter(
        (item) => item != id
      );
      setReportData((prev) => ({
        ...prev,
        report_details: filterReportDetails,
      }));
    }
  };

  const reportSubmit = () => {
    if (reportData?.report_details?.length !== 0) {
      setReportFormData(reportData);
      setModalNo(4);
      setTimeout(() => {
        setReportData("");
        setModalNo(1);
        setShowModal(false);
      }, 2000);
    } else {
      setErr("Please select an option");
    }
  };

  const closeModal = () => {
    setReportData("");
    setErr("");
    setShowModal(false);
    setModalNo(1);
  };

  useEffect(() => {
    console.log(`reportData`, reportData);
  }, [reportData, reportList]);

  return (
    <div>
      {showModal === true && checkIsLoggedIn()}
      {user_id && showModal ? (
        user_role === "employee" ? (
          <>
            {modalNo === 1 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-4/5 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl normal-case">
                        Why are you reporting this?
                      </h3>
                      <button
                        onClick={() => {
                          setShowModal(false);
                          setModalNo(1);
                        }}
                        className="relative bg-white -right-11 -top-6"
                      >
                        <FaWindowClose size="1.4em" color="black" />
                      </button>
                    </div>
                    {/*body*/}
                    {reportList?.data?.map((item) => (
                      <button
                        onClick={() => {
                          updateReport(item?.message, item?.id);
                        }}
                        className="relative flex-auto p-6 border-b border-solid rounded-t border-blueGray-200"
                      >
                        <div className="flex justify-between w-full font-semibold text-gray-500 md:items-center details font_small_light">
                          <div className="space-y-6">
                            <h1>{item?.message}</h1>
                          </div>
                          <IoIosArrowForward color="grey" size="1.5em" />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {modalNo === 2 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                  {/*content*/}
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                      <h3 className="text-xl font-semibold md:text-2xl normal-case">
                        Tell us a little more
                      </h3>
                      <button
                        onClick={closeModal}
                        className="relative bg-white -right-11 -top-6"
                      >
                        <FaWindowClose color="black" size="1.4em" />
                      </button>
                    </div>
                    {/*body*/}
                    {reportData?.report &&
                      reportList?.data
                        ?.filter((item) => {
                          return item?.id === reportData?.report;
                        })
                        .map((items) =>
                          items?.details.map((subItems) => (
                            <div className="relative flex items-center justify-center p-6 border-b border-solid rounded-t border-blueGray-200">
                              <input
                                type="checkbox"
                                checked={reportData?.report_details.includes(
                                  subItems?.id
                                )}
                                name="type"
                                onChange={(e) => {
                                  updateReportDetails(
                                    e,
                                    subItems?.message,
                                    subItems?.id
                                  );
                                }}
                                className="w-5 h-5 mr-2 rounded-lg checked:bg-blue-600 checked:border-transparent"
                              />

                              <div className="flex justify-between w-full font-semibold text-gray-500 md:items-center details font_small_light">
                                <div className="space-y-6">
                                  <h1>{subItems?.message}</h1>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                    <p className="text-center form_error_message">{err}</p>
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                      {/* <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                      <div className="flex space-x-2">
                        <button
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn text-gray-600 bg-transparent border hover:border-main"
                          onClick={() => {
                            setModalNo(1);
                            setErr("");
                          }}
                        >
                          Back
                        </button>
                        <button
                          onClick={() => {
                            reportSubmit();
                          }}
                          className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalNo === 4 && (
              <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6 ">
                  {/*content*/}
                  <div className="relative flex flex-col w-full border-0 rounded-lg outline-none bg-main custom_shadow focus:outline-none">
                    <div className="flex flex-col items-center justify-center gap-4 p-6 border-t border-solid rounded-b border-blueGray-200">
                      <img src={checkCircle} alt="" width="" />
                      <h2 className="text-3xl font-semibold text-center text-white">
                        Job Reported!
                      </h2>
                      <p className="w-10/12 text-xl text-center text-white ">
                        Your report was successfully submitted
                      </p>
                      <div className="flex space-x-2">
                        {/* <button
                        className="flex items-center justify-center w-full mt-3 bg-white text-main md:w-auto md:mt-0 btn btn-base"
                        onClick={() => {
                          setModalNo(2);
                        }}
                      >
                        ok
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
              {/*content*/}
              <div className="relative flex flex-col w-4/5 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold md:text-xl">
                    Only job seekers can report this job
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                      setModalNo(1);
                    }}
                    className="ml-4 text-red-600 font-base-semibold "
                  >
                    <FaWindowClose size="1.4em" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default JobReport;
