const fields = {
  first_name: "",
  last_name: "",
  position: { id: "", name: "" },
  location_latitude: 40,
  location_longitude: 66,
  country: "",
  city: "",
  state: "",
  address: "",
  dob: "",
  education_level: { id: 0, name: "Select Education" },
  desired_pay: 0,
  about_me: "",
  skills: [],
  phone: "",
  resume: "",
};

const checkFields = (data) => {
  const errors = {};
  for (let key in fields) {
    if (!data[key]) {
      errors[key] = "This field is required";
    }
  }

  if (!data.resume) {
    errors.resume = "This field is required";
  }

  if (
    data.position.id === "" ||
    data.position.name === "" ||
    Object.keys(data?.position).length === 0
  ) {
    errors.position = "This field is required";
  }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
