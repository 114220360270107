import { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import usePostData from "../../hooks/usePostData";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";

//Facebook appId created on facebook developer account
const appId = "607320350595828";

function Facebook() {
  const [formdata, setFormData] = useState(null);
  const [popup, setPopup] = useState(false);
  const [userRole, setUserRole] = useState("employee");
  const history = useHistory();

  const url = "social/fb-login";
  const { data, error, loading, code } = usePostData(url, formdata);

  //When the Google login throwing 400 error, popup should show
  useEffect(() => {
    if (code === 400) {
      setPopup(true);
    }
  }, [code]);

  //Facebook function to generate the data
  const responseFacebook = (res) => {
    console.log(res);
    setFormData({
      userID: res?.userID,
      email: res?.email,
      accessToken: res?.accessToken,
      user_role: "",
    });
  };

  //Call the API at the second time by selecting the user type
  const upgradeMe = () => {
    setFormData((prev) => ({ ...prev, user_role: userRole }));
  };

  //Action take place once the Facebook login success and when it has data
  useEffect(() => {
    if (data) {
      localStorage.setItem("accessToken", data.data.token);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("user_id", data.data.user_id);
      localStorage.setItem("user_role", data.data.user_role);
      localStorage.setItem("latitude", data.data["latitude "]);
      localStorage.setItem("longitude", data.data["longitude "]);
      localStorage.setItem("plan_value", data.data.plan_value);

      const route = localStorage.getItem("path");
      const job_id = localStorage.getItem("job_id");

      if (job_id) {
        window.location.href = `/job-details/${job_id}`;
        localStorage.removeItem("job_id");
      } else {
        window.location.href = route || "/profile/edit";
      }
    }
  }, [data]);

  return (
    <div>
      {popup && (
        <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
          <div className="relative w-11/12 md:w-1/2 lg:w-5/12 2xl:w-1/4 max-w-4xl mx-auto my-6 social-log">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                <h3 className="text-xl font-semibold md:text-2xl">
                  Choose Your Account Type
                </h3>

                <button
                  className="flex items-center justify-center font-semibold text-gray-500 w-auto"
                  onClick={() => {
                    setPopup(false);
                    history.go(0);
                  }}
                >
                  <AiOutlineClose className="text-gray-600" size="1.2em" />
                </button>
              </div>

              <div className="flex justify-center pt-6 social-but">
                <button
                  value="employee"
                  onClick={(e) => setUserRole(e.target.value)}
                  className={`btn ${
                    userRole === "employee" ? "btn-main" : ""
                  } mb-0`}
                >
                  {" "}
                  Job Seekers
                </button>
                <button
                  value="employer"
                  onClick={(e) => setUserRole(e.target.value)}
                  className={`btn ${
                    userRole === "employer" ? "btn-main" : ""
                  } ml-3`}
                >
                  {" "}
                  Looking For Hire
                </button>
              </div>

              <div className="p-6">
                <button
                  className="w-full mt-3 md:mt-0 btn btn-main"
                  onClick={() => {
                    upgradeMe();
                    setPopup(false);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <FacebookLogin
        appId={appId}
        autoLoad={false}
        fields="name,email,picture"
        //   onClick={this.componentClicked}
        callback={responseFacebook}
        cssClass="btnFacebook"
        icon="fa-facebook"
      />
    </div>
  );
}

export default Facebook;
