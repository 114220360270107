import { useContext } from "react";
import { useState, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import RohLogo from "../../../images/ROH-logo.png";
import { UserContext } from "../../../App";
import PaymentFaq from "./PaymentFaq";
import useGetData from "../../../hooks/useGetData";

function PlansAndPricing() {
  const { username } = useContext(UserContext);

  const customLogin = () => {
    localStorage.setItem("path", window.location.href);
    window.location.href = "/login";
  };

  const GotoMyPlan = () => {
    window.location.href = "/profile/my-plan";
  };

  const [pricingUrl, setPricingUrl] = useState(`plan-details`);
  const { data: PricingData } = useGetData(pricingUrl);
  const [choosePlan, SetChoosePlan] = useState();
  const [loading, setLoading] = useState(true);
  const { userRole } = useContext(UserContext);

  useEffect(() => {
    if (PricingData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [PricingData]);

  useEffect(() => {
    if (userRole === "employee") {
      SetChoosePlan("employee");
    } else if (userRole === "employer") {
      SetChoosePlan("employer");
    } else {
      SetChoosePlan("employee");
    }
  }, []);

  // pricing plan filtering based on the user type
  const pricingSortData = PricingData?.data;
  const employee_ids = ["employee"];
  const employer_ids = ["employer"];

  //Filter only the employee objects from the pricing array
  const employeeSortedData = pricingSortData?.filter((i) =>
    employee_ids.includes(i.applicable_user_type_name)
  );

  //Convert the number of billing periods in months value to "12.34" format
  function monthlyTotal(price) {
    const em1 = price.toFixed(2);
    return em1;
  }

  //Filter only the employer objects from the pricing array
  const employerSortedData = pricingSortData?.filter((i) =>
    employer_ids.includes(i.applicable_user_type_name)
  );

  // Check the trial period of the particular user
  const { data: trialStatus } = useGetData("flag-check");
  const isTrialed = trialStatus?.data?.is_trialed;
  

  return (
    <>
      <div className="minHeight container-wrapper bg-main py-10">
        <div className="text-white text-center mb-12">
          <h2 className="lg:text-4xl font-semibold mb-5 text-3xl">
            Plans & Pricing
          </h2>
          {/* <p className="font-medium">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
          </p> */}
        </div>

        <div className="items-center justify-center flex mb-4">
          <button
            href="#"
            className={`bg-white btn-base ${
              choosePlan == "employee"
                ? "bg-red-500 text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              SetChoosePlan("employee");
            }}
          >
            Job Seekers
          </button>
          <button
            href="#"
            className={`bg-white btn-base ml-3 ${
              choosePlan == "employer"
                ? "bg-red-500 text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              SetChoosePlan("employer");
            }}
          >
            Looking to Hire
          </button>
        </div>

        {!loading ? (
          <div className="flex flex-col md:flex-row space-y-5 md:space-x-2 lg:space-x-5 md:space-y-0 justify-center">
            {choosePlan === "employee" &&
              employeeSortedData?.map((single_employee_item, index) => (
                <div
                  className="pricing-card bg-white px-10 pt-10 pb-5 md:px-5 md:pt-5 lg:px-10 lg:pt-10 rounded-lg flex-initial w-full md:w-4/12 lg:w-4/12 xl:w-3/12 flex flex-col justify-between"
                  key={index}
                >
                  <div className="pricing-content">
                    <div className="pricing-head flex justify-between">
                      <h3 className="text-2xl font-bold text-black mb-2">
                        {single_employee_item.plan_name} Membership
                      </h3>
                      {single_employee_item.name != 1 && (
                        <img src={RohLogo} className="w-7 h-full" />
                      )}
                    </div>
                    <p className="font-semibold text-gray-600 md:h-14 xl:h-10 2xl:h-6 capitalize">
                      {single_employee_item.tag_line}
                    </p>
                    <div className="text-4xl text-red-500 font-bold py-5">
                      ${single_employee_item.monthly_price}{" "}
                      {single_employee_item.monthly_price !== "0.00" ? (
                        <sup>
                          <span className="text-sm text-gray-600">
                            {" "}
                            / Month
                          </span>
                        </sup>
                      ) : (
                        ""
                      )}
                    </div>

                    {single_employee_item.billing_period_in_months > 1 && (
                      <p>
                        <span className="text-main font-semibold">
                          ${single_employee_item.monthly_price}
                        </span>
                        /month for{" "}
                        {single_employee_item.billing_period_in_months} months.
                        <br />
                        Billed one time as{" "}
                        <span className="text-main font-semibold">
                          $
                          {monthlyTotal(
                            single_employee_item.monthly_price *
                              single_employee_item.billing_period_in_months
                          )}
                        </span>
                      </p>
                    )}

                    <hr className="mt-2" />

                    <div className="privilege-list my-5">
                      <ul className="space-y-2">
                        {single_employee_item?.description.map(
                          (single_feature, index) => (
                            <li className="flex items-top" key={single_feature}>
                              <div>
                                <BsCheck2Circle
                                  className="mr-3 mt-1 text-main stroke-1"
                                  size="1.2em"
                                />
                              </div>
                              {single_feature.description}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  {!username ? (
                    single_employee_item.trial_period > 0 ? (
                      <div className="get-started-butt">
                        <button
                          class="bg-transparent hover:bg-main text-main text-xl font-semibold hover:text-white p-4 border border-main hover:border-transparent rounded-lg w-full block text-center"
                          onClick={customLogin}
                        >
                          Start Free Trial
                        </button>
                      </div>
                    ) : (
                      <div className="get-started-butt">
                        <button
                          class="bg-transparent hover:bg-main text-main text-xl font-semibold hover:text-white p-4 border border-main hover:border-transparent rounded-lg w-full block text-center"
                          onClick={customLogin}
                        >
                          Get Started
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="get-started-butt">
                      <button
                        class={`bg-transparent text-xl font-semibold hover:text-white p-4 border hover:border-transparent rounded-lg w-full block text-center ${
                          userRole === "employee" &&
                          single_employee_item.name ==
                            single_employee_item.logged_users_plan
                            ? "bg-red-500 border-red-500 text-white"
                            : "border-main text-main hover:bg-main hover:text-white"
                        }`}
                        onClick={GotoMyPlan}
                      >
                        {userRole === "employee" &&
                        single_employee_item.name ==
                          single_employee_item.logged_users_plan
                          ? "Current Plan"
                          : single_employee_item.trial_period > 0 && isTrialed == false
                          ? "Start Free Trial"
                          : "Get Started"}
                      </button>
                    </div>
                  )}
                </div>
              ))}

            {choosePlan === "employer" &&
              employerSortedData?.map((single_employer_item, index) => (
                <div
                  className="pricing-card bg-white px-10 pt-10 pb-5 md:px-5 md:pt-5 lg:px-10 lg:pt-10 rounded-lg flex-initial w-full md:w-4/12 lg:w-4/12 xl:w-3/12 flex flex-col justify-between"
                  key={single_employer_item}
                >
                  <div className="pricing-content">
                    <div className="pricing-head flex justify-between">
                      <h3 className="text-2xl font-bold text-black mb-2">
                        {single_employer_item.plan_name} Membership
                      </h3>
                      {single_employer_item.name != 1 && (
                        <img src={RohLogo} className="w-7 h-full" />
                      )}
                    </div>
                    <p className="font-semibold text-gray-600 md:h-14 xl:h-10 2xl:h-6 capitalize">
                      {single_employer_item.tag_line}
                    </p>
                    <div className="text-4xl text-red-500 font-bold py-5">
                      ${single_employer_item.monthly_price}{" "}
                      {single_employer_item.monthly_price !== "0.00" ? (
                        <sup>
                          <span className="text-sm text-gray-600">
                            {" "}
                            / Month
                          </span>
                        </sup>
                      ) : (
                        ""
                      )}
                    </div>

                    {single_employer_item.billing_period_in_months > 1 && (
                      <p>
                        <span className="text-main font-semibold">
                          ${single_employer_item.monthly_price}
                        </span>
                        /month for{" "}
                        {single_employer_item.billing_period_in_months} months.
                        <br />
                        Billed one time as{" "}
                        <span className="text-main font-semibold">
                          $
                          {monthlyTotal(
                            single_employer_item.monthly_price *
                              single_employer_item.billing_period_in_months
                          )}
                        </span>
                      </p>
                    )}

                    <hr className="mt-2" />

                    <div className="privilege-list my-5">
                      <ul className="space-y-2">
                        {single_employer_item?.description.map(
                          (single_feature, index) => (
                            <li className="flex items-top" key={single_feature}>
                              <div>
                                <BsCheck2Circle
                                  className="mr-3 mt-1 text-main stroke-1"
                                  size="1.2em"
                                />
                              </div>
                              {single_feature.description}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  {!username ? (
                    single_employer_item.trial_period > 0 ? (
                      <div className="get-started-butt">
                        <button
                          class="bg-transparent hover:bg-main text-main text-xl font-semibold hover:text-white p-4 border border-main hover:border-transparent rounded-lg w-full block text-center"
                          onClick={customLogin}
                        >
                          Start Free Trial
                        </button>
                      </div>
                    ) : (
                      <div className="get-started-butt">
                        <button
                          class="bg-transparent hover:bg-main text-main text-xl font-semibold hover:text-white p-4 border border-main hover:border-transparent rounded-lg w-full block text-center"
                          onClick={customLogin}
                        >
                          Get Started
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="get-started-butt">
                      <button
                        class={`bg-transparent text-xl font-semibold hover:text-white p-4 border hover:border-transparent rounded-lg w-full block text-center ${
                          userRole === "employer" &&
                          single_employer_item.name ==
                            single_employer_item.logged_users_plan
                            ? "bg-red-500 border-red-500 text-white"
                            : "border-main text-main hover:bg-main hover:text-white"
                        }`}
                        onClick={GotoMyPlan}
                      >
                        {userRole === "employer" &&
                        single_employer_item.name ==
                          single_employer_item.logged_users_plan
                          ? "Current Plan"
                          : single_employer_item.trial_period > 0 && isTrialed == false
                          ? "Start Free Trial"
                          : "Get Started"}
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ) : (
          <h5 className="p-3 text-white text-center">Loading...</h5>
        )}
      </div>

      {/* <div class="container-wrapper py-16 pricing-faq">
        <PaymentFaq />
      </div> */}
    </>
  );
}

export default PlansAndPricing;
