const useToken = (content_type='application/json') => {
    
    let defaultOptions = null;
    
    
    let token = localStorage.getItem('accessToken');
    
    let full_name = localStorage.getItem('full_name');
    let role = localStorage.getItem('role');
    

    let headerData ={'Content-Type': content_type}

    if(token){
        headerData ={...headerData,Authorization:`Token ${token}`}
    }

    defaultOptions = {
        headers: headerData,
    };
    
    return {token,full_name,role,defaultOptions};
}
 
export default useToken;