import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import facebookImg from "../../images/Footer/facebook.svg";
import googleImg from "../../images/Footer/google.svg";
import linkedinImg from "../../images/Footer/linkedin.svg";
import twitterImg from "../../images/Footer/twitter.svg";
import instagramImg from "../../images/Footer/instagram.svg";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../custom.css";
import { fields, checkFields } from "../../helpers/ContactUsSchema";

import useGetData from "../../hooks/useGetData";
import usePostData from "../../hooks/usePostData";

function ContactUs() {
  const url = "feedback";
  const loader = <span className="text-blue-500">Sending...</span>;

  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  const { data: feedbackUserType } = useGetData("feedback/user-type");

  const { data, error, loading } = usePostData(url, formdata);

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };
  const updateUserType = (e, data) => {
    setValue({ ...values, [e.target.name]: data });
  };

  //Timeout for the message
  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setMessage({ message: data?.message, class: "success hidden" });
      }, 3000);
      setValue({ contact_name: "", email_address: "", description: "" });
    }
  }, [data]);

  useEffect(() => {
    if (error?.non_field_errors) {
      setTimeout(() => {
        setMessage({ message: error.non_field_errors, class: "error hidden" });
      }, 3000);
    }
  }, [error]);

  const updatePhone = (value, country) => {
    setValue({ ...values, mobile_number: value });
    console.log(`Phone number....`, values);
  };

  const processForm = (e) => {
    e.preventDefault();
    const errList = checkFields(values);
    if (!errList) {
      setFormData(values);
      setErrors({ email_address: "" });
    } else {
      setErrors(errList);
    }
  };

  useEffect(() => {
    if (data) {
      setMessage({ message: data?.message, class: "success" });
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  useEffect(() => {
    if (feedbackUserType) {
      const result = Object.entries(feedbackUserType.data);
      console.log(`result`, result);
    }
  }, [feedbackUserType]);

  return (
    <div class="inner-wrapper relative overflow-hidden xl:w-4/6 lg:mx-auto my-10 p-6">
      <h2 class="lg:text-4xl font-semibold mb-12 text-3xl">Contact Us</h2>
      <div class="block">
        <div class="lg:col-span-3">
          <div class="rounded-md border-2 border-gray-100 shadow-lg p-5 mb-7">
            <h1 class="text-gray-600">
              For any queries please call us between 9 am to 6 pm(Monday -
              Saturday)
            </h1>
            <div class="md:flex md:space-x-4 block">
              {/* <div class="custom-phone flex md:mr-5 items-center mb-2 md:mb-0">
                <PhoneIcon className="h-5 w-5 mr-3 text-blue-500" /> +1
                941-216-5957
              </div> */}
              <div class="custom-mail flex items-center">
                <MailIcon className="h-5 w-5 mr-3 text-blue-500" />
                <a href="mailto:info@ringofhires.com">info@ringofhires.com</a>
              </div>
              <div class="custom-mail flex items-center">
                <PhoneIcon className="h-5 w-5 mr-3 text-blue-500" />
                <a href="tel:813-485-5141">813-485-5141</a>
              </div>
            </div>
            <div class="my-5">
              <hr />
            </div>
            <div class="md:flex items-center">
              <div class="mr-3 font-semibold text-gray-600">Follow us on:</div>
              <div>
                <a
                  href="mailto:support@ringofhires.com"
                  className="mr-1 inline-block"
                >
                  <img
                    className="bg-white rounded-md shadow-md border-gray-300 w-8 md:w-10"
                    src={googleImg}
                    alt="Google icon"
                    title="Google"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://twitter.com/RingofHires"
                  className="mx-1 inline-block"
                  target="_blank"
                >
                  <img
                    className="bg-white rounded-md shadow-md border-gray-300 w-8 md:w-10"
                    src={twitterImg}
                    alt="Twitter icon"
                    title="Twitter"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/ringofhires"
                  className="mx-1 inline-block"
                  target="_blank"
                >
                  <img
                    className="bg-white rounded-md shadow-md border-gray-300 w-8 md:w-10"
                    src={linkedinImg}
                    alt="Linkedin icon"
                    title="Linkedin"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.facebook.com/ringofhires"
                  className="mx-1 inline-block"
                  target="_blank"
                >
                  <img
                    className="bg-white rounded-md shadow-md border-gray-300 w-8 md:w-10"
                    src={facebookImg}
                    alt="Facebook icon"
                    title="Facebook"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/ring.of.hires"
                  className="ml-1 inline-block"
                  target="_blank"
                >
                  <img
                    className="bg-white rounded-md shadow-md border-gray-300 w-8 md:w-10"
                    src={instagramImg}
                    alt="Instagram icon"
                    title="Instagram"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="rounded-md border-2 border-gray-100 shadow-lg p-5">
            <h2 className="text-2xl font-bold pb-5">Suggestion or feedback</h2>
            <p>
              We value your suggestions. Please select any one of the two
              options below, describe your query or suggestion and send it to
              us. We will reply as soon as possible.
            </p>

            <div className="mt-8 max-w-md">
              <form
                onSubmit={(e) => {
                  processForm(e);
                }}
              >
                <div className="grid grid-cols-1 gap-2">
                  <div className="mb-3">
                    <label
                      class="block mb-2 text-sm font-semibold text-gray-500"
                      htmlFor="contact_name"
                    >
                      Contact Name
                    </label>
                    <input
                      type="text"
                      id="contact_name"
                      name="contact_name"
                      value={values?.contact_name}
                      class="block w-full rounded-md border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
                      placeholder="Your name"
                      onChange={(e) => updateValue(e)}
                    />
                    <span className="form_error_message block mt-1">
                      {errors?.contact_name}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label
                      class="block mb-2 text-sm font-semibold text-gray-500"
                      htmlFor="email_address"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      id="email_address"
                      name="email_address"
                      value={values?.email_address}
                      class="block w-full rounded-md border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
                      placeholder="john@example.com"
                      onChange={(e) => updateValue(e)}
                    />
                    <span className="form_error_message block mt-1">
                      {errors?.email_address}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label
                      class="block mb-2 text-sm font-semibold text-gray-500"
                      htmlFor="mobile_number"
                    >
                      Phone
                    </label>
                    <PhoneInput
                      id="mobile_number"
                      country={"us"}
                      value={values?.mobile_number}
                      inputClass="mt-1 block w-full rounded-md border-gray-200 border-2 h-14 p-5 focus:outline-none focus:shadow-outline"
                      onChange={(mobile_number, country) =>
                        updatePhone(mobile_number)
                      }
                    />
                    <span className="form_error_message block mt-1">
                      {errors?.mobile_number}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label
                      class="block mb-2 text-sm font-semibold text-gray-500"
                      htmlFor="description"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      value={values?.description}
                      name="description"
                      class="mt-1 block w-full rounded-md border-gray-200 border-2 p-5 focus:outline-none focus:shadow-outline"
                      placeholder="Write your description"
                      rows="6"
                      onChange={(e) => updateValue(e)}
                    />
                    <span className="form_error_message block mt-1">
                      {errors?.description}
                    </span>
                  </div>

                  <div class="space-y-3 md:flex md:space-y-0">
                    <div class="mr-5 flex items-center">
                      <input
                        type="checkbox"
                        name="type"
                        class="h-5 w-5 rounded-lg checked:bg-blue-600 checked:border-transparent"
                        value="0"
                        checked={values?.type === "0"}
                        onChange={(e) => {
                          updateUserType(e, "0");
                        }}
                      />
                      <label class="ml-2 text-gray-600">Job Seekers</label>
                    </div>
                    <div class="flex items-center">
                      <input
                        type="checkbox"
                        name="type"
                        class="h-5 w-5 rounded-lg checked:bg-blue-600 checked:border-transparent"
                        value="1"
                        checked={values?.type === "1"}
                        onChange={(e) => {
                          updateUserType(e, "1");
                        }}
                      />
                      <label class="ml-2 text-gray-600">
                        Looking for Hires
                      </label>
                    </div>
                  </div>
                  <span className="form_error_message block">
                    {errors?.type}
                  </span>
                  <div class="mt-5">
                    <button class="w-full btn btn-main">
                      {loading ? loader : "Send"}
                    </button>
                  </div>
                  {commonMessage && (
                    <p className={commonMessage.class} id="resp-msg">
                      {commonMessage.message}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div class="sidebar-outer rounded-md border-2 border-gray-100 shadow-lg p-5">
          <a
            target="_blank"
            href="https://www.amazon.com/hz/audible/gift-membership-detail?tag=roh08-20&ref_=assoc_tag_ph_1524210806852&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&linkId=a52944b364597db248322774f14f5200"
          >
            Audible Gift Memberships{" "}
          </a>

          <div className="mt-10">
            <Sidebar />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ContactUs;
