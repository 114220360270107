import { useState, useContext, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import RohLogo from "../../../images/ROH-logo.png";
import useGetData from "../../../hooks/useGetData";
import { UserContext } from "../../../App";

const Pricing = ({ priceModal, setPriceModal }) => {
  const GotoMyPlan = () => {
    window.location.href = "/profile/my-plan";
  };

  const [pricingUrl, setPricingUrl] = useState(`plan-details`);
  const { data: PricingData } = useGetData(pricingUrl);
  const { userRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  // pricing plan filtering based on the user type
  const pricingSortData = PricingData?.data;
  console.log("pricingSortData", pricingSortData);
  const employee_ids = ["employee"];
  const employer_ids = ["employer"];

  //Filter only the employee objects from the pricing array
  const employeeSortedData = pricingSortData?.filter((i) =>
    employee_ids.includes(i.applicable_user_type_name)
  );
  //Convert the number of billing periods in months value to "12.34" format
  function employeeTotal(price) {
    const em1 = price.toFixed(2);
    return em1;
  }

  //Filter only the employer objects from the pricing array
  const employerSortedData = pricingSortData?.filter((i) =>
    employer_ids.includes(i.applicable_user_type_name)
  );
  //Convert the number of billing periods in months value to "12.34" format
  function employerTotal(price) {
    const em2 = price.toFixed(2);
    return em2;
  }

  useEffect(() => {
    if (PricingData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [PricingData]);

  return (
    <div>
      {priceModal ? (
        <>
          <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
            <div className="relative w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12 mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center p-5 rounded-t">
                  <h3 className="text-xl font-semibold md:text-3xl">
                    Plans & Pricing
                  </h3>
                  <button
                    className="absolute right-5 font-semibold text-gray-500 w-auto"
                    onClick={() => {
                      setPriceModal(false);
                    }}
                  >
                    <AiOutlineClose className="text-gray-600" size="1.2em" />
                  </button>
                </div>

                {/* Pixel code addition */}
                {/* <p className="block">
                  <img
                    src="https://www.jobs2careers.com/conversion2.php?p=8752"
                    width="1"
                    height="1"
                  />
                </p> */}
                {/* <p className="px-5 text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p> */}
                {/*body*/}
                {!loading ? (
                  <div className="flex flex-col md:flex-row space-y-5 md:space-x-2 lg:space-x-5 md:space-y-0 justify-center py-10 px-5">
                    {userRole === "employee"
                      ? employeeSortedData?.map(
                          (single_employee_item, index) => (
                            <div
                              className="pricing-card bg-white p-7 md:p-3 lg:p-7 rounded-lg flex-initial w-full md:w-4/12 lg:w-4/12 xl:w-3/12 flex flex-col justify-between border-2 border-gray-100 shadow-lg"
                              key={index}
                            >
                              <div className="pricing-content">
                                <div className="pricing-head flex">
                                  <h4 className="text-xl font-bold text-black mb-2">
                                    {single_employee_item.plan_name} Membership
                                  </h4>
                                  {single_employee_item.monthly_price !==
                                    "0.00" && (
                                    <img
                                      src={RohLogo}
                                      className="w-5 ml-3 h-full"
                                    />
                                  )}
                                </div>
                                <p className="font-semibold text-gray-600">
                                  {single_employee_item.tag_line}
                                </p>
                                <div className="text-3xl text-red-500 font-bold py-5">
                                  ${single_employee_item.monthly_price}{" "}
                                  {single_employee_item.monthly_price !==
                                  "0.00" ? (
                                    <sup>
                                      <span className="text-sm text-gray-600">
                                        {" "}
                                        / Month
                                      </span>
                                    </sup>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {single_employee_item.billing_period_in_months >
                                  1 && (
                                  <p>
                                    <span className="text-main font-semibold">
                                      ${single_employee_item.monthly_price}
                                    </span>
                                    /month for{" "}
                                    {
                                      single_employee_item.billing_period_in_months
                                    }{" "}
                                    months.
                                    <br />
                                    Billed one time as{" "}
                                    <span className="text-main font-semibold">
                                      $
                                      {employeeTotal(
                                        single_employee_item.monthly_price *
                                          single_employee_item.billing_period_in_months
                                      )}
                                    </span>
                                  </p>
                                )}

                                <hr className="mt-2" />

                                <div className="privilege-list my-5">
                                  <ul className="space-y-2">
                                    {single_employee_item?.description.map(
                                      (single_feature, index) => (
                                        <li
                                          className="flex items-top"
                                          key={single_feature}
                                        >
                                          <div>
                                            <BsCheck2Circle
                                              className="mr-3 mt-1 text-main stroke-1"
                                              size="1.2em"
                                            />
                                          </div>
                                          {single_feature.description}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>

                              <div className="get-started-butt">
                                <button
                                  class={`bg-transparent text-xl font-semibold hover:text-white p-4 border hover:border-transparent rounded-lg w-full block text-center ${
                                    single_employee_item.name ==
                                    single_employee_item.logged_users_plan
                                      ? "bg-red-500 border-red-500 text-white"
                                      : "border-main text-main hover:bg-main hover:text-white"
                                  }`}
                                  onClick={GotoMyPlan}
                                >
                                  {single_employee_item.name ==
                                  single_employee_item.logged_users_plan
                                    ? "Current Plan"
                                    : "Get Started"}
                                </button>
                              </div>
                            </div>
                          )
                        )
                      : employerSortedData?.map(
                          (single_employer_item, index) => (
                            <div
                              className="pricing-card bg-white p-7 md:p-3 lg:p-7 rounded-lg flex-initial w-full md:w-4/12 lg:w-4/12 xl:w-3/12 flex flex-col justify-between border-2 border-gray-100 shadow-lg"
                              key={index}
                            >
                              <div className="pricing-content">
                                <div className="pricing-head flex">
                                  <h4 className="text-xl font-bold text-black mb-2">
                                    {single_employer_item.plan_name} Membership
                                  </h4>
                                  {single_employer_item.monthly_price !==
                                    "0.00" && (
                                    <img
                                      src={RohLogo}
                                      className="w-5 ml-3 h-full"
                                    />
                                  )}
                                </div>
                                <p className="font-semibold text-gray-600">
                                  {single_employer_item.tag_line}
                                </p>
                                <div className="text-3xl text-red-500 font-bold py-5">
                                  ${single_employer_item.monthly_price}{" "}
                                  {single_employer_item.monthly_price !==
                                  "0.00" ? (
                                    <sup>
                                      <span className="text-sm text-gray-600">
                                        {" "}
                                        / Month
                                      </span>
                                    </sup>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {single_employer_item.billing_period_in_months >
                                  1 && (
                                  <p>
                                    <span className="text-main font-semibold">
                                      ${single_employer_item.monthly_price}
                                    </span>
                                    /month for{" "}
                                    {
                                      single_employer_item.billing_period_in_months
                                    }{" "}
                                    months.
                                    <br />
                                    Billed one time as{" "}
                                    <span className="text-main font-semibold">
                                      $
                                      {employeeTotal(
                                        single_employer_item.monthly_price *
                                          single_employer_item.billing_period_in_months
                                      )}
                                    </span>
                                  </p>
                                )}

                                <hr className="mt-2" />

                                <div className="privilege-list my-5">
                                  <ul className="space-y-2">
                                    {single_employer_item?.description.map(
                                      (single_feature, index) => (
                                        <li
                                          className="flex items-top"
                                          key={single_feature}
                                        >
                                          <div>
                                            <BsCheck2Circle
                                              className="mr-3 mt-1 text-main stroke-1"
                                              size="1.2em"
                                            />
                                          </div>
                                          {single_feature.description}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>

                              <div className="get-started-butt">
                                <button
                                  class={`bg-transparent text-xl font-semibold hover:text-white p-4 border hover:border-transparent rounded-lg w-full block text-center ${
                                    single_employer_item.name ==
                                    single_employer_item.logged_users_plan
                                      ? "bg-red-500 border-red-500 text-white"
                                      : "border-main text-main hover:bg-main hover:text-white"
                                  }`}
                                  onClick={GotoMyPlan}
                                >
                                  {single_employer_item.name ==
                                  single_employer_item.logged_users_plan
                                    ? "Current Plan"
                                    : "Get Started"}
                                </button>
                              </div>
                            </div>
                          )
                        )}
                  </div>
                ) : (
                  <h5 className="p-3 text-black text-center">Loading...</h5>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pricing;
