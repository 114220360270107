import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TextEditor({ textValue, setTextValue }) {
  const [value, setValue] = useState(textValue);
  setTextValue(value);
  return (
    <div className="normal-case">
      <ReactQuill
        value={value}
        onChange={setValue}
        theme="snow"
        bounds="#scrolling-container"
        scrollingContainer=".parent-scroll"
      />
    </div>
  );
}
export default TextEditor;
