import { useState, useEffect } from "react";
import axios from "axios";
import useToken from "./useToken.js";

const useGetData = (url, service = "main", refresh = false) => {
  const [data, setData] = useState(null);
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const { defaultOptions } = useToken();
  //{params: {slug: user_name}}
  let base_url;
  if (service === "main") {
    base_url = process.env.REACT_APP_API_URL;
  } else if (service === "search") {
    base_url = process.env.REACT_APP_SEARCH_URL;
  } else {
    base_url = process.env.REACT_APP_CHAT_URL;
  }

  useEffect(() => {
    if (url) {
      setLoader(true);
      axios
        .get(base_url + url, defaultOptions)
        .then((response) => {
          setData(response.data);
          setError(null);
          setLoader(false);
        })
        .catch((error) => {
          setData(null);
          setLoader(false);

          //set redirect to login if the user deleted from admin panel
          if (
            error?.response?.data?.data?.detail === "User inactive or deleted."
          ) {
            localStorage.clear();
            window.location.href = "/login";
          }

          if (error?.response?.data?.error) {
            setError(error.response.data.error);
          } else if (error?.response?.data?.data?.detail) {
            setError(error.response.data.data.detail);
          } else {
            setError({ non_field_error: "Something went wrong" });
          }
        });
    }
  }, [url, refresh]);
  return { data, error, loading };
};

export default useGetData;
