import { UserContext } from "../../App";
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import usePostData from "../../hooks/usePostData";

import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineLightBulb,
} from "react-icons/hi";
import GoogleLogin from "../elements/GoogleLogin";
import Facebook from "../elements/FacebookLogin";

const Login = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  const fields = { username: "", password: "" };
  const url = "users/session";
  const loader = <span className="text-blue-500">Sending...</span>;
  

  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [revealPassword, togglePasswordView] = useState(false);
  const [isValid, setIsValid] = useState("");

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };

  const processForm = (e) => {
    e.preventDefault();
    const username = e.target.value;
    // console.log(`values`, values.email);
    if (!emailRegex.test(values.username)) {
      setIsValid("Invalid email format");
    } else {
      setIsValid("");
      setFormData(values);
      // const route = localStorage.getItem("path");
      // window.location.href = route;
    }

    if (values.username == "" && values.password == "") {
      setFormData(values);
      setIsValid("");
    }
  };
  // console.log(localStorage.getItem("path"));
  const { data, error, loading } = usePostData(url, formdata);

  useEffect(() => {
    if (data) {
      setValue(fields);
      // setMessage(data.message);
      localStorage.setItem("accessToken", data.data.token);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("user_id", data.data.user_id);
      localStorage.setItem("user_role", data.data.user_role);
      localStorage.setItem("latitude", data.data["latitude "]);
      localStorage.setItem("longitude", data.data["longitude "]);
      localStorage.setItem("plan_value", data.data.plan_value);
      

      const route = localStorage.getItem("path");
      const job_id = localStorage.getItem("job_id");
      

      if (job_id) {
        window.location.href = `/job-details/${job_id}`;
        localStorage.removeItem("job_id");
        console.log(route, "1");
      } else {
        window.location.href = route || "/";
        console.log(route, "2");
      }
      console.log(`data`, data);
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage(error.non_field_errors);
    }
    if (error?.temp_token) {
      history.push(`/activate-account/${error.uid}/${error.temp_token}`);
    }
  }, [error]);

  return (
    <div className="minHeight relative flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg lg:w-8/12 xl:w-6/12">
        {commonMessage && (
          <p className="py-5 text-center text-red-500">{commonMessage}</p>
        )}
        <h1 className="my-5 text-3xl font-semibold text-black">Sign In</h1>

        <form
          onSubmit={(e) => {
            processForm(e);
          }}
        >
          <div className="mb-3">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="username"
            >
              Email Address
            </label>
            <input
              type="text"
              name="username"
              id="username"
              value={values.username}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="example@gmail.com"
              onChange={(e) => updateValue(e)}
            />
            <span className="form_error_message">{errors?.username}</span>
            {isValid && <p className="form_error_message">{isValid}</p>}
          </div>

          <div className="relative mb-3">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type={revealPassword ? "text" : "password"}
              name="password"
              id="password"
              value={values.password}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e) => updateValue(e)}
            />
            <span className="form_error_message">{errors?.password}</span>
            <HiOutlineEye
              onClick={() => togglePasswordView(!revealPassword)}
              className={`password_eye ${revealPassword ? "hidden" : ""}`}
            />
            <HiOutlineEyeOff
              onClick={() => togglePasswordView(!revealPassword)}
              className={`password_eye ${revealPassword ? "" : "hidden"}`}
            />
          </div>

          <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 justify-between mb-6 text-xs text-gray-500 sm:text-sm">
            <div>
              <input type="checkbox" className="cursor-pointer" />
              <p className="inline ml-2">Keep me signed in on this device</p>
            </div>
            <div>
              <a
                href="/forgot-password"
                className="font-semibold text-red-500"
                title="Forgot password"
              >
                <span className="block">Forgot password?</span>
                {/* <HiOutlineLightBulb className="text-sm sm:hidden" /> */}
              </a>
            </div>
          </div>

          <div className="mb-3">
            <button className="w-full btn btn-main">
              {loading ? loader : "Sign In"}
            </button>
          </div>
          </form>
          <div className="flex items-center justify-between">
            <div className="w-full h-0 mr-6 border-t border-gray-200"></div>
            <div className="text-sm text-gray-400">Or</div>
            <div className="w-full h-0 ml-6 border-t border-gray-200"></div>
          </div> 

          <div className="md:flex items-center justify-center my-5 text-center">
            <div className="mb-3 md:mb-0"><GoogleLogin /></div>
            <Facebook />
            {/* <div className="p-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/google.png"
                className="w-8 h-auto"
                alt="Sign Up with Google"
              />
            </div> */}

            {/* <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/apple.png"
                className="w-8 h-auto"
                alt="Sign Up with Apple"
              />
            </div>

            <div className="p-2 ml-2 rounded-md shadow cursor-pointer h-15 w-15 hover:shadow-lg">
              <img
                src="images/facebook.png"
                className="w-8 h-auto"
                alt="Sign Up with Facebook"
              />
            </div> */}
          </div> 

          <div className="my-5 text-center">
            <p className="text-sm text-gray-500">
              New to Ring of Hires?{" "}
              <a className="text-main" href="/signup">
                Create Account
              </a>{" "}
            </p>

            <a className="mt-5 text-sm text-main" href="/help-center">
              Help Center
            </a>

            <p className="mt-10 text-sm text-gray-500">
              By signing into account, you agree to Ring of Hires{" "}
              <a className="text-main" href="/terms-of-service">
                Terms of Service
              </a>{" "}
              and consent to our{" "}
              <a className="text-main" href="/privacy-policy">
                Privacy Policy
              </a>
            </p>
          </div>
     
      </div>
    </div>
  );
};

export default Login;
