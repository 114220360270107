import { useEffect, useState, useContext, useCallback, useRef } from "react";
import usePatch from "../../../hooks/usePatch";
import useGetData from "../../../hooks/useGetData";
import usePostData from "../../../hooks/usePostData";
import useDelete from "../../../hooks/useDelete";

import { MarkerContext, UserContext } from "../../../App";

import Select from "react-select";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactSlider from "react-slider";

import { fields, checkFields } from "../../../helpers/EmployeeSchema";

import ImageUploader from "../../elements/ImageUploader";
import PdfUploader from "../../elements/PdfUploader";
import SelectCreate from "../../elements/SelectCreate";

import { AiFillCloseCircle } from "react-icons/ai";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ProfileMap from "../../elements/ProfileMap";
import Pricing from "../../modals/PricingAndPlan/Pricing";

const EditEmployee = () => {
  const skillName = useRef("");
  const skillLevel = useRef("");

  const { userId } = useContext(UserContext);
  const url = `employees/${userId}`;
  const loader = <span className="text-blue-500">Sending...</span>;
  const [qualificationUrl, setQualificationUrl] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [formdata, setFormData] = useState(null);

  const [skillDeleteUrl, setSkillDeleteUrl] = useState(null);
  const [skillUpdateUrl, setSkillUpdateUrl] = useState(null);
  const [skillFormData, setSkillFormData] = useState(null);
  const [skillUpdateFormData, setSkillUpdateFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [skillErrors, setSkillErrors] = useState({
    skillName: null,
    skillLevel: null,
  });
  const [skill, setSkill] = useState(null);
  const [getUrl, setGetUrl] = useState(null);
  const [resumeUpdate, setResumeUpdate] = useState(false);

  const [address, setAddress] = useState();
  const [mapMarker, setMapMarker] = useState({
    lat: "",
    lng: "",
  });
  const [markerFlag, setmarkerFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [addressValidate, setAddressValidate] = useState(true);
  const initRef = useRef(true);
  const ref1 = useRef("");

  const calculateAnnualPay = useCallback((pay) => {
    return pay * 261 * 8;
  }, []);

  //Plan & Price modal box while update the employee profile at the first time
  const user_id = localStorage.getItem("user_id");
  const [priceModal, setPriceModal] = useState(false);
  const { data: profileCheck } = useGetData(
    `employees/${user_id}/profile-check`
  );
  const current_plan_value = localStorage.getItem("plan_value");
  console.log("current_plan_value..", current_plan_value);

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
    // setErrors(null);
  };

  const saveSingleValue = (e) => {
    setFormData({ [e.target.name]: e.target.value });
    setErrors(null);
  };

  const updatePositionSelectValue = (value) => {
    setValue({ ...values, position: value });
    setFormData({ position: value.id });
    setQualificationUrl(`qualifications?category_id=${value.id}`);
    setErrors(null);
  };

  const updateQualificationSelectValue = (value) => {
    setFormData({ education_level: value.id });
    setValue({ ...values, education_level: value });
  };

  const updatePhone = (value, country) => {
    setValue({
      ...values,
      phone: value,
      country_code: country.dialCode,
      country: country.countryCode,
    });
  };

  const savePhone = () => {
    const check = /^0*$/.test(values.phone);
    if (check) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        phone: "Please enter a valid phone number",
      }));

      return;
    }
    // else {
    //   setErrors(null);
    // }

    // const countryVal = values.country_code.slice(-1);
    // console.log("countryVal :>> ", countryVal);

    // const index = values.phone.indexOf(countryVal);
    // console.log("index :>> ", index);

    // const slicedPhone = values.phone.slice(index + 1);
    // console.log("slicedPhone :>> ", slicedPhone);

    setFormData({
      phone: values.phone,
      country_code: values.country_code,
      country: values.country,
    });
  };

  const updateDate = (value) => {
    setFormData({ dob: value?.toISOString().split("T")[0] });
    setValue({ ...values, dob: value?.toISOString().split("T")[0] });
    setErrors(null);
  };

  const refreshResume = () => {
    setGetUrl(`employees/${userId}`);
    setResumeUpdate(true);
    setErrors(null);
  };

  const updatePay = (value) => {
    setValue({ ...values, desired_pay: value });
  };

  const savePay = (value) => {
    setFormData({ desired_pay: value });
    setErrors(null);
  };

  const selectSkill = (skill) => {
    if (skill) {
      setSkill(skill);
      skillName.current.value = skill.skill_name;
    } else {
      setSkill(null);
      skillName.current.value = "";
    }
  };

  const removeSkill = (employee_skill_id) => {
    let skills = [...values.skills];
    skills = skills.filter(
      (skill) => skill.employee_skill_id != employee_skill_id
    );
    setValue((oldValues) => ({ ...oldValues, skills: [...skills] }));
    setSkillDeleteUrl(`employees/${userId}/skills/${employee_skill_id}`);
  };

  useEffect(() => {
    if (skillFormData) {
      setSkill(null);
      skillLevel.current.value = "";
      skillName.current.value = "";
    }
  }, [skillFormData]);

  const saveSkill = (e) => {
    e.preventDefault();
    if (
      skillName.current.value &&
      skillLevel.current.value &&
      /^\d+$/.test(skillLevel.current.value) &&
      skillLevel.current.value <= 100
    ) {
      let skillsToSave = values.skills ? [...values.skills] : [];
      let skillFound = false;

      skillsToSave = skillsToSave.map((skill) => {
        if (
          skill.skill_name.toUpperCase() ===
          skillName.current.value.toUpperCase()
        ) {
          skillFound = true;
          setSkillUpdateUrl(
            `employees/${userId}/skills/${skill.employee_skill_id}`
          );
          setSkillUpdateFormData({ skill_level: skillLevel.current.value });
          return { ...skill, skill_level: skillLevel.current.value };
        } else {
          return skill;
        }
      });

      if (skillFound) {
        setValue({ ...values, skills: skillsToSave });
      } else {
        setValue({
          ...values,
          skills: [
            ...values.skills,
            {
              skill_name: skillName.current.value,
              skill_level: skillLevel.current.value,
            },
          ],
        });
        setSkillFormData({
          skill_name: skillName.current.value,
          skill_level: skillLevel.current.value,
        });
      }
    }
    if (!skillName.current.value) {
      setSkillErrors((preErrors) => ({
        ...preErrors,
        skillName: "Please enter skill name",
      }));
    } else {
      setSkillErrors((preErrors) => ({ ...preErrors, skillName: "" }));
    }

    if (
      skillLevel.current.value &&
      /^\d+$/.test(skillLevel.current.value) &&
      skillLevel.current.value <= 100
    ) {
      setSkillErrors((preErrors) => ({ ...preErrors, skillLevel: "" }));
    } else {
      setSkillErrors((preErrors) => ({
        ...preErrors,
        skillLevel: "Please enter a number between 1 and 100",
      }));
    }
  };

  const handleChange = (address) => {
    ref1.current = address;
    setValue({ ...values, address: address });
    if (addressValidate) {
      setAddressValidate(false);
    }
  };

  const handleSelect = (address) => {
    // setValue({ ...values, address: address });
    ref1.current = "";
    geocodeByAddress(address)
      .then((results) => {
        const result = results[0].address_components;
        const country = result.find((item) => item.types.includes("country"));
        const postcode = result.find((item) =>
          item.types.includes("postal_code")
        );
        const state = result.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        const city = result.find((item) =>
          item.types.includes("administrative_area_level_2")
            ? item.types.includes("administrative_area_level_2")
            : item.types.includes("locality")
        );
        // const suburb = result.find((item) => item.types.includes("locality"));
        // const street = result.find((item) =>
        //   item.types.includes("sublocality")
        // );
        console.log(result);
        const street = result.find((item) =>
          item.types.includes("street_number")
        );
        const route = result.find((item) => item.types.includes("route"));
        const premise = result.find((item) => item.types.includes("premise"));
        const locality = result.find((item) => item.types.includes("locality"));
        const establishment = result.find((item) => item.types.includes("establishment"));
        const sublocality = result.find((item) =>
          item.types.includes("sublocality")
        );
        const neighborhood = result.find((item) => item.types.includes("neighborhood"));
        console.log(route,country,state,locality,sublocality,street,neighborhood,premise)
        let addressVal = "";

        // if (route || locality || sublocality || premise) {
        //   addressVal = `${street?.long_name ? street.long_name : ""} ${
        //     route?.long_name ? route.long_name + "," : ""
        //   } ${premise?.long_name ? premise.long_name + "," : ""} ${
        //     sublocality?.long_name ? sublocality.long_name : ""
        //   } ${locality?.long_name ? locality.long_name : ""}`;
        //   // addressVal = addressVal.trim();
        //   addressVal = addressVal.replace(/,\s*$/, "").trim();
        // } else {
        //   addressVal = address;
        // }

        if (establishment||neighborhood||premise||street|| route || sublocality || locality) {
          addressVal = `${establishment?.long_name ? establishment.long_name +", ": ""}${ 
            neighborhood?.long_name ? neighborhood.long_name +", " : ""}${
            premise?.long_name ? premise.long_name +", " : ""}${
            street?.long_name ? street.long_name +", ": ""}${
            route?.long_name ? route.long_name + ", " : ""}${
            sublocality?.long_name ? sublocality.long_name +", " : ""}`;
          // addressVal = addressVal.trim();
          addressVal = addressVal.replace(/,\s*$/, "").trim();
        } else {
          addressVal = address;
        }
        setAddressValidate(true);
        const obj = {
          ...values,
          address: addressVal,
          country: country ? country.long_name : "",
          state: state ? state.long_name : "",
          city: city
            ? city.long_name.includes("County")
              ? locality
                ? locality.long_name
                : sublocality
                ? sublocality.long_name
                : city.long_name
              : city.long_name
            : "",
          zipcode: postcode ? postcode.long_name : "",
          location_latitude: results[0].geometry.location.lat(),
          location_longitude: results[0].geometry.location.lng(),
        };
        setValue(obj);

        // const errList = checkFields(obj);
        // setErrors(errList);
        // if (!errList) {
        setFormData({
          address: addressVal,
          country: obj.country,
          state: obj.state,
          city: obj.city,
          zipcode: obj.zipcode,
          location_latitude: obj.location_latitude,
          location_longitude: obj.location_longitude,
        });
        // }

        // else {
        //   setMessage({
        //     message: "Please check entered fields",
        //     class: "error",
        //   });
        // }
        // obj = {
        //   ...obj,
        //   country: country ? country.long_name : "",
        //   postcode: postcode ? postcode.long_name : "",
        //   state: state ? state.long_name : "",
        //   city: city ? city.long_name : "",
        //   suburb: suburb ? suburb.long_name : "",
        //   street: street ? street.long_name : "",
        // };
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      const addressString = `${values.city} ${values.state} ${values.country}`;
      setAddress(addressString);
    }
  }, [mapMarker]);

  const getSelectedLocation = (location) => {
    setMapMarker((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  //   useEffect(() => {
  //     let addressVal = values;

  //     if (initRef1.current) {
  //       initRef1.current = false;
  //     } else {
  //       addressVal = {
  //         address: values.address,
  //         city: values.city,
  //         country: values.country,
  //         state: values.state,
  //         zipcode: values.postcode,
  //       };
  //       console.log(addressVal);
  //       const { data, error } = usePatch(url, addressVal);
  //       console.log(data);
  //     }
  //   }, [refresh]);

  const handleMapAddress = (
    route,
    country,
    postcode,
    state,
    city,
    lat,
    lng
  ) => {
    const obj = {
      ...values,
      address: route ? route : "",
      country: country ? country.long_name : "",
      state: state ? state.long_name : "",
      city: city ? city.long_name : "",
      zipcode: postcode ? postcode.long_name : "",
      location_latitude: lat,
      location_longitude: lng,
    };
    setValue(obj);

    if (address !== "Location not available") {
      const errList = checkFields(obj);
      setErrors(errList);
      if (!errList) {
        setFormData({
          address: route ? route : "",
          country: country ? country.long_name : "",
          state: state ? state.long_name : "",
          city: city ? city.long_name : "",
          zipcode: postcode ? postcode.long_name : "",
          location_latitude: lat,
          location_longitude: lng,
        });
      } else {
        setMessage({ message: "Please check entered fields", class: "error" });
      }
    }
  };

  const handleGeolocationMarker = (lat, lng) => {
    setValue((currentVal) => ({
      ...currentVal,
      location_latitude: lat,
      location_longitude: lng,
    }));

    setMapMarker((currentData) => ({
      ...currentData,
      lat: lat,
      lng: lng,
    }));
  };

  const handleGeolocationError = (message) => {
    setErrors((currentErrors) => ({
      ...currentErrors,
      map: "Please enable geolocation and try again",
    }));
  };

  const processForm = (e) => {
    e.preventDefault();
    const errList = checkFields(values);
    setErrors(errList);
    if (!addressValidate) {
      setErrors((prevErrs) => ({
        ...prevErrs,
        address: "Please select address from the list",
      }));
      return;
    }
    if (!errList) {
      let finalData = { ...values };
      delete finalData.resume;

      finalData.position = finalData.position.id;
      finalData.education_level = finalData.education_level.id;
      setFormData(finalData);

      if (current_plan_value == 0 || current_plan_value === null) {
        setPriceModal(true);
      } else {
        setPriceModal(false);
      }
    }
  };

  //updating whole form
  const { data, error, loading } = usePatch(url, formdata);

  // fetching current employee data
  const { data: employeeData } = useGetData(getUrl);

  //creating new skill
  const { data: skillSaveResponse } = usePostData(
    `employees/${userId}/skills`,
    skillFormData
  );

  //delete a skill
  const { data: skillDeleteResponse } = useDelete(skillDeleteUrl);

  //update a skill
  const { data: skillUpdateResponse } = usePatch(
    skillUpdateUrl,
    skillUpdateFormData
  );

  // useEffect(() => {
  //     if (skillDeleteResponse) {
  //         setSkillDeleteUrl(null);
  //     }
  // }, [skillDeleteResponse])

  //masters
  const { data: qualifications } = useGetData(qualificationUrl);
  const { data: positionList } = useGetData("categories");
  const { data: skillList } = useGetData("skills");
  const { data: skills } = useGetData(`employees/${userId}/skills`);

  useEffect(() => {
    window.scrollTo(0, 0);
    setGetUrl(`employees/${userId}`);
  }, []);

  useEffect(() => {
    if (getUrl) {
      setGetUrl(null);
    }
  }, [getUrl]);

  useEffect(() => {
    if (employeeData && resumeUpdate) {
      setValue((prev) => ({
        ...prev,
        resume: employeeData.data.resume ? employeeData.data.resume : "",
      }));
      setResumeUpdate(false);
      return;
    }

    if (employeeData) {
      const employee = employeeData.data;
      setValue({
        ...values,
        first_name: employee.first_name,
        last_name: employee.last_name,
        about_me: employee.about_me,
        position: employee.position,
        education_level: employee.education_level,
        city: employee.city?.includes("County")
          ? employee.city?.replace("County", "")
          : employee.city,
        state: employee.state,
        address: employee.address?.includes(`, ${employee.city}`)
          ? employee.address.replace(`, ${employee.city}`, "")
          : employee.address.replace(`,  ${employee.city}`, ""),
        zipcode: employee.zipcode,
        location_latitude: employee.location_latitude,
        location_longitude: employee.location_longitude,
        desired_pay: employee.desired_pay ? employee.desired_pay : 0,
        resume: employee.resume ? employee.resume : "",
        dob: employee.dob,
        phone: employee.phone ? String(employee.phone) : employee.phone,
        country: employee.country
          ? employee.country
          : localStorage.getItem("country")
          ? localStorage.getItem("country")
          : "us",
      });
      setQualificationUrl(`qualifications?category_id=${employee.position.id}`);

      setMapMarker((currentData) => ({
        ...currentData,
        lat: employee.location_latitude,
        lng: employee.location_longitude,
      }));
      setmarkerFlag(true);
    }
  }, [employeeData]);

  useEffect(() => {
    if (data) {
      //setValue({ ...values, skills: data.data.skills });
      console.log("hi");
      //update localstorage when location is updated
      if (formdata.location_latitude && formdata.location_longitude) {
        localStorage.setItem("latitude", formdata.location_latitude);
        localStorage.setItem("longitude", formdata.location_longitude);
      }

      setMessage({ message: data.message, class: "success" });
      setTimeout(() => {
        setIsUpdate(false);
      }, 3000);
      setIsUpdate(true);
    }
  }, [data]);

  // useEffect(() => {
  //   if (employeeData?.data?.first_name !== "") {
  //     setPriceModal(true);
  //   } else {
  //     setPriceModal(false);
  //   }
  // }, []);

  useEffect(() => {
    if (skills) {
      setValue({ ...values, skills: skills.data });
    }
  }, [skills]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  const handleBlur = () => {
    ref1.current = "";
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (commonMessage) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [commonMessage]);

  //sort positions list
  const sortedPositionData = positionList?.data?.response_data.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
  return (
    <div className="w-full px-3 py-11  md:p-10 bg-white rounded-md shadow-lg relative">
      {commonMessage && (
        <div className="absolute top-0 right-5 text-sm">
          <p className={commonMessage.class}>{commonMessage.message}</p>
        </div>
      )}

      <div className="flex justify-center mt-2 mb-8">
        <div>
          <ImageUploader
            image={employeeData ? employeeData.data.image : null}
          />
        </div>
      </div>

      <div className="">
        <Pricing priceModal={priceModal} setPriceModal={setPriceModal} />
      </div>

      <form>
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2">
          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="first_name"
            >
              First name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={values.first_name}
              autocomplete="disable"
              className="form_input"
              onChange={(e) => updateValue(e)}
              onBlur={(e) => saveSingleValue(e)}
            />
            <span className="form_error_message">{errors?.first_name}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={values.last_name}
              autocomplete="disable"
              className="form_input"
              onChange={(e) => updateValue(e)}
              onBlur={(e) => saveSingleValue(e)}
            />
            <span className="form_error_message">{errors?.last_name}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="position"
            >
              Desired Position
            </label>
            {sortedPositionData?.length > 0 && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={sortedPositionData}
                name="position"
                id="position"
                value={values.position}
                onChange={updatePositionSelectValue}
                //onBlur={(e) => saveSingleValue(e)}
              />
            )}

            <span className="form_error_message">{errors?.position}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="phone"
            >
              Phone
            </label>
            <PhoneInput
              inputClass="max-h-11 p-0 m-0"
              containerClass="h-11 p-0"
              value={values.phone}
              onChange={(phone, country) => updatePhone(phone, country)}
              onBlur={() => savePhone()}
            />
            <span className="form_error_message">{errors?.phone}</span>
          </div>

          <div className={ref1.current !== "" ? "row-start-3 relative" : ""}>
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="address"
            >
              Address
            </label>
            <PlacesAutocomplete
              value={values.address}
              onChange={handleChange}
              onSelect={handleSelect}
              onError={(err) => {
                ref1.current = "";
                setRefresh(!refresh);
                setAddressValidate(false);
              }}
              // searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      type: "text",
                      autocomplete: "disable",
                      className: "form_input",
                      onBlur: handleBlur,
                      // onChange={(e) => updateValue(e)
                    })}
                  />
                  <div
                    className={
                      ref1.current !== ""
                        ? "border absolute top-auto z-50 bg-white"
                        : ""
                    }
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "bg-gray-700 p-2"
                        : "p-2";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      if (suggestion.terms.length > 1) {
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <span className="form_error_message">{errors?.address}</span>
            {/* <input
                            type="text"
                            name="address"
                            id="address"
                            value={values.address}
                            autoComplete="off"
                            className="form_input"
                            onChange={(e) => updateValue(e)}
                        />
                        <span className='form_error_message'>{errors?.address}</span> */}
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="state"
            >
              State
            </label>
            <input
              type="text"
              name="state"
              id="state"
              value={values.state}
              autocomplete="disable"
              className="form_input"
              onChange={(e) => updateValue(e)}
              // onBlur={(e) => saveSingleValue(e)}
            />
            <span className="form_error_message">{errors?.state}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="city"
            >
              City
            </label>
            <input
              type="text"
              name="city"
              id="city"
              value={values.city}
              autocomplete="disable"
              className="form_input"
              onChange={(e) => updateValue(e)}
            />
            <span className="form_error_message">{errors?.city}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="address"
            >
              Zipcode
            </label>
            <input
              type="text"
              name="zipcode"
              id="zipcode"
              value={values.zipcode}
              autocomplete="disable"
              className="form_input"
              onChange={(e) => updateValue(e)}
            />
            <span className="form_error_message">{errors?.zipcode}</span>
          </div>
          <div className="w-full h-72 col-span-2">
            <ProfileMap
              zoomLevel={12}
              handleMapAddress={handleMapAddress}
              geolocationMarker={handleGeolocationMarker}
              geolocationError={handleGeolocationError}
              marker={mapMarker}
              setMarkerLocation={setMapMarker}
              address={address}
              setAddress={setAddress}
              markerFlag={markerFlag}
            />
            <span className="form_error_message">{errors?.map}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="dob"
            >
              Date of Birth
            </label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              placeholderText="YYYY-MM-DD"
              selected={values?.dob ? new Date(values.dob) : null}
              onChange={(date) => updateDate(date)}
              customInput={<input className="form_input" />}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <span className="form_error_message">{errors?.dob}</span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="education_level"
            >
              Education Level
            </label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={qualifications?.data?.response_data}
              name="education_level"
              id="education_level"
              value={
                Object.keys(values.education_level).length === 0
                  ? ""
                  : values.education_level
              }
              onChange={updateQualificationSelectValue}
              placeholder="Select Education"
            />
            <span className="form_error_message">
              {errors?.education_level}
            </span>
          </div>

          <div className="">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="desired_pay"
            >
              Desired Pay
            </label>
            <div className="flex justify-between items-center">
              <div>
                <span className="bg-blue-50 p-2 rounded-md mr-2">
                  {values.desired_pay}
                </span>
                $ / HR
              </div>
              <div>
                <span className="bg-blue-50 p-2 rounded-md mr-2">
                  {calculateAnnualPay(values.desired_pay)?.toLocaleString()}
                </span>
                $/ Year
              </div>
            </div>
            <ReactSlider
              step={1}
              min={5}
              max={400}
              name="desired_pay"
              id="desired_pay"
              className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
              thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
              trackClassName="bg-main"
              value={values.desired_pay}
              onChange={(value) => {
                updatePay(value);
              }}
              onAfterChange={(value) => {
                savePay(value);
              }}
            />
            <span className="form_error_message">{errors?.desired_pay}</span>
          </div>

          <div className="">
            <PdfUploader
              resume={values.resume ? values.resume : null}
              refreshResume={refreshResume}
            />
            <span className="form_error_message mt-3 block">
              {errors?.resume}
            </span>
          </div>

          <div className="md:col-span-2">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="about_me"
            >
              About You
            </label>
            <textarea
              name="about_me"
              id="about_me"
              value={values.about_me}
              autoComplete="off"
              className="form_input"
              onChange={(e) => updateValue(e)}
              onBlur={(e) => saveSingleValue(e)}
            />
            <span className="form_error_message">{errors?.about_me}</span>
          </div>
        </div>

        <div className="w-full h-0 my-10  border-t border-gray-200"></div>

        {/* <div className="mb-10">
          <label
            className="block mb-2 font-semibold text-gray-900 text-3xl"
            htmlFor="skills"
          >
            Skills
          </label>

          <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mb-10">
            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="skill_name"
              >
                Skill Name
              </label> */}

        {/* <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                getOptionLabel={option => option.skill_name}
                                getOptionValue={option => option.id}
                                options={skillList?.data?.response_data}
                                name="skill_name_select"
                                id="skill_name_select"
                                value={skill}
                                onChange={selectSkill}
                                //onInputChange={skillTyped}
                                //onCreateOption={skillTyped}
                                allowCreateWhileLoading={true}
                            /> */}

        {/* <SelectCreate
                skillList={skillList}
                skill={skill}
                selectSkill={selectSkill}
              />

              <input
                ref={skillName}
                type="text"
                name="skill_name"
                id="skill_name"
                autoComplete="off"
                className="form_input hidden"
              />
              <span className="form_error_message">
                {skillErrors?.skillName}
              </span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="skill_level"
              >
                Skill Level
              </label>
              <input
                ref={skillLevel}
                type="text"
                name="skill_level"
                id="skill_level"
                autoComplete="off"
                className="form_input"
                placeholder="1-100"
              />
              <span className="form_error_message">
                {skillErrors?.skillLevel}
              </span>
            </div>
            <div className="col-span-2 cursor-pointer font-semibold">
              <button
                className="text-sm text-main font-semibold"
                onClick={(e) => saveSkill(e)}
              >
                Add Other
              </button>
            </div>
          </div>

          {values.skills &&
            values.skills.map((skill) => {
              return (
                <div key={skill.skill_name}>
                  <div className="relative pt-1 mb-5">
                    <div className="flex mb-2 items-center justify-between">
                      <div>
                        <p className="font-semibold ">{skill.skill_name}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center pr-2 md:pr-0">
                      <div className="overflow-hidden h-5 text-xs flex rounded bg-gray-200 w-full">
                        <div
                          style={{ width: `${skill.skill_level}%` }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-main"
                        >
                          {skill.skill_level} %
                        </div>
                      </div>
                      <div
                        onClick={() => removeSkill(skill.employee_skill_id)}
                        className="w-2 ml-1 cursor-pointer"
                      >
                        <AiFillCloseCircle className="text-sub text-xl" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          <span className="form_error_message">{errors?.skills}</span>
        </div> */}

        <div className="my-3">
          <button
            // disabled={loading ? true : false}
            onClick={(e) => processForm(e)}
            className="w-full btn btn-main"
          >
            {loading ? loader : isUpdate ? "Updated" : "Update and Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEmployee;
