import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import usePostData from "../../hooks/usePostData";
import useDelete from "../../hooks/useDelete";
import HireModal from "../modals/EmployeeDetails/HireModal";
import ContactModal from "../modals/EmployeeDetails/ContactModal";
import HireReport from "../modals/EmployeeDetails/HireReport";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillHeart } from "react-icons/ai";
import { MdRestore } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { BiDownload } from "react-icons/bi";
import { FaWindowClose } from "react-icons/fa";
import RohLogo from "../../images/ROH-logo.png";

let old_phoneformat = "";
let new_phoneformat = "";
const EmployeeDetails = () => {
  //useParams
  const { emp_id } = useParams();

  //get queries from URL
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [empDetails, setEmpDetails] = useState([]);
  const [position, setPosition] = useState("");
  const [hireModal, setHireModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [saveFormData, setSaveFormData] = useState(null);
  const [unsaveUrl, setUnsaveUrl] = useState(null);
  const [isHired, setIsHired] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [employerHired, setEmployerHired] = useState(false);
  const [getUrl, setGetUrl] = useState(
    `employees/${emp_id}/public${
      query.get("job_id") ? `/${query.get("job_id")}` : ""
    }`
  );
  const [reportModal, setReportModal] = useState(false);
  // localStorage
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");

  //hooks get
  const {
    data: employeeData,
    loading: loading,
    error: error,
  } = useGetData(getUrl);

  console.log("employeeData..", error);

  const { data: positionList } = useGetData("categories");

  const { data: savedJobsPostData, error: err } = usePostData(
    `employers/${user_id}/saved-employees`,
    saveFormData
  );

  const { data: delJobsData } = useDelete(unsaveUrl);

  useEffect(() => {
    window.scrollTo(0, 0);
    setGetUrl(null);
    // if (query.get("hired") === "true") {
    //   setIsHired(true);
    // }

    // if (user_role === "employee" || !user_role) {
    //   window.location.href = "/";
    // }
  }, []);

  useEffect(() => {
    if (error === "Not found.") {
      window.location.href = "/404";
    }
  }, [error]);

  console.log("getUrl", getUrl);
  useLayoutEffect(() => {
    if (employeeData?.data) {
      setEmpDetails(employeeData.data);
      setIsSaved(employeeData.data.is_saved);
      setIsHired(employeeData.data.hired);
      setIsCancel(employeeData.data.job_application_cancelled);
      setEmployerHired(employeeData.data.employer_hired);
    }
  }, [employeeData]);

  // Deleted employee account redirection
  useEffect(() => {
    if (error?.non_field_error === "Something went wrong") {
      window.location.href = "/404";
    }
  }, [error]);

  useEffect(() => {
    if (!err && savedJobsPostData?.code === 200) {
      setIsSaved(true);
    }
  }, [savedJobsPostData]);

  useEffect(() => {
    if (!err && delJobsData?.code === 200) {
      setIsSaved(false);
    }
  }, [delJobsData]);

  useEffect(() => {
    if (positionList?.data?.response_data && employeeData?.data) {
      const positions = positionList.data.response_data;
      const findPostion = positions.find(
        (item) => item.id === employeeData.data.position
      );
      setPosition(findPostion?.name);
    }
  }, [positionList, employeeData]);

  const hireRefresh = () => {
    setGetUrl(
      `employees/${emp_id}/public${
        query.get("job_id") && `/${query.get("job_id")}`
      }`
    );
  };

  const contactRefresh = (isContact) => {
    setIsContact(isContact);
  };

  const checkUser = () => {
    if (!user_id) {
      window.location.href = "/login";
      return;
    }
  };

  const saveEmp = () => {
    checkUser();
    if (user_role === "employer") {
      setSaveFormData({
        employee: emp_id,
      });
    }
    // savedRefresh(true);
  };

  useEffect(() => {
    if (unsaveUrl) {
      setUnsaveUrl(null);
    }
  }, [unsaveUrl]);

  const delEmp = () => {
    checkUser();
    setUnsaveUrl(`employers/${user_id}/saved-employees/${emp_id}`);
    // savedRefresh(false);
  };

  function dateFormat(d) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let t = new Date(d);
    return t.getDate() + " " + monthNames[t.getMonth()] + " " + t.getFullYear();
  }

  if (empDetails) {
    old_phoneformat = empDetails?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const customClose = () => {
    setDownloadModal(false);
  };
  const customDownload = () => {
    setDownloadModal(true);
  };

  return (
    <div className="minHeight mb-5 container-wrapper">
      {!loading && !error ? (
        <>
          <HireModal
            showModal={hireModal}
            setShowModal={setHireModal}
            empDetails={empDetails}
            position={position}
            phone={new_phoneformat}
            hireRefresh={hireRefresh}
          ></HireModal>
          <ContactModal
            showModal={contactModal}
            setShowModal={setContactModal}
            empDetails={empDetails}
            position={position}
            phone={new_phoneformat}
            contactRefresh={contactRefresh}
          ></ContactModal>
          <HireReport
            showModal={reportModal}
            setShowModal={setReportModal}
          ></HireReport>

          <div className="my-5 custom_shadow jobTitleContainer">
            <div className="flex flex-col justify-between py-3 pt-6 space-y-6 md:px-5 md:space-y-0 md:flex-row">
              <div className="block space-x-5 font-semibold text-gray-500 md:flex md:whitespace-pre md:justify-center md:text-left md:items-center details font_small_light">
                <img
                  src={
                    empDetails?.image
                      ? empDetails?.image
                      : "/images/employee-default.png"
                  }
                  alt="Profile Image"
                  className="block ml-5 md:ml-0"
                  width="150px"
                />

                <div className="space-y-1 lg:mr-5">
                  {empDetails?.premium == true && (
                    <div className="block sm:hidden">
                      <img src={RohLogo} className="w-5 h-full" />
                    </div>
                  )}
                  <h2 className="text-black font-xl-semibold flex items-center">
                    {empDetails?.first_name
                      ? `${empDetails?.first_name} ${empDetails.last_name}`
                      : "User Name"}
                    {empDetails?.premium == true && (
                      <div className="hidden sm:block ml-2">
                        <img src={RohLogo} className="w-5 h-full" />
                      </div>
                    )}
                  </h2>
                  <p className="pb-5 font-normal text-gray-500 ">{position}</p>
                  <p className="flex items-center justify-start md:justify-start">
                    <span>
                      <MdRestore color="007fee" className="mr-1" size="1.2em" />
                    </span>
                    {empDetails?.created_at &&
                      `Member Since, ${dateFormat(empDetails.created_at)}`}
                  </p>
                  <div className="flex flex-col justify-start gap-0 md:gap-2 md:flex-row md:justify-start md:flex-wrap md:space-x-3">
                    <p className="flex justify-start md:items-center">
                      <span style={{ marginTop: "2px" }} className="self-start">
                        <GoLocation
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      <span className="whitespace-pre-wrap">
                        {empDetails?.location}
                      </span>
                    </p>
                    <p
                      style={{ marginLeft: "0" }}
                      className="flex items-center m-0"
                    >
                      <span>
                        <HiOutlinePhone
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {new_phoneformat}
                    </p>
                    <p
                      style={{ marginLeft: "0" }}
                      className="flex items-center m-0"
                    >
                      <span>
                        <HiOutlineMail
                          color="007fee"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      {empDetails.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-5 line"></div>
            <div className="flex flex-col justify-between p-3 pr-3 lg:flex-row">
              <div className="flex flex-col justify-between gap-2 mt-3 lg:flex-row">
                {isContact ? (
                  <button
                    onClick={() => setContactModal(true)}
                    disabled
                    className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                  >
                    <span>
                      <HiOutlineMail
                        color="white"
                        className="mr-1"
                        size="1.2em"
                      />
                    </span>
                    Contacted
                  </button>
                ) : (
                  <button
                    onClick={() => setContactModal(true)}
                    className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                  >
                    <span>
                      <HiOutlineMail
                        color="white"
                        className="mr-1"
                        size="1.2em"
                      />
                    </span>
                    Contact Now
                  </button>
                )}

                {!isCancel &&
                user_role === "employer" &&
                query.get("job_id") ? (
                  !employerHired ? (
                    <button
                      onClick={() => setHireModal(true)}
                      className="flex items-center justify-center w-full text-white md:w-auto md:mt-0 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hire Now
                    </button>
                  ) : (
                    <button
                      disabled
                      className="flex items-center justify-center w-full text-white md:w-auto md:mt-0 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hired
                    </button>
                  )
                ) : null}
              </div>
              <div className="flex flex-col justify-between gap-2 mt-3 lg:flex-row">
                {user_id ? (
                  user_role === "employer" ? (
                    <button className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-white hover:text-black ">
                      <span>
                        <BiDownload className="mr-1" size="1.2em" />
                      </span>
                      <a
                        href={empDetails?.resume}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download CV
                      </a>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => customDownload()}
                        className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-white hover:text-black "
                      >
                        <span>
                          <BiDownload className="mr-1" size="1.2em" />
                        </span>
                        {/* <a href="#">Download CV</a> */}
                        Download CV
                      </button>
                      {downloadModal ? (
                        <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none">
                          <div className="relative w-auto max-w-3xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                              {/*header*/}
                              <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                                <h3 className="text-xl font-semibold md:text-xl">
                                  Only an employer is able to download the CV
                                </h3>
                                <button
                                  onClick={() => customClose()}
                                  className="ml-4 text-red-600 font-base-semibold "
                                >
                                  <FaWindowClose size="1.4em" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  <button className="flex items-center justify-center w-full text-white bg-black shadow-md md:w-auto btn-base hover:bg-white hover:text-black ">
                    <span>
                      <BiDownload className="mr-1" size="1.2em" />
                    </span>
                    <a href="/login">Download CV</a>
                  </button>
                )}

                {user_role === "employer" ? (
                  isSaved ? (
                    <button
                      onClick={delEmp}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <AiFillHeart className="mr-1" size="1.2em" />
                      </span>
                      Unsave User
                    </button>
                  ) : (
                    <button
                      onClick={saveEmp}
                      className="flex items-center justify-center w-full text-white bg-yellow-300 md:w-auto btn-base hover:bg-yellow-400"
                    >
                      <span>
                        <AiOutlineHeart className="mr-1" size="1.2em" />
                      </span>
                      Save User
                    </button>
                  )
                ) : null}
                <button
                  onClick={() => {
                    setReportModal(true);
                  }}
                  className="flex items-center justify-center text-white bg-red-500 hover:bg-red-600 btn-base"
                >
                  <span>
                    <MdErrorOutline className="mr-1" size="1.2em" />
                  </span>
                  Report
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-3 lg:space-x-5">
            <div className="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
              <h2 className="my-4 font-xl-semibold">About Me</h2>
              <p>{empDetails.about_me}</p>

              {/* <div className="mt-5 space-y-3">
                <p className="flex" key="">
                  <span className="mt-1">
                    <GoPrimitiveDot color="#EB5757" className="mr-2" />
                  </span>
                  asdsada
                </p>
              </div> */}
            </div>
            <div className="md:w-6/12 lg:w-5/12 jobDescriptionSidebar">
              <div className="px-5 py-3 jobDescription custom_shadow">
                <h2 className="my-4 font-xl-semibold">Candidate Details</h2>
                <div className="space-y-4 capitalize">
                  {/* <div className="flex justify-between">
                    <p className="mr-4">Experience</p>
                    <p className="font-semibold text-right">Akjkjge</p>
                  </div> */}
                  <div className="flex justify-between">
                    <p className="mr-4">Age</p>
                    <p className="font-semibold text-right">
                      {getAge(empDetails.dob)}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="mr-4">Expected Salary</p>
                    <p className="font-semibold text-right">
                      {empDetails.desired_pay != null
                        ? `$${empDetails.desired_pay}`
                        : "$0"}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="mr-4">Education Levels</p>
                    {/* <p className="font-semibold text-right" key={single_jobtype}>
                    {single_jobtype.name}
                  </p>     */}
                    {/* <ul className="jobdetail-type"> */}
                    <p className="inline font-semibold text-right">
                      {empDetails.education_level_label}
                    </p>
                    {/* </ul> */}
                  </div>
                </div>
                {!isCancel &&
                user_role === "employer" &&
                query.get("job_id") ? (
                  !employerHired ? (
                    <button
                      onClick={() => setHireModal(true)}
                      className="flex items-center justify-center w-full text-white mt-4 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hire Now
                    </button>
                  ) : (
                    <button
                      disabled
                      className="flex items-center justify-center w-full text-white mt-4 btn-base bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:to-yellow-500 hover:via-red-600 "
                    >
                      <span>
                        <RiSendPlaneFill
                          color="white"
                          className="mr-1"
                          size="1.2em"
                        />
                      </span>
                      Hired
                    </button>
                  )
                ) : null}
              </div>
              {/* <div className="px-5 py-3 mt-5 locaionMap custom_shadow">
                <h2 className="my-4 font-xl-semibold">Skills</h2>
                <div className="space-y-6"> */}
              {/* <div className="">
                    <p className="pb-2 font-semibold">Lorem</p>
                    <div className="relative w-full h-5 bg-gray-200 rounded-md">
                      <div
                        className="relative h-5 text-center bg-blue-600 rounded-md"
                        style={{ width: "10%" }}
                      >
                        <span className="text-sm font-semibold text-white">
                          43%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <p className="pb-2 font-semibold">Lorem</p>
                    <div className="relative w-full h-5 bg-gray-200 rounded-md">
                      <div
                        className="relative h-5 text-center bg-blue-600 rounded-md"
                        style={{ width: "10%" }}
                      >
                        <span className="text-sm font-semibold text-white"></span>
                      </div>
                    </div>
                  </div> */}
              {/* {empDetails?.skill?.map((item) => {
                    return (
                      <div key={item.employee_skill_id} className="">
                        <p className="pb-2 font-semibold">{item.skill_name}</p>
                        <div className="relative w-full h-5 bg-gray-200 rounded-md">
                          <div
                            className="relative h-5 text-center bg-blue-600 rounded-md"
                            style={{ width: `${item.skill_level}%` }}
                          >
                            <span className="text-sm font-semibold text-white">
                              {item.skill_level}%
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })} */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="my-5 custom_shadow">
            <h5 className="p-3 text-gray-600 font-semibold">
              {error?.non_field_error ? error.non_field_error : "Loading..."}
            </h5>
          </div>
          <div className="push"></div>
        </>
      )}
    </div>
  );
};

export default EmployeeDetails;
