const fields = {
  website: "",
  company_name: "",
  phone: "",
  country: "US",
  city: "",
  state: "",
  address: "",
};

const checkFields = (data) => {
  const errors = {};
  for (let key in fields) {
    if (!data[key]) {
      errors[key] = "This field is required";
    }
  }

  return Object.keys(errors).length !== 0 ? errors : false;
};

export { fields, checkFields };
