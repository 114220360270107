import { useEffect, useState, useContext, useRef } from "react";
import usePatch from "../../../hooks/usePatch";
import useGetData from "../../../hooks/useGetData";
import { MarkerContext, UserContext } from "../../../App";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fields, checkFields } from "../../../helpers/EmployerSchema";
import ImageUploader from "../../elements/ImageUploader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ProfileMap from "../../elements/ProfileMap";
import Pricing from "../../modals/PricingAndPlan/Pricing";

const EditEmployer = () => {
  const { userId } = useContext(UserContext);
  const url = `employers/${userId}`;
  const loader = <span className="text-blue-500">Sending...</span>;
  const [isUpdate, setIsUpdate] = useState(false);
  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);
  const [address, setAddress] = useState();
  const [addressValidate, setAddressValidate] = useState(true);
  const [mapMarker, setMapMarker] = useState({
    lat: "",
    lng: "",
  });
  const [markerFlag, setmarkerFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const initRef = useRef(true);
  const ref1 = useRef("");

  //Plan & Price modal box while update the employer profile once 
  const [priceModal, setPriceModal] = useState(false);
  // const { data: profileCheck } = useGetData(
  //   `employers/${userId}/profile-check`
  // );
  const current_plan_value = localStorage.getItem("plan_value");
 

  
  // const [markerLng, setMarkerLng] = useState();

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       setCurrentLatitude(position.coords.latitude);
  //       setCurrentLongitude(position.coords.longitude);
  //     });
  //   } else {
  //     console.log("Not Available");
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };

  const handleChange = (address) => {
    ref1.current = address;
    setValue({ ...values, address: address });
    if (addressValidate) {
      setAddressValidate(false);
    }
  };

  const updatePhone = (value) => {
    setValue({
      ...values,
      phone: value,
      // country: country.countryCode,
      // country_code: country.dialCode,
    });
  };

  const saveSingleValue = (e) => {
    setFormData({ [e.target.name]: e.target.value });
  };

  const savePhone = () => {
    const check = /^0*$/.test(values.phone);
    if (check) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        phone: "Please enter a valid phone number",
      }));

      return;
    }
    setFormData({
      phone: values.phone,
      country_code: values.country_code,
      country: values.country,
    });
  };

  const processForm = (e) => {
    e.preventDefault();
    const errList = checkFields(values);
    setErrors(errList);
    if (!addressValidate) {
      setErrors((prevErrs) => ({
        ...prevErrs,
        address: "Please select address from the list",
      }));
      return;
    }
    if (!errList) {
      let finalData = { ...values };
      setFormData(finalData);

      if (current_plan_value == 0 || current_plan_value === null) {
        setPriceModal(true);
      } else {
        setPriceModal(false);
      }
    }
  };

  const { data, error, loading } = usePatch(url, formdata);

  const { data: employersData, error: err } = useGetData(`employers/${userId}`);
  console.log('err...', err);
 

  //Set the redirection to login page if the user account doesn't exist
  // useEffect(() => {
  //   if(err === "User inactive or deleted.") {
  //     localStorage.clear();
  //     window.location.href = "/login";
  //   }
  // }, [err])
  

  useEffect(() => {
    if (employersData) {
      const employer = employersData.data;
      setValue({
        ...values,
        website: employer.website,
        company_name: employer.company_name,
        phone: employer.phone ? String(employer.phone) : employer.phone,
        country: employer.country,
        city: employer.city?.includes("County")
          ? employer.city?.replace("County", "")
          : employer.city,
        state: employer.state,
        address: employer.address?.includes(`, ${employer.city}`)
          ? employer.address.replace(`, ${employer.city}`, "")
          : employer.address.replace(`,  ${employer.city}`, ""),
        zipcode: employer.zipcode,
        lat: employer.location_latitude,
        lng: employer.location_longitude,
      });
      setMapMarker((currentData) => ({
        ...currentData,
        lat: employer.location_latitude,
        lng: employer.location_longitude,
      }));
      setmarkerFlag(true);
    }
  }, [employersData]);

  useEffect(() => {
    if (data) {
      //setValue(fields);

      //update localstorage when location is updated
      if (formdata.location_latitude && formdata.location_longitude) {
        localStorage.setItem("latitude", formdata.location_latitude);
        localStorage.setItem("longitude", formdata.location_longitude);
      }

      setMessage({ message: data.message, class: "success" });
      setTimeout(() => {
        setIsUpdate(false);
      }, 3000);
      setIsUpdate(true);

      // if (profileCheck?.data?.profile_completed === false) {
      //   setPriceModal(true);
      // } else {
      //   setPriceModal(false);
      // }
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  const handleSelect = (address) => {
    ref1.current = "";
    // setValue({ ...values, address: address });
    geocodeByAddress(address)
      .then((results) => {
        const result = results[0].address_components;
        const country = result.find((item) => item.types.includes("country"));
        const establishment = result.find((item) => item.types.includes("establishment"));
        const neighborhood = result.find((item) => item.types.includes("neighborhood"));
        const premise = result.find((item) => item.types.includes("premise"));
        const postcode = result.find((item) =>
          item.types.includes("postal_code")
        );
        const state = result.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        const city = result.find((item) =>
          item.types.includes("administrative_area_level_2") ? item.types.includes("administrative_area_level_2"):  item.types.includes("locality")
        );
        const street = result.find((item) =>
          item.types.includes("street_number")
        );
        const route = result.find((item) => item.types.includes("route"));
        // const premise = result.find((item) => item.types.includes("premise"));
        const locality = result.find((item) => item.types.includes("locality"));
        const sublocality = result.find((item) =>
          item.types.includes("sublocality")
        );

        let addressVal = "";

        // if (route || locality || sublocality || premise) {
        //   addressVal = `${street?.long_name ? street.long_name : ""} ${
        //     route?.long_name ? route.long_name + "," : ""
        //   } ${premise?.long_name ? premise.long_name + "," : ""} ${
        //     sublocality?.long_name ? sublocality.long_name : ""
        //   } ${locality?.long_name ? locality.long_name : ""}`;
        //   // addressVal = addressVal.trim();
        //   addressVal = addressVal.replace(/,\s*$/, "").trim();
        // } else {
        //   addressVal = address;
        // }
        if (establishment||neighborhood||premise||street|| route || sublocality || locality) {
          addressVal = `${establishment?.long_name ? establishment.long_name +", ": ""}${ 
            neighborhood?.long_name ? neighborhood.long_name +", " : ""}${
            premise?.long_name ? premise.long_name +", " : ""}${
            street?.long_name ? street.long_name +", ": ""}${
            route?.long_name ? route.long_name + ", " : ""}${
            sublocality?.long_name ? sublocality.long_name +", " : ""}`;
            
          addressVal = addressVal.replace(/,\s*$/, "").trim();
        } else {
          addressVal = address;
        }
        setAddressValidate(true);
        const obj = {
          ...values,
          address: addressVal,
          country: country ? country.long_name : "",
          state: state ? state.long_name : "",
          city: city
            ? city.long_name.includes("County")
              ? locality
                ? locality.long_name
                : sublocality
                ? sublocality.long_name
                : city.long_name
              : city.long_name
            : "",
          zipcode: postcode ? postcode.long_name : "",
          location_latitude: results[0].geometry.location.lat(),
          location_longitude: results[0].geometry.location.lng(),
        };
        setValue(obj);

        const errList = checkFields(obj);
        setErrors(errList);
        if (!errList) {
          setFormData({
            address: addressVal,
            country: obj.country,
            state: obj.state,
            city: obj.city,
            zipcode: obj.zipcode,
            location_latitude: obj.location_latitude,
            location_longitude: obj.location_longitude,
          });
        } else {
          setMessage({
            message: "Please check entered fields",
            class: "error",
          });
        }

        // obj = {
        //   ...obj,
        //   country: country ? country.long_name : "",
        //   postcode: postcode ? postcode.long_name : "",
        //   state: state ? state.long_name : "",
        //   city: city ? city.long_name : "",
        //   suburb: suburb ? suburb.long_name : "",
        //   street: street ? street.long_name : "",
        // };
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      const addressString = `${values.city} ${values.state} ${values.country}`;
      setAddress(addressString);
    }
  }, [mapMarker]);

  const getSelectedLocation = (location) => {
    // setCurrentLatitude(location.lat);
    // setCurrentLongitude(location.lng);
    setMapMarker((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
    // setMarkerLat(location.lat);
    // setMarkerLng(location.lng);
  };

  const handleMapAddress = (
    route,
    country,
    postcode,
    state,
    city,
    lat,
    lng
  ) => {
    const obj = {
      ...values,
      address: route ? route : "",
      country: country ? country.long_name : "",
      state: state ? state.long_name : "",
      city: city ? city.long_name : "",
      zipcode: postcode ? postcode.long_name : "",
      location_latitude: lat,
      location_longitude: lng,
    };
    setValue(obj);

    if (address !== "Location not available") {
      const errList = checkFields(obj);
      setErrors(errList);
      if (!errList) {
        setFormData({
          address: route ? route : "",
          country: country ? country.long_name : "",
          state: state ? state.long_name : "",
          city: city ? city.long_name : "",
          zipcode: postcode ? postcode.long_name : "",
          location_latitude: lat,
          location_longitude: lng,
        });
      } else {
        setMessage({ message: "Please check entered fields", class: "error" });
      }
    }
  };

  const handleGeolocationMarker = (lat, lng) => {
    setValue((currentVal) => ({
      ...currentVal,
      location_latitude: lat,
      location_longitude: lng,
    }));

    setMapMarker((currentData) => ({
      ...currentData,
      lat: lat,
      lng: lng,
    }));
  };

  const handleGeolocationError = (message) => {
    setErrors((currentErrors) => ({
      ...currentErrors,
      map: "Please enable geolocation and try again",
    }));
  };

  const handleBlur = () => {
    ref1.current = "";
    setRefresh(!refresh);
  };

  /* Timeout for message/error messages*/
  useEffect(() => {
    if (commonMessage) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [commonMessage]);

  return (
    <>
    {(employersData )&&
      <div className="relative w-full px-3 py-11 bg-white rounded-md shadow-lg md:p-10">
        {commonMessage && (
          <p className={`${commonMessage.class} absolute top-0 right-5`}>
            {commonMessage.message}
          </p>
        )}

        <div className="flex justify-center mt-2 mb-8">
          <div>
            <ImageUploader
              image={employersData ? employersData.data.image : null}
            />
          </div>
        </div>
        <div className="">
          <Pricing priceModal={priceModal} setPriceModal={setPriceModal} />
        </div>

        <form autocomplete="off">
          <input type="text" style={{ display: "none" }} />
          <input type="password" style={{ display: "none" }} />
          <div className="flex flex-col gap-4 md:grid md:grid-cols-2">
            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="company_name"
              >
                Company name
              </label>
              <input
                type="text"
                name="company_name"
                id="company_name"
                value={values.company_name}
                autocomplete="disable"
                className="form_input"
                onChange={(e) => updateValue(e)}
                onBlur={(e) => saveSingleValue(e)}
              />
              <span className="form_error_message">{errors?.company_name}</span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="website"
              >
                Website
              </label>
              <input
                type="text"
                name="website"
                id="website"
                value={values.website}
                autoComplete="off"
                className="form_input"
                onChange={(e) => updateValue(e)}
                onBlur={(e) => saveSingleValue(e)}
              />
              <span className="form_error_message">{errors?.website}</span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="phone"
              >
                Phone no
              </label>
              <PhoneInput
                inputClass="max-h-11 p-0 m-0"
                containerClass="h-11 p-0"
                //country={values.country_code}
                value={values.phone}
                onChange={(phone, country) => updatePhone(phone, country)}
                onBlur={() => savePhone()}
              />
              <span className="form_error_message">{errors?.phone}</span>
            </div>

            <div
              className={
                ref1.current !== "" ? "col-start-2 row-start-2 relative" : ""
              }
            >
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="address"
              >
                Address
              </label>
              <PlacesAutocomplete
                value={values.address}
                onChange={handleChange}
                onSelect={handleSelect}
                onError={(err) => {
                  ref1.current = "";
                  setRefresh(!refresh);
                  setAddressValidate(false);
                }}
                // searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="">
                    <input
                      {...getInputProps({
                        type: "text",
                        autocomplete: "disable",
                        className: "form_input",
                        onBlur: handleBlur,
                        // onChange={(e) => updateValue(e)
                      })}
                    />

                    <div
                      className={
                        ref1.current !== ""
                          ? "border absolute top-auto z-50 bg-white"
                          : ""
                      }
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "bg-gray-700 p-2"
                          : "p-2";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        if (suggestion.terms.length > 1) {
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <span className="form_error_message">{errors?.address}</span>
              {/* <input
                            type="text"
                            name="address"
                            id="address"
                            value={values.address}
                            autoComplete="off"
                            className="form_input"
                            onChange={(e) => updateValue(e)}
                        />
                        <span className='form_error_message'>{errors?.address}</span> */}
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="city"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                value={values.city}
                autocomplete="disable"
                className="form_input"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">{errors?.city}</span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="state"
              >
                State
              </label>
              <input
                type="text"
                name="state"
                id="state"
                value={values.state}
                autocomplete="disable"
                className="form_input"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">{errors?.state}</span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="zipcode"
              >
                Zip Code
              </label>
              <input
                type="text"
                name="zipcode"
                id="zipcode"
                value={values.zipcode}
                autocomplete="disable"
                className="form_input"
                onChange={(e) => updateValue(e)}
              />
              <span className="form_error_message">{errors?.zipcode}</span>
            </div>

            <div className="">
              <label
                className="block mb-2 text-sm font-semibold text-gray-500"
                htmlFor="country"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                value={values.country}
                autocomplete="disable"
                className="form_input"
                onChange={(e) => updateValue(e)}
              />

              <span className="form_error_message">{errors?.country}</span>
            </div>
            <div className="w-full col-span-2 h-72">
              <ProfileMap
                zoomLevel={12}
                handleMapAddress={handleMapAddress}
                geolocationError={handleGeolocationError}
                geolocationMarker={handleGeolocationMarker}
                marker={mapMarker}
                setMarkerLocation={setMapMarker}
                address={address}
                setAddress={setAddress}
                markerFlag={markerFlag}
                // markerLat={markerLat}
                // setMarkerLat={setMarkerLat}
                // markerLng={markerLng}
                // setMarkerLng={setMarkerLng}
              />
              <span className="form_error_message">{errors?.map}</span>
            </div>
          </div>

          <div className="my-3">
            <button
              disabled={loading ? true : false}
              onClick={(e) => processForm(e)}
              className="w-full btn btn-main"
            >
              {loading ? loader : isUpdate ? "Updated" : "Update and Save"}
            </button>
          </div>
        </form>
      </div>}
    </>
  );
};

export default EditEmployer;
