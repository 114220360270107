import { useState, useEffect } from "react";
import axios from "axios";
import useToken from "./useToken.js";

const usePostData = (
  url,
  formdata,
  type = "application/json",
  service = "main"
) => {
  const [data, setData] = useState(null);
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [code, setCode] = useState(null);
  const { defaultOptions } = useToken(type);

  let base_url;
  if (service === "main") {
    base_url = process.env.REACT_APP_API_URL;
  } else if (service === "search") {
    base_url = process.env.REACT_APP_SEARCH_URL;
  } else {
    base_url = process.env.REACT_APP_CHAT_URL;
  }

  useEffect(() => {
    if (formdata) {
      setLoader(true);
      axios
        .post(base_url + url, formdata, defaultOptions)
        .then((response) => {
          setData(response.data);
          setMessage(response.data);
          setError(null);
          setLoader(false);
          setCode("ok");
        })
        .catch((error) => {
          setCode(error?.response?.data?.code);
          setData(null);
          setMessage(null);
          setLoader(false);
          
          //set redirect to login if the user deleted from admin panel
          if (
            error?.response?.data?.data?.detail === "User inactive or deleted."
          ) {
            localStorage.clear();
            window.location.href = "/login";
          }

          if (error?.response?.data?.data) {
            setError(error.response.data.data);
          } else if (error?.response?.data) {
            setError(error.response.data);
          } else {
            setError({ non_field_error: "Something went wrong" });
          }
        });
    }
}, [formdata, url]);
  return { data, error, loading, message ,code};
};

export default usePostData;
