

import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { IoMdGlobe } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import usePostData from "../../../hooks/usePostData";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

let old_phoneformat = "";
let new_phoneformat = "";
const MAX_LENGTH = 280;

const ClaimJob = ({ claimJob, setClaimJob, singleJobDetails, setRefresh }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [modalNo, setModalNo] = useState(1);
  const [formdataClaimJobs, setFormdataClaimJobs] = useState(null);
  const [idArray, setIdArray] = useState([]);

  const emp_id = localStorage.getItem("user_id");
  const [pageNumber, setPageNumber] = useState(0);
  const [suggestedUrl, setSuggestedUrl] = useState(
    `employers/claim-jobs/suggestions`
  );
  const claimjoburl = `employers/${emp_id}/claim-jobs`;
  const [suggestionFormData, setSuggestionFormData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [suggestedList, setSuggestedList] = useState([]);
  const [claimId, setClaimId] = useState(false);
  const [suggestedTempData, setSuggestedTempData] = useState([]);
  const [suggestedData, setSuggestedData] = useState([]);
  const [selectAll,setSelectAll]=useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  //Modal 1 continue button click function
  const continueClick = () => {
    if (isDisabled) {
      setAlert(true);
    } else {
      setModalNo(2);
    }
    setSuggestionFormData({
      company_name: singleJobDetails?.company_name,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      setClaimId(false);
    }, 5000);
  }, [alert, claimId]);

  const { data: suggestionData } = usePostData(
    suggestedUrl,
    suggestionFormData
  );

  const claimjobRef = useRef(null);
  useEffect(() => {
    claimjobRef?.current?.scrollIntoView();
  }, [suggestionData]);

  const usersPerPage =4;
  useEffect(() => {
    let new_arr1 = [];
    let new_arr2 = [];
    for (var i = 0; i < suggestionData?.data.job_data.length; i++) {
      if (suggestionData?.data.job_data[i].id === singleJobDetails?.id) {
        new_arr1.push(suggestionData?.data.job_data[i]);
      } else {
        new_arr2.push(suggestionData?.data.job_data[i]);
      }
    }
    let new_arr = new_arr1.concat(new_arr2);
    // console.log(new_arr,"filterdata")
    setSuggestedTempData(new_arr);
    setSuggestedData(new_arr.slice(0, usersPerPage));
  }, [suggestionData]);

  const suggestedPageCount = Math.ceil(
    suggestionData?.data.job_data.length / usersPerPage
  );
  const suggestedHandlePageData = (selected) => {
    setSuggestedUrl(`employers/claim-jobs/suggestions?page=${selected}`);
  };
  const suggestedChangePage = ({ selected }) => {
    setPageNumber(selected);
    let new_select =usersPerPage*(selected)
    if(selected!==0){
        setSuggestedData(
        suggestedTempData.slice(new_select, new_select + usersPerPage)
      );
    }else{
      setSuggestedData(
        suggestedTempData.slice(0, usersPerPage)
      );
    }
  };

  useEffect(() => {
    if (suggestionData) {
      setSuggestedList(suggestionData);
    }
  }, [suggestionData]);

  //Convert the phone number format
  if (singleJobDetails) {
    old_phoneformat = singleJobDetails?.phone;
    new_phoneformat = old_phoneformat
      ? old_phoneformat
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "(+$1) $2 $3-$4")
      : "";
  }

  //checkbox click function
  const dataSubmit = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };
  const onCheckboxClick = () => {
    setChecked(!checked);
    return dataSubmit();
  };

  const handleSubmmit = () => {
    let climedjobs = [];
    isCheck.map((ele) => {
      climedjobs.push({ job: ele, status: 1 });
    });
    setFormdataClaimJobs({
      company_name: singleJobDetails?.company_name,
      claimed_jobs: climedjobs,
    });

    if (climedjobs.length === 0) {
      setClaimId(true);
    }
  };

  const { data: claimJobResponse } = usePostData(
    claimjoburl,
    formdataClaimJobs
  );

  useEffect(() => {
    if (claimJobResponse?.code === 200) {
      setModalNo(3);
      setRefresh((prev) => !prev);
    }
  }, [claimJobResponse]);

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(suggestedTempData.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  return (
    <div>
      {claimJob ? (
        <>
          {modalNo === 1 && (
            <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none p-2">
              <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                <div className="relative flex flex-col w-5/6 mx-auto md:w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200 mb-6">
                    <h3 className="text-xl font-semibold md:text-2xl">
                      Want to claim this job?
                    </h3>
                    <p className="text-red-600 font-base-semibold">1/3</p>
                  </div>

                  <div className="relative flex-auto px-6 pt-6">
                    <div className="flex w-full details font_small_light normal-case flex-col">
                      <div className="space-y-1">
                        <h2 className="text-black font-xl-semibold">
                          {singleJobDetails?.company_name &&
                            singleJobDetails?.company_name}
                        </h2>
                        {singleJobDetails?.location_details?.address ? (
                          <p className="flex break-words">
                            <p className="jobAddress">
                              {singleJobDetails?.location_details?.address}
                            </p>
                          </p>
                        ) : (
                          <p className="flex">
                            <span className="mt-1 md:mt-0">
                              <GoLocation
                                color="007fee"
                                className="mr-1"
                                size="1.2em"
                              />
                            </span>
                            {singleJobDetails?.city
                              ? `, ${singleJobDetails?.city}`
                              : ""}
                            <br />
                            {singleJobDetails?.country}
                            {singleJobDetails?.zipcode
                              ? `, ${singleJobDetails?.zipcode}`
                              : ""}
                          </p>
                        )}

                        <div className="flex flex-col space-y-1">
                          {singleJobDetails?.phone && (
                            <p className="flex items-top break-all">
                              <span>
                                <HiOutlinePhone
                                  color="007fee"
                                  className="mr-1 mt-1"
                                  size="1.2em"
                                />
                              </span>
                              <div>{new_phoneformat}</div>
                            </p>
                          )}
                          {singleJobDetails?.email && (
                            <p className="flex items-top break-all">
                              <span>
                                <HiOutlineMail
                                  color="007fee"
                                  className="mr-1 mt-1"
                                  size="1.2em"
                                />
                              </span>
                              <div>{singleJobDetails?.email}</div>
                            </p>
                          )}
                        </div>

                        {singleJobDetails?.website && (
                          <p className="flex items-top break-all">
                            <span>
                              <IoMdGlobe
                                color="007fee"
                                className="mr-1 mt-1"
                                size="1.2em"
                              />
                            </span>
                            <div>{singleJobDetails?.website}</div>
                          </p>
                        )}
                      </div>
                      <div className="flex items-top space-x-3 mt-8">
                        <div>
                          <input
                            type="checkbox"
                            onClick={onCheckboxClick}
                            className="h-5 w-5 rounded-lg checked:bg-blue-600 checked:border-transparent mt-1"
                            defaultChecked={checked}
                          />
                        </div>
                        <p>
                          I agree that the information furnished above, belongs
                          to me.
                        </p>
                      </div>
                      {alert && (
                        <h1 className="text-sm text-red-500 my-2">
                          Please agree to continue
                        </h1>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-6 rounded-b border-blueGray-200">
                    <div className="flex space-x-2">
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={() => {
                          setClaimJob(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        // disabled={isDisabled}
                        onClick={() => {
                          continueClick();
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {modalNo === 2 && (
            <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none p-2">
              <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                <div
                  className="relative flex flex-col  bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none claim-mob"
                  style={{ minWidth: "400px", maxHeight: "400px" }}
                >
                  <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                    <h3 className="text-xl font-semibold md:text-2xl">
                      Available Jobs
                    </h3>
                    <p className="text-red-600 font-base-semibold">2/3</p>
                  </div>

                  <div className="px-6 pt-6 overflow-y-scroll h-96">
                    {!suggestionData?.data && <h1>Loading...</h1>}
                    <div ref={claimjobRef}></div>
                    {suggestedData?.map((singleJob, index) => {
                      let jobUrl = "/job-details/" + singleJob?.id;
                      let salarySubType = singleJob?.salary_subtype
                        ? Object.values(singleJob?.salary_subtype)
                        : "";
                      let salaryType = singleJob?.salary_type
                        ? Object.values(singleJob?.salary_type)
                        : "";

                      const handleClick = e => {
                        const { id, checked } = e.target;
                        setIsCheck([...isCheck, parseInt(id)]);
                        if (!checked) {
                          setIsCheck(isCheck.filter(item => item !== parseInt(id)));
                        }
                      };
                      return (
                        <div className="mb-4" key={index+pageNumber*usersPerPage}>
                          <div
                            className="single-job border p-4 flex-auto custom_shadow flex space-x-2 cursor-pointer"
                            index={singleJob}
                            onClick={(event) => {
                              if (event.target.localName !== "input") {
                                window.open(jobUrl, "_blank");
                              }
                            }}
                          >
                            <div className="flex-none relative top-1">
                              <input
                                onChange={handleClick}
                                checked={isCheck.includes(singleJob.id)}
                                id={singleJob?.id}
                                type="checkbox"
                                onClick={() => {
                                  // selectJob(singleJob?.id);
                                }}
                                className="h-5 w-5 rounded-lg checked:bg-blue-600 checked:border-transparent"
                              />
                            </div>

                            <div className="space-y-2">
                              <h1 className="text-base md:text-xl font-semibold mb-3 text-black">
                                {singleJob?.job_title
                                  ? singleJob?.job_title
                                  : singleJob?.position_label}
                              </h1>
                              {/* <p>
                                  {singleJob?.min_salary &&
                                    singleJob?.max_salary &&
                                    `$${singleJob.min_salary} -
                                      $${singleJob.max_salary} /hr`}
                                </p> */}
                              {salaryType[0] === "Fixed" && (
                                <p className="font-bold text-main">
                                  {singleJob.max_salary
                                    ? "$" +
                                      singleJob.max_salary.toLocaleString() +
                                      " "
                                    : ""}
                                  {singleJob.max_salary ? "/" : ""}{" "}
                                  {salarySubType[0]}
                                </p>
                              )}
                              {salaryType[0] !== "Fixed" && (
                                <p className="font-bold text-main">
                                  {singleJob?.min_salary ||
                                  singleJob?.max_salary ? (
                                    <>
                                      {singleJob?.min_salary
                                        ? "$" + singleJob?.min_salary + "/Hr"
                                        : ""}
                                      {singleJob?.min_salary &&
                                        singleJob?.max_salary &&
                                        "-"}
                                      {singleJob?.max_salary
                                        ? "$" + singleJob?.max_salary + "/Hr"
                                        : ""}
                                    </>
                                  ) : singleJob?.min_yearly_pay ||
                                    singleJob?.max_yearly_pay ? (
                                    <>
                                      {singleJob?.min_yearly_pay
                                        ? "$" +
                                          singleJob?.min_yearly_pay +
                                          "/Yr"
                                        : ""}
                                      {singleJob?.min_yearly_pay &&
                                        singleJob?.max_yearly_pay &&
                                        "-"}
                                      {singleJob?.max_yearly_pay
                                        ? "$" +
                                          singleJob?.max_yearly_pay +
                                          "/Yr"
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              )}
                              <p>{singleJob?.location_details?.address}</p>
                              <ul className="jobdetail-type">
                                {singleJob?.job_type_label?.map(
                                  (single_jobtype) => (
                                    <li key={single_jobtype}>
                                      {single_jobtype.name}
                                    </li>
                                  )
                                )}
                              </ul>
                              <p className="text-gray-500 font-sm-semibold">
                                Date Posted:{" "}
                                {singleJob?.created_at?.slice(0, 10)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {suggestedTempData?.length > 0 && (
                      <div className="mt-12">
                        <ReactPaginate
                          pageCount={suggestedPageCount}
                          onPageChange={suggestedChangePage}
                          containerClassName={
                            "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                          }
                          previousLabel="<"
                          previousLinkClassName={
                            "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                          }
                          nextLabel=">"
                          nextLinkClassName={
                            "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                          }
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </div>
                    )}
                  </div>

                  {claimId && (
                    <h1 className="text-sm text-red-500 my-2 px-6">
                      Please select at least one job to be claimed
                    </h1>
                  )}

                  <div className="flex items-center justify-between p-6 rounded-b border-blueGray-200">
                    <div className="flex flex-row">
                      <div className="form-check">
                        <input
                          className="h-5 w-5 rounded-lg checked:bg-blue-600 checked:border-transparent"
                          type="checkbox"
                          // onClick={(e) => {
                          //   setSelectAll(!selectAll)
                          // }}
                          onChange={handleSelectAll}
                          id="flexCheckDefault"
                        />
                        <label
                          className="p-1"
                          htmlFor="flexCheckDefault"
                        >
                          Select All
                        </label>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={() => {
                          setModalNo(1);
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        disabled={isDisabled}
                        onClick={handleSubmmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {modalNo === 3 && (
            <div className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none md:items-center top-50 focus:outline-none p-2">
              <div className="relative w-auto max-w-3xl mx-auto my-6 flex items-center">
                <div
                  className="relative flex flex-col w-full bg-white border-0 rounded-lg custom_shadow outline-none focus:outline-none claim-mob"
                  style={{ minWidth: "400px", maxHeight: "400px" }}
                >
                  <div className="flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200 third-window">
                    <h3 className="text-xl font-semibold md:text-2xl">
                      Available Jobs
                    </h3>
                    <p className="text-red-600 font-base-semibold">3/3</p>
                  </div>
                  <div className="p-6">{claimJobResponse?.message}</div>

                  <div className="flex items-center justify-end p-6 rounded-b border-blueGray-200">
                    <div className="flex space-x-2">
                      <button
                        className="flex items-center justify-center w-full mt-3 md:w-auto md:mt-0 btn btn-main"
                        onClick={() => {
                          setClaimJob(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClaimJob;
