import { useState, useEffect } from "react";
import usePostData from "../../../hooks/usePostData";

import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { fields, checkFields } from '../../../helpers/ChangePasswordScheme';

const ChangePassword = () => {
  const user_id = localStorage.getItem("user_id");

  const url = `users/${user_id}/password`;
  const loader = <span className="text-blue-500">Sending...</span>;

  const [formdata, setFormData] = useState(null);
  const [values, setValue] = useState(fields);
  const [errors, setErrors] = useState(null);
  const [commonMessage, setMessage] = useState(null);

  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [revealPassword, togglePasswordView] = useState(false);
  const [revealNewPassword, toggleNewPasswordView] = useState(false);
  const [revealConfirmPassword, toggleConfirmPasswordView] = useState(false);

  const updateValue = (e) => {
    setValue({ ...values, [e.target.name]: e.target.value });
  };

  const processForm = (e) => {
    e.preventDefault();
    const errList = checkFields(values);
    if (!errList) {
      setFormData(values);
    } else {
      setErrors(errList)
    }
  };

  const { data, error, loading } = usePostData(url, formdata);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      setMessage({ message: data.message, class: "success" });
      localStorage.setItem("accessToken", data.data.token);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");

      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = "/login";
      }, [2000]);
    }
  }, [data]);

  useEffect(() => {
    console.log(`url`, url);
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({ message: error.non_field_errors, class: "error" });
    }
  }, [error]);

  return (
    <div className="w-full p-5 bg-white rounded-md shadow-lg">
      <div className="w-full my-5 bg-white rounded-md  lg:w-8/12 xl:w-8/12">
        {commonMessage && (
          <p className={commonMessage.class}>{commonMessage.message}</p>
        )}
        <h1 className="mb-5 text-2xl font-semibold text-black">
          Change Password
        </h1>

        <form
          onSubmit={(e) => {
            processForm(e);
          }}
        >
          <div className="relative mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="old_password"
            >
              Old Password
            </label>
            <input
              type={revealPassword ? "text" : "password"}
              name="old_password"
              id="old_password"
              value={values.old_password}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e) => updateValue(e)}
            />
            <span className="form_error_message block mb-2">
              {errors?.old_password}
            </span>
            <HiOutlineEye
              onClick={() => togglePasswordView(!revealPassword)}
              className={`password_eye ${revealPassword ? "hidden" : ""}`}
            />
            <HiOutlineEyeOff
              onClick={() => togglePasswordView(!revealPassword)}
              className={`password_eye ${revealPassword ? "" : "hidden"}`}
            />
          </div>

          <div className="relative mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="new_password"
            >
              New Password
            </label>
            <input
              type={revealNewPassword ? "text" : "password"}
              name="new_password"
              id="new_password"
              value={values.new_password}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e) => updateValue(e)}
            />
            <HiOutlineEye
              onClick={() => toggleNewPasswordView(!revealNewPassword)}
              className={`password_eye ${revealNewPassword ? "hidden" : ""}`}
            />
            <HiOutlineEyeOff
              onClick={() => toggleNewPasswordView(!revealNewPassword)}
              className={`password_eye ${revealNewPassword ? "" : "hidden"}`}
            />
            <span className="form_error_message block mb-4">
              {errors?.new_password}
            </span>
          </div>

          <div className="relative mb-1">
            <label
              className="block mb-2 text-sm font-semibold text-gray-500"
              htmlFor="confirm_password"
            >
              Confirm New Password
            </label>
            <input
              type={revealConfirmPassword ? "text" : "password"}
              name="confirm_password"
              id="confirm_password"
              value={values.confirm_password}
              autoComplete="off"
              className="w-full px-3 py-3 mb-1 leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
              placeholder="********"
              onChange={(e) => updateValue(e)}
            />
            <HiOutlineEye
              onClick={() => toggleConfirmPasswordView(!revealConfirmPassword)}
              className={`password_eye ${
                revealConfirmPassword ? "hidden" : ""
              }`}
            />
            <HiOutlineEyeOff
              onClick={() => toggleConfirmPasswordView(!revealConfirmPassword)}
              className={`password_eye ${
                revealConfirmPassword ? "" : "hidden"
              }`}
            />
            <span className="form_error_message block mb-4">
              {errors?.confirm_password}
            </span>
          </div>

          <div className="mb-3">
            <button className="w-full btn btn-main">
              {loading ? loader : "Save Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
