import React, { useState, useCallback, memo, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

function SelectCreate({ benefitList, benefit, selectBenefit }) {
  const [options, setOptions] = useState([]);

  const handleCreate = useCallback(
    (inputValue) => {
      const newValue = { name: inputValue };
      setOptions((preOptions) => [...preOptions, newValue]);
      selectBenefit(newValue);
    },
    [options]
  );

  useEffect(() => {
    setOptions(benefitList ? benefitList.data?.response_data : []);
  }, [benefitList]);

  return (
    <div className="App">
      <CreatableSelect
        className="react-select-container"
        classNamePrefix="react-select"
        maxMenuHeight={205}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        options={benefitList?.data?.response_data}
        isClearable
        value={benefit}
        onChange={selectBenefit}
        onCreateOption={handleCreate}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          name: optionLabel,
        })}
      />
    </div>
  );
}

export default memo(SelectCreate);
