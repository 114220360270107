import { useContext, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import useGetData from "../../hooks/useGetData";
import { LookingToHireContext, MapContext } from "../../App";
import {
  AiOutlineSearch,
  AiOutlineEnvironment,
  AiOutlineClose,
} from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Select from "react-select";
import Highlighter from 'react-highlight-words';
import { IconContext } from "react-icons";
import locationIcon from "../../images/Home/locationIcon.svg";
import Map from "../elements/Map";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import NewsLetterPopup from "../modals/NewsLetterPopup";

const Home = () => {
  const [lookingToHire, setLookingToHire] = useContext(LookingToHireContext);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [currentLoc, setCurrentLoc] = useState(false);
  const [pos, setPos] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
  ] = useContext(MapContext);
  const [markerFlag] = useState(false);
  const [position, setPosition] = useState({
    name: null,
    id: null,
  });
  const [positionData, setPositionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showAllList, setShowAllList] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [positionClear, setPositionClear] = useState(tempPostion !== null);
  const [locationClear, setLocationClear] = useState(false);

  const history = useHistory();

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const url_category = params.get("category");
  const url_address = params.get("address");
  useEffect(() => {
    if (tempCoord) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: tempCoord.lat,
        lng: tempCoord.lan,
      }));
    } else {
      setMarkerLocation({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }

    if (homeCoordinates?.position) {
      setPosition(homeCoordinates.position);
    } else if (url_address) {
      setAddress(url_address);
      handleSelect(url_address);
      setLocationClear(true);
    } else {
      setAddress("");
    }
    if (url_category) {
      setPositionClear(true);
      setTempPosition({ id: 1, name: url_category });
    }
  }, []);

  const handleGeolocationMarker = (lat, lng) => {
    if (lat == null && lng == null) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        // lat: lat,
        // lng: lng,
        lat: marker.lat,
        lng: marker.lng,
      }));
    } else {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: lat,
        lng: lng,
        // lat: marker.lat,
        // lng: marker.lng,
      }));
    }
  };
  // useEffect(() => {
  //    console.log("render");
  //   handleGeolocationMarker();
  // }, []);

  const { data: positionList } = useGetData("categories");

  //Check whether the particular user already downloaded the newsletter pdf or not
  const user_id = localStorage.getItem("user_id");
  const { data: downloadStatus } = useGetData("flag-check");
  const isdownloaded = downloadStatus?.data?.is_downloaded;

  //remove the token passing for the public user in header
  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  //Cookie function declaration
  const getCookie = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
    null;

  //Newsletter popup for loggedin users
  useEffect(() => {
    setShowPopup(false);
    const isPopupShow = getCookie("isPopupShow");
    if (!isPopupShow && isdownloaded == false) {
      setTimeout(() => {
        setShowPopup(true);
      }, 3000);
    }
  }, [isdownloaded]);

  //Newsletter popup for non-loggedin users
  useEffect(() => {
    if (!user_id) {
      const isPopupShow = getCookie("isPopupShow");

      if (!isPopupShow) {
        setTimeout(() => {
          setShowPopup(true);
        }, 3000);
      }
    }
  }, []);

  useEffect(() => {
    setPositionData(positionList?.data?.response_data);
  }, [positionList]);

  //Sorted the position array of object in ascending order
  const sortedPositionData = positionData?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
  // Onclick function to show the position dropdown list
  const showPositionList = () => {
    setShowAllList(true);
  };

  /* Close the position dropdown on outside click */
  // function positionHide() {
  //   setHideDropdown(true);
  //   console.log("outside click");
  // }
  const positionListRef = useRef();
  const selectPositionInputRef = useRef();
  const selectAddressInputRef = useRef();

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        showAllList &&
        positionListRef.current &&
        !positionListRef.current.contains(e.target)
      ) {
        setShowAllList(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [showAllList]);

  const handleChange = (address) => {
    setAddress(address);
    setLocationClear(true);
  };

  const handlePosition = (e) => {
    const val = e.target.value;
    setPos(val);
    if (pos !== "") {
      setShowAllList(false);
    }
    if (val !== "") {
      const filteredPositions = positionData.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });

      setFilteredData(filteredPositions);
    } else {
      setFilteredData([]);
    }

    setPosition({ ...position, name: val });
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
    setTempCoord({
      lat: location.lat,
      lan: location.lng,
    });
  };

  let apiTimeout;

  useEffect(() => {
    setLoading(true);
  }, [homeCoordinates]);

  const getJobData = async (coordinates) => {
    let token = localStorage.getItem("accessToken");
    clearTimeout(apiTimeout);

    setHomeCoordinates((prev) => ({
      ...prev,
      coordinates: coordinates.coordinates,
    }));
    coordinates.position_name = tempPostion?.name;

    if (lookingToHire) {
      apiTimeout = setTimeout(() => {
        axios
          .post(`${process.env.REACT_APP_SEARCH_URL}/users`, coordinates, {
            headers: headerData,
          })
          .then((res) => {
            if (res.data) {
              setLoading(false);
              let hires = [...res.data];

              //Add data with same locations in arr
              // let arr = [];

              // for (let i = 0; i < jobs.length; i++) {
              //   for (let j = i + 1; j < jobs.length; j++) {
              //     if (
              //       jobs[i].latitude === jobs[j].latitude &&
              //       jobs[i].longtitude === jobs[j].longtitude
              //     ) {
              //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
              //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
              //     }
              //   }
              // }

              // //seperate same locations into different arrays
              // let obj = {};
              // let jobData = res.data;
              // for (let i = 0; i < jobData.length; i++) {
              //   obj[i] = [];
              //   for (let j = i + 1; j < jobData.length; j++) {
              //     if (
              //       jobData[i].latitude === jobData[j].latitude &&
              //       jobData[i].longtitude === jobData[j].longtitude
              //     ) {
              //       if (!obj[i].includes(jobData[i])) {
              //         obj[i].push(jobData[i]);
              //       }

              //       obj[i].push(jobData[j]);
              //       jobData.splice(j, 1);
              //       j = j - 1;
              //     }
              //   }
              // }

              // // deleting empty arrays in object
              // Object.keys(obj).forEach((i) => {
              //   if (obj[i].length === 0) {
              //     delete obj[i];
              //   }
              // });

              // //group same location data by company_name for jobs only
              // if(!lookingToHire){
              //   Object.keys(obj).forEach((i) => {
              //     let key =  'company_name';

              //     obj[i].modal_data =  obj[i]
              //     .reduce((hash, obj) => {
              //       if(obj[key] === undefined) return hash;
              //       return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
              //     }, {});

              //   });
              // }

              // //Remove same location data from other datas
              // const filteredJobData = jobs.filter(
              //   (i) => !arr.find((f) => f.id === i.id)
              // );
              setGroupData([]);
              // setSameLocationData(obj);

              // for clustering replacing lng for longitude and lat for latitude
              hires = hires.map(
                ({ longtitude: lng, latitude: lat, ...rest }) => ({
                  lng,
                  lat,
                  ...rest,
                })
              );
              setMapData(hires);
            }
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });
      }, 500);
    } else {
      if (zoomlvl == 4) {
        coordinates.step = 2.5;
      } else if (zoomlvl == 5) {
        coordinates.step = 1.5;
      } else if (zoomlvl == 6) {
        coordinates.step = 0.8;
      } else if (zoomlvl == 7) {
        coordinates.step = 0.4;
      }

      // if(zoomlvl <= 6 && !lookingToHire) coordinates.step = step;

      if (zoomlvl > 7) {
        axios
          .post(
            `${process.env.REACT_APP_SEARCH_URL}/alljobs/count`,
            coordinates,
            {
              headers: headerData,
            }
          )
          .then((res) => {
            if (res?.data?.total_count > 1000) {
              coordinates.step = zoomlvl < 10 ? 0.2 : 0.1;
            }
            apiTimeout =
              res?.data?.total_count > 1000
                ? setTimeout(() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          setLoading(false);
                          setMapData([]);
                          setSameLocationData([]);
                          setGroupData([...res.data.data]);
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500)
                : setTimeout(() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_SEARCH_URL}/jobs`,
                        coordinates,
                        {
                          headers: headerData,
                        }
                      )
                      .then((res) => {
                        if (res.data) {
                          setLoading(false);
                          let jobs = [...res.data];

                          // //Add data with same locations in arr
                          // let arr = [];

                          // for (let i = 0; i < jobs.length; i++) {
                          //   for (let j = i + 1; j < jobs.length; j++) {
                          //     if (
                          //       jobs[i].latitude === jobs[j].latitude &&
                          //       jobs[i].longtitude === jobs[j].longtitude
                          //     ) {
                          //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
                          //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
                          //     }
                          //   }
                          // }

                          // //seperate same locations into different arrays
                          // let obj = {};
                          // let jobData = res.data;
                          // for (let i = 0; i < jobData.length; i++) {
                          //   obj[i] = [];
                          //   for (let j = i + 1; j < jobData.length; j++) {
                          //     if (
                          //       jobData[i].latitude === jobData[j].latitude &&
                          //       jobData[i].longtitude === jobData[j].longtitude
                          //     ) {
                          //       if (!obj[i].includes(jobData[i])) {
                          //         obj[i].push(jobData[i]);
                          //       }

                          //       obj[i].push(jobData[j]);
                          //       jobData.splice(j, 1);
                          //       j = j - 1;
                          //     }
                          //   }
                          // }

                          // // deleting empty arrays in object
                          // Object.keys(obj).forEach((i) => {
                          //   if (obj[i].length === 0) {
                          //     delete obj[i];
                          //   }
                          // });

                          // //group same location data by company_name
                          // Object.keys(obj).forEach((i) => {
                          //   let key = "company_name";

                          //   obj[i].modal_data = obj[i].reduce((hash, obj) => {
                          //     if (obj[key] === undefined) return hash;
                          //     return Object.assign(hash, {
                          //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
                          //     });
                          //   }, {});
                          // });

                          // //Remove same location data from other datas
                          // const filteredJobData = jobs.filter(
                          //   (i) => !arr.find((f) => f.id === i.id)
                          // );

                          //sorting premium jobs
                          jobs.sort((a, b) => b.is_premium - a.is_premium);

                          // setSameLocationData(obj);

                          // for clustering replacing lng for longitude and lat for latitude
                          jobs = jobs.map(
                            ({ longtitude: lng, latitude: lat, ...rest }) => ({
                              lng,
                              lat,
                              ...rest,
                            })
                          );
                          console.log("jobs", jobs);
                          setMapData(jobs);
                          setGroupData([]);
                        }
                      })
                      .catch((err) => {
                        console.log("err :>> ", err);
                      });
                  }, 500);
          });
      } else {
        apiTimeout = setTimeout(() => {
          axios
            .post(
              `${process.env.REACT_APP_SEARCH_URL}/jobs/summary`,
              coordinates,
              {
                headers: headerData,
              }
            )
            .then((res) => {
              if (res.data) {
                setLoading(false);
                setMapData([]);
                setSameLocationData([]);
                setGroupData([...res.data.data]);
              }
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        }, 500);
      }
    }
  };

  const initRef = useRef(true);
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      getJobData(homeCoordinates);
    }
  }, [lookingToHire, tempPostion]);

  const Indicator = () => {
    return (
      <div className="rounded w-10 h-10 right-0 blue_box bg-main top-1 p-1 siftright">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (pos === "") {
              setShowAllList((old) => !old);
            }
          }}
        >
          <MdOutlineKeyboardArrowDown
            className="mx-auto w-7 h-full"
            color="fff"
          />
        </div>
      </div>
    );
  };

  const updatePositionSelectValue = (value) => {
    setTempPosition(value);
    if (value) setPositionClear(true);
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? 'grey' : 'red',
    }),
    menu: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? 'grey' : 'red',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      textAlign: "left",
      marginLeft: "15px",
      textColor: state.isFocused ? 'grey' : 'red',
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      textAlign: "left",
      textColor: state.isFocused ? 'grey' : 'red',
    }),

    input: (provided, state) => ({
      ...provided,
      textColor: state.isFocused ? 'grey' : 'red',
      // marginLeft: "18px",
    }),
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lan: position.coords.longitude,
        });
        if (
          !localStorage.getItem("longitude") ||
          !localStorage.getItem("latitude")
        ) {
          localStorage.setItem("longitude", position.coords.longitude);
          localStorage.setItem("latitude", position.coords.latitude);
        }
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  const handleClickCurrentLoc = () => {
    if (currentLocation) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: currentLocation.lat,
        lng: currentLocation.lan,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lan}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          setCurrentLoc(false);
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          // handleSelect(route?.formatted_address)
          setAddress("Current Location");
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const currentLocModalref = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        currentLoc &&
        currentLocModalref.current &&
        !currentLocModalref.current.contains(e.target)
      ) {
        setCurrentLoc(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [currentLoc]);
  const openCurrentLoc = (e) => {
    if (e.target.localName !== "img") {
      setCurrentLoc(true);
    }
  };
  console.log(selectPositionInputRef.current,"aad...............")

  function formatOptionLabel({ name }, { inputValue }) {
    return <Highlighter searchWords={[inputValue]} textToHighlight={name} highlightClassName="text-red-500  border-red-500 bg-transparent"/>;
  }
  
  // const options = [
  //   { id: 1, name: "Some" },
  //   { id: 2, name: "Example" }
  // ];
  

  return (
    <>
      <div className="relative flex flex-col items-center justify-center py-4 text-center container-wrapper home-banner bg-main">
        <div className="flex flex-col items-center justify-center home_banner_content">
          <h1 className="flex flex-col text-2xl sm:text-3xl text-center text-white capitalize md:text-center font-semi bold md:text-5xl">
            job search for <span>healthcare professionals</span>
          </h1>

          {/* <p className="w-full text-center text-white mt-7 md:text-center lg:w-3/5 md:px-20">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
            consequuntur in deserunt quod expedita atque beatae numquam
            voluptates quaerat molestias
          </p> */}
        </div>
        <div className="relative content-around justify-center w-full my-3 md:my-1 top-5 md:flex-row home_banner_search md:top-3">
          <IconContext.Provider
            value={{
              color: "grey",
              className: "global-class-name",
              size: "1.3em",
            }}
          >
            <div className="flex items-center justify-center mb-5 md:mb-10 xl:hidden">
              <button
                href="#"
                className={`bg-white btn-base w-full md:w-auto ${
                  lookingToHire ? "" : "bg-sub text-white"
                }`}
                onClick={() => {
                  setLookingToHire(false);
                }}
              >
                Job Seekers
              </button>
              <button
                href="#"
                className={`ml-2 bg-white btn-base w-full md:w-auto ${
                  lookingToHire ? "bg-sub text-white" : ""
                }`}
                onClick={() => {
                  setLookingToHire(true);
                }}
              >
                Looking to Hire
              </button>
            </div>
            <div className="flex flex-col mb-4 justify-center md:flex-row">
              <div
                className="relative my-1 shadow-md md:mx-2 md:my-0 home_banner_input_box"
                ref={positionListRef}
              >
                <Select
                  ref={selectPositionInputRef}
                  formatOptionLabel={formatOptionLabel}
                  styles={SelectStyles}
                  className="react-select-container position_selector"
                  classNamePrefix="react-select"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={positionList?.data?.response_data.sort((a, b) => {
                    var x = a.name.toLowerCase();
                    var y = b.name.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                  })}
                  
                  components={{ DropdownIndicator: Indicator }}
                  placeholder={<h2 className="flex justify-start">Position</h2>}
                  name="position"
                  id="position"
                  value={tempPostion}
                  onChange={updatePositionSelectValue}
                />
                {positionClear && (
                  <AiOutlineClose
                    className="absolute top-4 right-11 cursor-pointer"
                    onClick={() => {
                      selectPositionInputRef.current.clearValue();
                      setPositionClear(false);
                    }}
                  />
                )}
                <AiOutlineSearch className="absolute top-4 left-3" />
              </div>
              <div
                className="relative my-1 shadow-md md:mx-2 home_banner_input_box md:my-0"
                onClick={openCurrentLoc}
                ref={currentLocModalref}
              >
                <AiOutlineSearch className="absolute top-4 left-3" />
                {/* <input
                  type="text"
                  name=""
                  className="w-full p-3 pl-10 rounded outline-none md:w-64 lg:w-80"
                  placeholder="example text"
                /> */}
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  onError={(err) => console.log(err)}
                  ref={selectAddressInputRef}
                  // searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          type: "text",
                          autoComplete: "off",
                          className:
                            "w-full p-3 pl-10 rounded outline-none md:w-64 lg:w-80 truncate auto-address",
                          placeholder: "Address",
                          // onBlur: handleBlur,
                          // onChange={(e) => updateValue(e)
                        })}
                      />
                      <div className="absolute w-full z-10">
                        {loading && <div>Loading...</div>}
                        {currentLoc && !address && (
                          <h2
                            className="text-left px-4 py-3 bg-white cursor-pointer flex"
                            style={{ color: "#007FEE" }}
                            onClick={handleClickCurrentLoc}
                          >
                            <span>
                              <AiOutlineEnvironment
                                className="relative top-0"
                                color="#007FEE"
                                size={28}
                              />
                            </span>

                            <h3 className="relative px-3 text-lg">Current Location</h3>
                          </h2>

                           

                        )}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "bg-gray-700 p-2 text-left"
                            : "p-2 text-left";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          if (suggestion.terms.length > 1) {
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {locationClear && (
                  <AiOutlineClose
                    className="absolute top-4 right-11 cursor-pointer"
                    onClick={() => {
                      setAddress("");
                      setLocationClear(false);
                    }}
                  />
                )}
                <img
                  className="absolute w-10 rounded right-1 blue_box bg-main top-1 cursor-pointer"
                  src={locationIcon}
                  alt="location icon"
                  onClick={handleClickCurrentLoc}
                  loading="lazy"
                  title="Location"
                />
              </div>

              {lookingToHire ? (
                <button
                  type="submit"
                  onClick={() => {
                    history.push("/hireSearch");
                  }}
                  className="my-1 md:mx-2 btn btn-sub md:my-0"
                >
                  Find Employee
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    history.push(`/jobSearch${(homeCoordinates?.position_name || address) && "/"}${homeCoordinates?.position_name ? homeCoordinates?.position_name+"-jobs" : ""}${address ? "jobs-in-"+address : ""}`);
                  }}
                  className="my-1 md:mx-2 btn btn-sub md:my-0"
                >
                  Find Jobs
                </button>
              )}
            </div>
          </IconContext.Provider>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-center lg:mx-auto lg:w-full z-0">
        {/* <img
          src={mapImg}
          alt=""
          className="m-0 mb-5 w-12/12 md:w-11/12 md:m-7"
        /> */}
        <div
          style={{ minHeight: "calc(100vh - 534px)", height: "50vh" }}
          className="z-0 w-full shadow-lg"
        >
          {/* <img src={map} alt="" width="100%" /> */}
          <Map
            zoomLevel={zoomlvl}
            lookingToHire={lookingToHire}
            geolocationMarker={handleGeolocationMarker}
            getJobData={getJobData}
            markerFlag={markerFlag}
            showZoom={true}
            loading={loading}
          />
        </div>

        {showPopup && <NewsLetterPopup setShowPopup={setShowPopup} />}

        {/* <h1 className="text-lg font-bold text-center capitalize text-main">
          how it works
        </h1>
        <div className="w-10 h-0.5 bg-red-500 red_line my-5"></div>
        <p className="w-full px-5 md:w-3/5 md:px-0">
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet.
        </p> */}
      </div>
      {/* <div className="my-20 cards">
        <div className="grid grid-cols-1 gap-5 mx-5 lg:mx-auto lg:w-4/5 md:grid-cols-3">
          <div className="p-5 text-center rounded shadow-md cards">
            <img
              src={searchForAddress}
              alt=""
              className="w-auto mx-auto md:w-4/5"
              width="190px"
            />

            <p className="py-2 mt-3 font-semibold capitalize text-main">
              search for an address
            </p>
            <p className="py-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
          <div className="p-5 text-center rounded shadow-md cards">
            <img
              src={moreDetails}
              alt=""
              className="w-auto mx-auto md:w-4/5"
              width="190px"
            />

            <p className="py-2 mt-3 font-semibold capitalize text-main">
              Click on each result to see more details
            </p>
            <p className="py-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit
            </p>
          </div>
          <div className="p-5 text-center rounded shadow-md cards">
            <img
              src={applyToJob}
              alt=""
              className="w-auto mx-auto md:w-3/5"
              width="150px"
            />

            <p className="py-2 mt-3 font-semibold capitalize text-main">
              apply to job
            </p>
            <p className="py-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
