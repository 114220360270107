import { useState, useEffect } from "react";
import usePostData from "../../hooks/usePostData";
import useGetData from "../../hooks/useGetData";
import { useParams } from "react-router-dom";

function UnsubscribeNewsletter() {
  const [radioValue, setRadioValue] = useState(null);
  const [isMoreReason, setIsMoreReason] = useState(null);
  const [formdata, setFormData] = useState(null);
  const [viewMoreReason, setViewMoreReason] = useState(false);
  const [commonMessage, setMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [otherOptionError, setOtherOptionError] = useState(null);

  let params = useParams();
  let user_role = params.user_role;
  let uid = params.uid;
  let token = params.token;
  let email_notification = params.email_template;

  //Get the reason array
  const { data: ReasonData, loading: getloading } = useGetData(
    `unsubscribe-mail/${user_role}/${uid}/${token}/${email_notification}`
  );

  //Post request
  const url = `unsubscribe-mail/${user_role}/${uid}/${token}/${email_notification}`;
  const { data, error, loading } = usePostData(url, formdata);

  useEffect(() => {
    if (radioValue == 5 || radioValue == 10) {
      setViewMoreReason(true);
    } else {
      setViewMoreReason(false);
    }
  }, [radioValue]);

  const processForm = (e) => {
    e.preventDefault();
    if (viewMoreReason && isMoreReason == null) {
      setOtherOptionError("The can't be empty");
      setFormData("");
    } else {
      setFormData({
        user_role,
        uid,
        temp_token: token,
        email_template: email_notification,
        reason: radioValue,
        more_reason: isMoreReason,
      });
      setOtherOptionError("");
    }
  };

  useEffect(() => {
    if (data) {
      setMessage({ message: data.message, class: "unsubscribe-success" });
      setRadioValue(null);
      setIsMoreReason("");
    }
  }, [data]);

  useEffect(() => {
    setErrors(error);
    if (error?.non_field_errors) {
      setMessage({
        message: error.non_field_errors,
        class: "unsubscribe-error",
      });
    }
    if (error?.message) {
      setMessage({ message: error.message, class: "unsubscribe-error" });
    }
  }, [error]);

  return (
    <div className="minHeight relative flex flex-col items-center justify-center py-10 bg-gray-100 container-wrapper">
      <div className="w-full p-5 my-5 bg-white rounded-md shadow-lg lg:w-8/12 xl:w-6/12">
        <h1 className="my-3 text-3xl font-semibold text-black uppercase">
          You Will Be Missed
        </h1>
        {user_role == 1 ? (
          <p className="mb-5 text-xl font-medium">
            Please take a moment to tell us why you would no longer like to
            receive daily email of jobs.
          </p>
        ) : (
          <p className="mb-5 text-xl font-medium">
            Please take a moment to tell us why you would no longer like to
            receive daily email of candidates.
          </p>
        )}

        <form
          onSubmit={(e) => {
            processForm(e);
          }}
        >
          {!getloading ? (
            <>
              {ReasonData?.map((single_reason) => (
                <div>
                  <input
                    type="radio"
                    name="dynamic-radio"
                    id={single_reason.reason}
                    value={single_reason.id}
                    className="checked:bg-blue-600 checked:border-transparent"
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  <label
                    className="ml-2 text-lg"
                    htmlFor={single_reason.reason}
                  >
                    {single_reason.reason}
                  </label>
                </div>
              ))}

              {viewMoreReason && (
                <textarea
                  id="more_reason"
                  value={isMoreReason}
                  name="more_reason"
                  class="mt-1 block w-full rounded-md border-gray-200 border-2 p-5 focus:outline-none focus:shadow-outline"
                  placeholder="Write your reason"
                  rows="4"
                  onChange={(e) => setIsMoreReason(e.target.value)}
                />
              )}
              <span className="unsubscribe-error">{otherOptionError}</span>

              <div class="mt-5">
                <button class="btn btn-main">Unsubscribe</button>
              </div>
            </>
          ) : (
            <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
          )}

          {commonMessage && (
            <p className={commonMessage.class}>{commonMessage.message}</p>
          )}
        </form>
      </div>
    </div>
  );
}

export default UnsubscribeNewsletter;
