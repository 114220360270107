import Logo from "../../images/Footer/logo.png";
import FooterCard from "../../images/Footer/FooterCard.svg";
import facebookImg from "../../images/Footer/facebook.svg";
import googleImg from "../../images/Footer/google.svg";
import linkedinImg from "../../images/Footer/linkedin.svg";
import twitterImg from "../../images/Footer/twitter.svg";
import instagramImg from "../../images/Footer/instagram.svg";
import { useContext } from "react";
import { UserContext } from "../../App";

const Footer = () => {
  const { username } = useContext(UserContext);

  return (
    <footer className="py-5 text-white container-wrapper bg-main">
      <div className="flex flex-col items-start justify-between md:flex-row md:items-center">
        <div className="ring_of_hires_section w-70 md:w-80">
          <a href="/"><img className="py-3 w-52" src={Logo} alt="ROH footer logo" title="ROH light logo" loading="eager" /></a>
          {/* <p className="py-1 font-xl-light">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p> */}
          <p className="py-4">©RING OF HIRES 2021. All rights reserved</p>
          <div className="flex">
            <a href="https://www.facebook.com/ringofhires" target="_blank">
              <img
                className="w-8 mx-1 bg-white rounded-md"
                src={facebookImg}
                alt="Facebook icon"
                title="Facebook"
                loading="lazy"
              />
            </a>
            <a href="mailto:support@ringofhires.com">
              <img
                className="w-8 mx-1 bg-white rounded-md"
                src={googleImg}
                alt="Google icon"
                title="Google"
                loading="lazy"
              />
            </a>
            <a href="https://www.linkedin.com/in/ringofhires" target="_blank">
              <img
                className="w-8 mx-1 bg-white rounded-md"
                src={linkedinImg}
                alt="Linkedin icon"
                title="Linkedin"
                loading="lazy"
              />
            </a>
            <a href="https://twitter.com/RingofHires" target="_blank">
              <img
                className="w-8 mx-1 bg-white rounded-md"
                src={twitterImg}
                alt="Twitter icon"
                title="Twitter"
                loading="lazy"
              />
            </a>
            <a href="https://www.instagram.com/ring.of.hires" target="_blank">
              <img
                className="w-8 mx-1 bg-white rounded-md"
                src={instagramImg}
                alt="Instagram icon"
                title="Instagram"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="md:hidden flex flex-wrap -mx-2 space-x-8 pl-4">
        <div className="mt-5 capitalize company_section font-base-light md:mt-0">
          <h3 className="py-3 font-xl-semibold">company</h3>
          <div className="flex flex-col">
            <a href="/" className="py-1">
              home
            </a>
            <a href="/about-us" className="py-1">
              About Us
            </a>
            <a href="/partner-with-us" className="py-1">
              Partner With Us
            </a>
            <a href="/contact-us" className="py-1">
              contact
            </a>
            {username ? (
              <div className="py-1 text-main">
                .
              </div>
            ) 
            : (
              <a href="/login" className="py-1">
                  Sign in
              </a>
            )
            }
          </div>
        </div>
        <div className="mt-5 capitalize help_section font-base-light md:mt-0">
          <h3 className="py-3 font-xl-semibold">help</h3>
          <div className="flex flex-col">
            <a href="/help-center" className="py-1">
              Help Center
            </a>
            <a href="/terms-of-service" className="py-1">
              Terms of service
            </a>
            <a href="/privacy-policy" className="py-1">
              privacy policy
            </a>
          </div>
        </div>
        </div>
        {/* desktop view */}
        <div className="hidden md:block mt-5 capitalize company_section font-base-light md:mt-0 pt-14">
          <h3 className="py-3 font-xl-semibold">company</h3>
          <div className="flex flex-col">
            <a href="/" className="py-1">
              home
            </a>
            <a href="/about-us" className="py-1">
              About Us
            </a>
            <a href="/partner-with-us" className="py-1">
              Partner With Us
            </a>
            <a href="/contact-us" className="py-1">
              contact
            </a>
            {username ? (
              <div className="py-1 text-main">
                .
              </div>
            ) 
            : (
              <a href="/login" className="py-1">
                  Sign in
              </a>
            )
            }
          </div>
        </div>
        <div className=" hidden md:block capitalize help_section font-base-light md:mt-0 m-[-10px]">
          <h3 className="py-3 font-xl-semibold">help</h3>
          <div className="flex flex-col">
            <a href="/help-center" className="py-1">
              Help Center
            </a>
            <a href="/terms-of-service" className="py-1">
              Terms of service
            </a>
            <a href="/privacy-policy" className="py-1">
              privacy policy
            </a>
          </div>
        </div>
        <div className="hidden footer_icon w-52 h-52 lg:block">
          <img className="relative top-10" src={FooterCard} alt="ROH footer pic" title="ROH footer graphics" loading="eager" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
