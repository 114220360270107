import React, { useState, useCallback,memo, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

function SelectCreate({skillList,skill,selectSkill}) {
    

    const [options,setOptions] = useState([]);

    const handleCreate = useCallback(
        (inputValue) => {
            const newValue = { id: inputValue.toLowerCase(), skill_name: inputValue };
            setOptions(preOptions => [...preOptions, newValue]);
            selectSkill(newValue);
        },
        [options]
    );

    useEffect(()=>{
        setOptions(skillList?skillList.data.response_data:[])
    },[skillList])

    return (
        <div className="App">
            <CreatableSelect
                className="react-select-container"
                classNamePrefix="react-select"
                getOptionLabel={option => option.skill_name}
                getOptionValue={option => option.id}
                options={skillList?.data?.response_data}
                isClearable
                value={skill}
                onChange={selectSkill}
                onCreateOption={handleCreate}
                getNewOptionData={(inputValue, optionLabel) =>( { id: inputValue, skill_name: optionLabel })}
            />
        </div>
    );


}

export default memo(SelectCreate);