import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import locationIcon from "../../images/JobSearch/locationIcon.png";
import {
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineEnvironment,
} from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import filterIcon from "../../images/JobSearch/filterIcon.png";
import ads from "../../images/JobSearch/ads.png";
import filterActive from "../../images/JobSearch/filterActive.png";
import Map from "../elements/Map";
import ReactSlider from "react-slider";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { MapContext } from "../../App";
import Select from "react-select";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import RohLogo from "../../images/ROH-logo.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import usePostData from "../../hooks/usePostData";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import Highlighter from "react-highlight-words";

const HireSearch = () => {
  const userId = localStorage.getItem("user_id");
  const [filter, setFilter] = useState(true);
  const [markerFlag, setmarkerFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [listingData, setListingData] = useState([]);
  const [addressError, setAddressError] = useState("");
  const [locationFlag, setLocationFlag] = useState(false);
  const [values, setValues] = useState({});
  const [currentLoc, setCurrentLoc] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRadius, setShowRadius] = useState(false);
  const [locationClear, setLocationClear] = useState(false);
  const [data, setData] = useState([]);
  const [
    mapData,
    setMapData,
    sameLocationData,
    setSameLocationData,
    marker,
    setMarkerLocation,
    address,
    setAddress,
    filterValues,
    setFilterValues,
    homeCoordinates,
    setHomeCoordinates,
    zoomlvl,
    setZoomlvl,
    tempCoord,
    setTempCoord,
    tempPostion,
    setTempPosition,
    groupData,
    setGroupData,
    step,
    setStep,
    showModal,
    setShowModal,
    circles,
    setCircles,
  ] = useContext(MapContext);

  const history = useHistory();

  const { data: positionList } = useGetData("categories");

  const { data: jobType } = useGetData("job-types");

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");

  //Access control API call
  const [accessUrl, setAccessUrl] = useState(`access/1010`);
  const { data: AccessData, loading: filterLoad } = useGetData(accessUrl);

  //for Parallax effect checking if mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  function featureFilter() {
    let sample = false;
    AccessData?.data?.map((single_access_item) => {
      if (single_access_item.feature === 3 && userId) {
        sample = true;
      }
    });
    return sample;
  }

  //check queries in url
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    if (tempCoord) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: tempCoord.lat,
        lng: tempCoord.lan,
      }));
      setTempCoord(null);
    }
  }, []);
  //check for position query in url
  useEffect(() => {
    // if (!userId) {
    //   window.location.href = "/login";
    // }

    if (query.get("position")) {
      const positionStr = query.get("position").replace(/_/g, " ");
      setFilterValues((prev) => ({
        ...prev,
        position: { id: null, name: positionStr },
      }));
    }

    if (query.get("location")) {
      setAddress(query.get("location"));
    }

    setmarkerFlag(true);

    return () => {
      setFilterValues((prev) => ({
        ...prev,
        position: null,
        min: 1,
        max: 50,
        jobType: [],
        radius: 0,
        salary: false,
      }));
    };
  }, []);

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (homeCoordinates?.coordinates) {
      setFlag(true);
    }

    if (homeCoordinates?.position) {
      setFilterValues({
        ...filterValues,
        position: homeCoordinates.position,
      });
    }
  }, []);

  // set full time as default
  // useEffect(() => {
  //   if (jobType) {
  //     const types = jobType?.data?.response_data;
  //     const filteredType = types.filter((type) => type.name === "Full Time");

  //     if (filteredType?.length > 0) {
  //       setFilterValues({
  //         ...filterValues,
  //         jobType: [filteredType[0].id],
  //       });
  //     }
  //   }
  // }, [jobType]);

  let token = localStorage.getItem("accessToken");
  let headerData = {
    "Content-Type": "application/json",
  };

  if (token) {
    headerData.Authorization = `Token ${token}`;
  }

  useEffect(() => {
    if (Object.keys(homeCoordinates).length !== 0) {
      let token = localStorage.getItem("accessToken");

      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: marker.lat,
        lng: marker.lng,
      }));
      setCoordinates(homeCoordinates);

      const sendData = {
        coordinates: homeCoordinates.coordinates,
        position_name: homeCoordinates.position_name,
      };
      axios
        .post(`${process.env.REACT_APP_SEARCH_URL}/users`, sendData, {
          headers: headerData,
        })
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setFlag(false);
            let hires = [...res.data];

            // //Add data with same locations in arr
            // let arr = [];

            // for (let i = 0; i < hires.length; i++) {
            //   for (let j = i + 1; j < hires.length; j++) {
            //     if (
            //       hires[i].latitude === hires[j].latitude &&
            //       hires[i].longtitude === hires[j].longtitude
            //     ) {
            //       !arr.includes(hires[i]) && arr.push(hires[i]);
            //       !arr.includes(hires[j]) && arr.push(hires[j]);
            //     }
            //   }
            // }

            // //seperate same locations into different arrays
            // let obj = {};
            // let hireData = res.data;
            // for (let i = 0; i < hireData.length; i++) {
            //   obj[i] = [];
            //   for (let j = i + 1; j < hireData.length; j++) {
            //     if (
            //       hireData[i].latitude === hireData[j].latitude &&
            //       hireData[i].longtitude === hireData[j].longtitude
            //     ) {
            //       if (!obj[i].includes(hireData[i])) {
            //         obj[i].push(hireData[i]);
            //       }

            //       obj[i].push(hireData[j]);
            //       hireData.splice(j, 1);
            //       j = j - 1;
            //     }
            //   }
            // }

            // // deleting empty arrays in object
            // Object.keys(obj).forEach((i) => {
            //   if (obj[i].length === 0) {
            //     delete obj[i];
            //   } else {
            //     obj[i].sort((a, b) => b.is_premium - a.is_premium);
            //   }
            // });

            // //Remove same location data from other datas
            // const filteredHireData = hires.filter(
            //   (i) => !arr.find((f) => f.id === i.id)
            // );

            //sorting premium hires
            hires.sort((a, b) => b.is_premium - a.is_premium);

            // setSameLocationData(obj);
            setListingData(hires);
            // for clustering replacing lng for longitude and lat for latitude
            hires = hires.map(
              ({ longtitude: lng, latitude: lat, ...rest }) => ({
                lng,
                lat,
                ...rest,
              })
            );

            setMapData(hires);
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  }, []);

  const handleGeolocationMarker = (lat, lng) => {
    if (!homeCoordinates || Object.keys(homeCoordinates).length === 0) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: lat,
        lng: lng,
      }));
    }
  };

  const updateSalary = (value) => {
    setFilterValues({ ...filterValues, min: value[0], max: value[1] });
  };

  const updateSalaryFilter = () => {
    console.log("salary updated");
    setValues({
      ...values,
      coordinates: coordinates.coordinates,
      salary: {
        min: filterValues.min,
        max: filterValues.max,
      },
    });
  };

  const updateType = (e, id) => {
    const check = e.target.checked;
    if (check) {
      setFilterValues({
        ...filterValues,
        jobType: [...filterValues.jobType, id],
      });
    } else {
      const filterData = filterValues.jobType.filter((item) => {
        return item !== id;
      });
      setFilterValues({
        ...filterValues,
        jobType: filterData,
      });
    }
  };

  const updatePositionSelectValue = (value) => {
    setTempPosition(value);
    setFilterValues({ ...filterValues, position: value });
    setValues({
      ...values,
      coordinates: coordinates.coordinates,
      position_name: value.name,
    });
  };

  const updateRadius = (value) => {
    setFilterValues({ ...filterValues, radius: value });
    setShowRadius(true);
  };
  const updateRadiusFilter = (value) => {
    setTimeout(() => {
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        radius: value,
      });
      setRefresh(!refresh);
    }, 1000);
  };

  const updateSalaryShow = (e) => {
    setFilterValues({ ...filterValues, salary: !filterValues.salary });
    setValues((current) => {
      const { salary, ...rest } = current;
      return rest;
    });
  };

  const calculateAnnualPay = useCallback((pay) => {
    let yearlyPay = pay * 261 * 8;
    return yearlyPay;
  }, []);

  useEffect(() => {
    if (tempPostion) {
      setFilterValues({ ...filterValues, position: tempPostion });
      setValues({
        ...values,
        coordinates: coordinates.coordinates,
        position_name: tempPostion.name,
      });
    }
  }, []);

  const updateListing = (arr) => {
    if (arr) {
      //sorting premium hire
      arr.sort((a, b) => b.is_premium - a.is_premium);

      setListingData(arr);
    }
  };

  const handleChange = (address) => {
    setAddress(address);
    setLocationClear(true);
    if (locationFlag) {
      setLocationFlag(false);
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    setLocationFlag(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => getSelectedLocation(latLng))
      .catch((error) => console.error("Error", error));
  };

  const getSelectedLocation = (location) => {
    setMarkerLocation((currentData) => ({
      ...currentData,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  let apiTimeout;

  useEffect(() => {
    setLoading(true);
  }, [homeCoordinates]);

  // debounced function to prevent unnecessary api calls
  const getJobData = (coordinates, cb) => {
    if (!flag) {
      // clear home coorinate data since it is not required as the map has changed
      if (homeCoordinates) {
        setHomeCoordinates({});
      }

      let token = localStorage.getItem("accessToken");
      clearTimeout(apiTimeout);

      apiTimeout = setTimeout(() => {
        coordinates.position_name = filterValues.position
          ? filterValues.position?.name
          : null;
        if (filterValues.salary) {
          coordinates.salary = {
            min: filterValues.min,
            max: filterValues.max,
          };
        } else {
          coordinates.salary = null;
        }
        setCoordinates(coordinates);

        axios
          .post(`${process.env.REACT_APP_SEARCH_URL}/users`, coordinates, {
            headers: headerData,
          })
          .then((res) => {
            if (res.data) {
              setLoading(false);
              let hires = [...res.data];

              // //Add data with same locations in arr
              // let arr = [];

              // for (let i = 0; i < hires.length; i++) {
              //   for (let j = i + 1; j < hires.length; j++) {
              //     if (
              //       hires[i].latitude === hires[j].latitude &&
              //       hires[i].longtitude === hires[j].longtitude
              //     ) {
              //       !arr.includes(hires[i]) && arr.push(hires[i]);
              //       !arr.includes(hires[j]) && arr.push(hires[j]);
              //     }
              //   }
              // }

              // //seperate same locations into different arrays
              // let obj = {};
              // let hireData = res.data;
              // for (let i = 0; i < hireData.length; i++) {
              //   obj[i] = [];
              //   for (let j = i + 1; j < hireData.length; j++) {
              //     if (
              //       hireData[i].latitude === hireData[j].latitude &&
              //       hireData[i].longtitude === hireData[j].longtitude
              //     ) {
              //       if (!obj[i].includes(hireData[i])) {
              //         obj[i].push(hireData[i]);
              //       }

              //       obj[i].push(hireData[j]);
              //       hireData.splice(j, 1);
              //       j = j - 1;
              //     }
              //   }
              // }

              // // deleting empty arrays in object
              // Object.keys(obj).forEach((i) => {
              //   if (obj[i].length === 0) {
              //     delete obj[i];
              //   } else {
              //     obj[i].sort((a, b) => b.is_premium - a.is_premium);
              //   }
              // });

              // //Remove same location data from other datas
              // const filteredHireData = hires.filter(
              //   (i) => !arr.find((f) => f.id === i.id)
              // );

              //sorting premium hires
              hires.sort((a, b) => b.is_premium - a.is_premium);

              // setSameLocationData(obj);
              setListingData(hires);
              hires = hires.map(
                ({ longtitude: lng, latitude: lat, ...rest }) => ({
                  lng,
                  lat,
                  ...rest,
                })
              );

              setMapData(hires);

              // callback to execute when a circle is drawn
              if (cb) cb();
            }
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });
      }, 500);
    }
  };

  const handleBlur = () => {
    if (!locationFlag) {
      setAddressError("Please select location from the list");
      setTimeout(() => {
        setAddressError("");
      }, 3000);
    }
  };

  const setRedirect = () => {
    localStorage.setItem("redirectToJobSh", true);
    history.push("/profile/my-plan");
  };

  // const { data } = usePostData(`/users`, values, "application/json", "search");

  const initRef = useRef(true);
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    } else {
      if (!values?.coordinates) return;
      axios
        .post(`${process.env.REACT_APP_SEARCH_URL}/users`, values, {
          headers: headerData,
        })
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          setData(res?.data);
        });
    }
  }, [values]);

  useEffect(() => {
    if (data) {
      let hires = [...data];

      // //Add data with same locations in arr
      // let arr = [];

      // for (let i = 0; i < jobs.length; i++) {
      //   for (let j = i + 1; j < jobs.length; j++) {
      //     if (
      //       jobs[i].latitude === jobs[j].latitude &&
      //       jobs[i].longtitude === jobs[j].longtitude
      //     ) {
      //       !arr.includes(jobs[i]) && arr.push(jobs[i]);
      //       !arr.includes(jobs[j]) && arr.push(jobs[j]);
      //     }
      //   }
      // }

      // //seperate same locations into different arrays
      // let obj = {};
      // let jobData = data;
      // for (let i = 0; i < jobData.length; i++) {
      //   obj[i] = [];
      //   for (let j = i + 1; j < jobData.length; j++) {
      //     if (
      //       jobData[i].latitude === jobData[j].latitude &&
      //       jobData[i].longtitude === jobData[j].longtitude
      //     ) {
      //       if (!obj[i].includes(jobData[i])) {
      //         obj[i].push(jobData[i]);
      //       }

      //       obj[i].push(jobData[j]);
      //       jobData.splice(j, 1);
      //       j = j - 1;
      //     }
      //   }
      // }

      // // deleting empty arrays in object
      // Object.keys(obj).forEach((i) => {
      //   if (obj[i].length === 0) {
      //     delete obj[i];
      //   } else {
      //     obj[i].sort((a, b) => b.is_premium - a.is_premium);
      //   }
      // });

      // //Remove same location data from other datas
      // const filteredJobData = jobs.filter(
      //   (i) => !arr.find((f) => f.id === i.id)
      // );

      //sorting premium jobs
      hires.sort((a, b) => b.is_premium - a.is_premium);

      // setSameLocationData(obj);
      setListingData(hires);
      hires = hires.map(({ longtitude: lng, latitude: lat, ...rest }) => ({
        lng,
        lat,
        ...rest,
      }));

      setMapData(hires);
    }
    if (showRadius) {
      let arr = [];
      let allData = [];
      let sameArr = [];

      Object.values(sameLocationData).forEach((item) => {
        sameArr = [...sameArr, ...item];
      });
      allData = [...data];
      const center = {
        lat: marker.lat,
        lng: marker.lng,
      };
      allData.forEach((item) => {
        const resultColor = window.google.maps.geometry.poly.containsLocation(
          { lat: item.latitude, lng: item.longtitude },
          circles
        );
        if (resultColor) {
          arr.push({
            ...item,
          });
        }
      });

      arr.forEach((item, index) => {
        const dist =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            { lat: item.latitude, lng: item.longtitude },
            center
          );
        arr[index].distance = dist;
      });

      arr.sort((a, b) => a.distance - b.distance);
      updateListing(arr);
    }
  }, [data]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lan: position.coords.longitude,
        });
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  const handleClickCurrentLoc = () => {
    if (currentLocation) {
      setMarkerLocation((currentData) => ({
        ...currentData,
        lat: currentLocation.lat,
        lng: currentLocation.lan,
      }));
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lan}&key=${process.env.REACT_APP_MAP_KEY}`
        )
        .then((res) => {
          setCurrentLoc(false);
          const data = res.data.results;
          const route = data.find((item) => item.types.includes("route"));
          // handleSelect(route?.formatted_address)
          setAddress("Current Location");
          geocodeByAddress(route?.formatted_address)
            .then((results) => {
              return getLatLng(results[0]);
            })
            .then((latLng) => getSelectedLocation(latLng))
            .catch((error) => console.error("Error", error));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const currentLocModalref = useRef();
  useEffect(() => {
    const checkIfClickOutside = (e) => {
      if (
        currentLoc &&
        currentLocModalref.current &&
        !currentLocModalref.current.contains(e.target)
      ) {
        setCurrentLoc(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
  }, [currentLoc]);
  const openCurrentLoc = (e) => {
    if (e.target.localName !== "img") {
      setCurrentLoc(true);
    }
  };

  function formatOptionLabel({ name }, { inputValue }) {
    return (
      <Highlighter
        searchWords={[inputValue]}
        textToHighlight={name}
        highlightClassName="text-red-500  border-red-500 bg-transparent"
      />
    );
  }

  let randomStr = "XXXXX XXXX";
  return (
    <div>
      {/* Above Mobile Devices*/}
      {!isMobile ? (
        <div className="hidden md:block jobSearchContainer relative">
          {/* map container start */}
          <div
            className={`relative w-full mr-0 mapContainer ${
              filter ? "hidden" : "block"
            } md:block`}
          >
            <div className="absolute flex items-center justify-between  h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
              <div className="relative flex items-center justify-start w-full md:items-center md:flex-nowrap">
                <button
                  onClick={() => {
                    setFilter(!filter);
                  }}
                >
                  <img
                    src={filter ? filterActive : filterIcon}
                    alt=""
                    width="100px"
                    className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                  />
                </button>
                <div
                  // style={{ width: "70%" }}
                  className="relative w-full lg:w-3/5 xl:w-3/4 mx-2 my-1 shadow-md search_input_box md:my-0 z-10"
                  onClick={openCurrentLoc}
                  ref={currentLocModalref}
                >
                  <AiOutlineSearch
                    className="absolute top-3 left-3"
                    color="gray"
                    size="1.3em"
                  />
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    onError={(err) => console.log(err)}
                    // searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            type: "text",
                            autoComplete: "off",
                            className:
                              "w-full p-3 h-11 pl-10 rounded outline-none",
                            placeholder: "Address",
                            // onBlur: handleBlur,
                            // onChange={(e) => updateValue(e)
                          })}
                        />
                        <div className="absolute w-full">
                          {loading && <div>Loading...</div>}
                          {currentLoc && !address && (
                            <h1
                              className="text-left px-4 py-3 bg-white cursor-pointer flex"
                              style={{ color: "#007FEE" }}
                              onClick={handleClickCurrentLoc}
                            >
                              <span>
                                <AiOutlineEnvironment
                                  className="relative top-0"
                                  color="#007FEE"
                                  size={28}
                                />
                              </span>
                              <h1 className="relative px-3 text-lg">
                                Current Location
                              </h1>
                            </h1>
                          )}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "bg-gray-700 p-2"
                              : "p-2";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            if (suggestion.terms.length > 1) {
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {locationClear && (
                    <AiOutlineClose
                      className="absolute top-4 right-12 cursor-pointer"
                      onClick={() => {
                        setAddress("");
                        setLocationClear(false);
                      }}
                    />
                  )}
                  <img
                    className="absolute w-9 h-9 rounded right-1 blue_box bg-main top-1 cursor-pointer"
                    src={locationIcon}
                    alt="location icon"
                    onClick={handleClickCurrentLoc}
                  />
                </div>
              </div>
              {/* <button type="submit" className="hidden w-24 md:block z-10">
              <img src={expandIcon} alt="" width="100px" />
            </button> */}
            </div>
            <div
              style={{ top: "4.5rem" }}
              className="form_error_message z-40 font-semibold block absolute left-24"
            >
              {addressError}
            </div>
            <div className="minHeight z-0">
              {/* <img src={map} alt="" width="100%" /> */}
              <Map
                zoomLevel={zoomlvl}
                lookingToHire={true}
                geolocationMarker={handleGeolocationMarker}
                // markerFlag={markerFlag}
                page="hires"
                updateListing={updateListing}
                filters={filterValues}
                refresh={refresh}
                getJobData={getJobData}
                // handleMapAddress={handleMapAddress}
                // geolocationError={handleGeolocationError}
                loading={loading}
                showRadius={showRadius}
              />
            </div>
            {/* map container below section button container start */}
            <div
              style={{ width: "94%" }}
              className="absolute flex-col hidden lg:flex bottom-7"
            >
              <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                {/* <button
                type="submit"
                className="p-2 bg-white rounded-md shadow-md"
              >
                <img src={plusSymbol} className="" alt="" width="auto" />
              </button> */}
              </div>
              {/* <div className="">
              <div className="flex justify-between px-4 left-5 bottom-5">
                <button
                  type="submit"
                  className="flex items-center justify-between btn btn-main"
                >
                  <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                  Search as map moves
                </button>
                <div className="flex justify-between space-x-3">
                  <button
                    type="submit"
                    className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                  >
                    Map
                    <div>
                      <MdKeyboardArrowDown
                        color="gray"
                        size="1.4em"
                      ></MdKeyboardArrowDown>
                    </div>
                  </button>
                   <button
                    type="submit"
                    className="p-2 bg-white rounded-md shadow-md"
                  >
                    <img src={minusSymbol} className="" alt="" width="auto" />
                  </button> 
                </div>
              </div>
            </div> */}
            </div>
            {/* map container below section button container end */}
          </div>
          {/* map container end */}

          {/* filter popup start*/}
          {!filterLoad &&
            (featureFilter() ? (
              <div
                className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold capitalize transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                  filter
                    ? "block overflow-x-hidden overflow-y-scroll custom-filter h-96"
                    : "hidden"
                }`}
              >
                <div className="relative">
                  <div className="relative w-[100%] h-10 block md:hidden">
                    <AiOutlineClose
                      className="absolute top-3 right-3 text-gray-700 cursor-pointer hover:text-black"
                      size="1.3em"
                      onClick={() => setFilter(false)}
                    />
                  </div>

                  <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                    <p className="font-semibold">Position</p>
                    <p
                      className="text-sm text-gray-700 cursor-pointer hover:text-black"
                      onClick={() => {
                        setShowRadius(false);
                        setFilterValues({
                          position: null,
                          min: 1,
                          max: 50,
                          jobType: [],
                          radius: 0,
                          salary: false,
                        });
                        setValues({
                          coordinates: coordinates.coordinates,
                        });
                      }}
                    >
                      Clear Filter
                    </p>
                  </div>
                  <Select
                    formatOptionLabel={formatOptionLabel}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={positionList?.data?.response_data.sort((a, b) => {
                      var x = a.name.toLowerCase();
                      var y = b.name.toLowerCase();
                      return x < y ? -1 : x > y ? 1 : 0;
                    })}
                    name="position"
                    id="position"
                    value={filterValues.position}
                    onChange={(e) => updatePositionSelectValue(e)}
                  />
                  <div className="mt-5 flex flex-row items-center gap-4">
                    <p className="font-semibold">salary</p>
                    <input
                      type="checkbox"
                      className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                      name="salary"
                      checked={filterValues.salary}
                      onChange={updateSalaryShow}
                    />
                  </div>

                  <div
                    className={
                      !filterValues.salary
                        ? "pointer-events-none opacity-40"
                        : ""
                    }
                  >
                    <div className="flex my-5 salaryContainer ">
                      <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                        <span className="">${filterValues.min}</span>
                      </p>
                      <p className="mr-2">-</p>
                      <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                        ${filterValues.max}
                      </p>
                      <p className="mr-2 lowercase">/hr</p>
                    </div>
                    <ReactSlider
                      step={1}
                      min={1}
                      max={250}
                      minDistance={1}
                      name="desired_pay"
                      id="desired_pay"
                      className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                      thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                      trackClassName="track-color"
                      withTracks={true}
                      defaultValue={[filterValues.min, filterValues.max]}
                      value={[filterValues.min, filterValues.max]}
                      onChange={(value) => {
                        updateSalary(value);
                      }}
                      onAfterChange={updateSalaryFilter}
                    />
                    {/* <div className="w-full line h-0.5 bg-gray-300 my-5"></div> */}
                    <div className="flex justify-between w-full my-5 md:my-2 xl:my-5">
                      <div className="flex my-5 salaryContainer ">
                        <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                          $
                          {calculateAnnualPay(
                            filterValues.min
                          )?.toLocaleString()}
                        </p>
                        <p className="mr-2">-</p>
                        <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                          $
                          {calculateAnnualPay(
                            filterValues.max
                          )?.toLocaleString()}
                        </p>
                        <p className="mr-2 lowercase">/yr</p>
                      </div>
                    </div>
                  </div>
                  <p className="my-5 md:my-3 xl:my-5 font-semibold">job type</p>
                  {jobType?.data?.response_data.map((item) => {
                    return (
                      <p
                        key={item.id}
                        className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                      >
                        {" "}
                        <span>
                          <input
                            type="checkbox"
                            className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                            name="Job Type"
                            checked={filterValues.jobType.includes(item.id)}
                            onChange={(e) => updateType(e, item.id)}
                          />
                        </span>
                        {item.name}
                      </p>
                    );
                  })}
                  <p className="mt-5 font-semibold">radius</p>
                  <div className="flex justify-start space-x-5 radiusContainer">
                    <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                      {filterValues.radius} miles
                    </p>
                    {/* <p className="my-5 text-sm text-gray-500">150 miles</p> */}
                  </div>
                  <ReactSlider
                    step={1}
                    min={1}
                    max={150}
                    minDistance={1}
                    name="desired_pay"
                    id="desired_pay"
                    className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                    thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                    trackClassName="bg-main"
                    // defaultValue={[
                    //   values.min_hourly_pay,
                    //   values.max_hourly_pay,
                    // ]}
                    value={filterValues.radius}
                    onChange={(value) => {
                      updateRadius(value);
                    }}
                    onAfterChange={updateRadiusFilter}
                  />
                  {/* <div className="w-full line h-0.5 bg-gray-300 mb-5"></div> */}
                  {/* <div
                className="py-2 mt-16 md:mt-8 xl:mt-16 font-semibold text-center rounded btn-main"
                onClick={() => {
                  setFilter(false);
                  getJobData(coordinates);
                }}
              >
                apply
              </div> */}
                </div>
              </div>
            ) : (
              <div
                className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                  filter ? "block" : "hidden"
                }`}
              >
                <p className="font-normal">
                  Make a FREE Profile For Advanced Filters
                </p>
                <button
                  onClick={setRedirect}
                  className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                >
                  Upgrade
                </button>
              </div>
            ))}
          {/* filter popup end */}

          <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
            <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
              <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>Hires Listing</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {/* <h2 className="text-xl font-semibold capitalize">
                    Hires Listing
                  </h2> */}
                    <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                      <p>showing {listingData.length} results</p>
                      <p>
                        sort by: <span className=" text-main">newest post</span>
                        <button className="px-1">
                          <MdKeyboardArrowDown
                            color="gray"
                            size="1em"
                          ></MdKeyboardArrowDown>
                        </button>
                      </p>
                    </div>
                    <div
                      style={{ height: "50vh" }}
                      className="grid grid-cols-1 gap-2 overflow-y-scroll capitalize md:grid-cols-2 custom-sidebar"
                    >
                      {listingData.map((item, index) => {
                        return (
                          <Link to={`/employees/${item.user_id}`}>
                            <div
                              key={index}
                              className="rounded shadow-md profileCards relative flex flex-col h-full"
                            >
                              <div className="flex p-2 justify-between">
                                <img
                                  src={
                                    item.image
                                      ? item.image
                                      : "/images/employee-default.png"
                                  }
                                  alt=""
                                  className={`w-20 h-20 ${
                                    !item.name && "blurimage"
                                  }`}
                                />
                                {item.is_premium == true && (
                                  <div>
                                    <img src={RohLogo} className="w-full h-7" />
                                  </div>
                                )}
                              </div>
                              <div className="p-2">
                                <p
                                  className={`my-2 font-semibold ${
                                    !item.name && "blurtext"
                                  }`}
                                >
                                  {item.name ? item.name : randomStr}
                                </p>
                                <p className="my-2 text-gray-500 text-xs">
                                  {item.post}
                                </p>
                                <span className="text-gray-700 text-sm">
                                  {item.position}
                                </span>
                                <p className="my-2 font-bold text-main mb-12">
                                  {item.desired_pay &&
                                    `$${item.desired_pay}/Hr`}
                                </p>
                                <div
                                  className={
                                    listingData?.length > 2
                                      ? "absolute bottom-0 my-2 w-11/12"
                                      : "my-2 w-11/12"
                                  }
                                >
                                  <button
                                    type="submit"
                                    className="w-full py-1 font-semibold rounded btn-main"
                                    onClick={() =>
                                      history.push(`/employees/${item.user_id}`)
                                    }
                                  >
                                    View Profile
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      ) : (
        /* Mobile Devices*/
        <div className="block md:hidden jobSearchContainer relative">
          {/* map container start */}
          <ParallaxProvider>
            <Parallax speed={-20}>
              <div className="fast">
                <div
                  className={`relative w-full mr-0 mapContainer ${
                    filter ? "hidden" : "block"
                  } md:block`}
                >
                  <div className="absolute flex items-center justify-between  h-20 bg-transparent top-2 search-sec w-full md:w-5/6 lg:w-4/6">
                    <div className="relative flex items-center justify-start w-full md:items-center md:flex-nowrap">
                      <button
                        onClick={() => {
                          setFilter(!filter);
                        }}
                      >
                        <img
                          src={filter ? filterActive : filterIcon}
                          alt=""
                          width="100px"
                          className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                        />
                      </button>
                      <div
                        // style={{ width: "70%" }}
                        className="relative w-full lg:w-3/5 xl:w-3/4 mx-2 my-1 shadow-md search_input_box md:my-0 z-10"
                        onClick={openCurrentLoc}
                        ref={currentLocModalref}
                      >
                        <AiOutlineSearch
                          className="absolute top-3 left-3"
                          color="gray"
                          size="1.3em"
                        />
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleChange}
                          onSelect={handleSelect}
                          onError={(err) => console.log(err)}
                          // searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  type: "text",
                                  autoComplete: "off",
                                  className:
                                    "w-full p-3 h-11 pl-10 rounded outline-none",
                                  placeholder: "Address",
                                  // onBlur: handleBlur,
                                  // onChange={(e) => updateValue(e)
                                })}
                              />
                              <div className="absolute w-full">
                                {loading && <div>Loading...</div>}
                                {currentLoc && !address && (
                                  <h1
                                    className="text-left px-4 py-3 bg-white cursor-pointer flex"
                                    style={{ color: "#007FEE" }}
                                    onClick={handleClickCurrentLoc}
                                  >
                                    <span>
                                      <AiOutlineEnvironment
                                        className="relative top-0"
                                        color="#007FEE"
                                        size={28}
                                      />
                                    </span>
                                    <h1 className="relative px-3 text-lg">
                                      Current Location
                                    </h1>
                                  </h1>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "bg-gray-700 p-2"
                                    : "p-2";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  if (suggestion.terms.length > 1) {
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <img
                          className="absolute w-9 h-9 rounded right-1 blue_box bg-main top-1 cursor-pointer"
                          src={locationIcon}
                          alt="location icon"
                          onClick={handleClickCurrentLoc}
                        />
                      </div>
                    </div>
                    {/* <button type="submit" className="hidden w-24 md:block z-10">
              <img src={expandIcon} alt="" width="100px" />
            </button> */}
                  </div>
                  <div
                    style={{ top: "4.5rem" }}
                    className="form_error_message z-40 font-semibold block absolute left-24"
                  >
                    {addressError}
                  </div>
                  <div className="minHeight z-0 h-60">
                    {/* <img src={map} alt="" width="100%" /> */}
                    <Map
                      zoomLevel={zoomlvl}
                      lookingToHire={true}
                      geolocationMarker={handleGeolocationMarker}
                      // markerFlag={markerFlag}
                      page="hires"
                      updateListing={updateListing}
                      filters={filterValues}
                      refresh={refresh}
                      getJobData={getJobData}
                      // handleMapAddress={handleMapAddress}
                      // geolocationError={handleGeolocationError}
                      loading={loading}
                      showRadius={showRadius}
                    />
                  </div>
                  {/* map container below section button container start */}
                  <div
                    style={{ width: "94%" }}
                    className="absolute flex-col hidden lg:flex bottom-7"
                  >
                    <div className="flex justify-end w-full px-4 my-2 top-4 left-5">
                      {/* <button
                type="submit"
                className="p-2 bg-white rounded-md shadow-md"
              >
                <img src={plusSymbol} className="" alt="" width="auto" />
              </button> */}
                    </div>
                    {/* <div className="">
              <div className="flex justify-between px-4 left-5 bottom-5">
                <button
                  type="submit"
                  className="flex items-center justify-between btn btn-main"
                >
                  <div className="p-2 mr-2 bg-transparent border border-white rounded-md"></div>
                  Search as map moves
                </button>
                <div className="flex justify-between space-x-3">
                  <button
                    type="submit"
                    className="flex items-center justify-between w-40 px-3 py-2 font-semibold bg-white rounded-md shadow-md"
                  >
                    Map
                    <div>
                      <MdKeyboardArrowDown
                        color="gray"
                        size="1.4em"
                      ></MdKeyboardArrowDown>
                    </div>
                  </button>
                   <button
                    type="submit"
                    className="p-2 bg-white rounded-md shadow-md"
                  >
                    <img src={minusSymbol} className="" alt="" width="auto" />
                  </button> 
                </div>
              </div>
            </div> */}
                  </div>
                  {/* map container below section button container end */}
                </div>
              </div>
            </Parallax>
            {/* map container end */}

            {/* filter popup start*/}
            {!filterLoad &&
              (featureFilter() ? (
                <div
                  className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold capitalize transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                    filter
                      ? "block overflow-x-hidden overflow-y-scroll custom-filter h-96"
                      : "hidden"
                  }`}
                >
                  <div className="relative">
                    <div className="relative w-[100%] h-10 block md:hidden">
                      <AiOutlineClose
                        className="absolute top-3 right-3 text-gray-700 cursor-pointer hover:text-black"
                        size="1.3em"
                        onClick={() => setFilter(false)}
                      />
                    </div>

                    <div className="my-5 md:my-2 xl:my-5 flex justify-between">
                      <p className="font-semibold">Position</p>
                      <p
                        className="text-sm text-gray-700 cursor-pointer hover:text-black"
                        onClick={() => {
                          setShowRadius(false);
                          setFilterValues({
                            position: null,
                            min: 1,
                            max: 50,
                            jobType: [],
                            radius: 0,
                            salary: false,
                          });
                          setValues({
                            coordinates: coordinates.coordinates,
                          });
                        }}
                      >
                        Clear Filter
                      </p>
                    </div>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={positionList?.data?.response_data.sort(
                        (a, b) => {
                          var x = a.name.toLowerCase();
                          var y = b.name.toLowerCase();
                          return x < y ? -1 : x > y ? 1 : 0;
                        }
                      )}
                      name="position"
                      id="position"
                      value={filterValues.position}
                      onChange={(e) => updatePositionSelectValue(e)}
                    />
                    <div className="mt-5 flex flex-row items-center gap-4">
                      <p className="font-semibold">salary</p>
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                        name="salary"
                        checked={filterValues.salary}
                        onChange={updateSalaryShow}
                      />
                    </div>

                    <div
                      className={
                        !filterValues.salary
                          ? "pointer-events-none opacity-40"
                          : ""
                      }
                    >
                      <div className="flex my-5 salaryContainer ">
                        <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                          <span className="">${filterValues.min}</span>
                        </p>
                        <p className="mr-2">-</p>
                        <p className="px-4 py-1 mr-2 w-16 text-gray-500 rounded bg-blue-50 text-center">
                          ${filterValues.max}
                        </p>
                        <p className="mr-2 lowercase">/hr</p>
                      </div>
                      <ReactSlider
                        step={1}
                        min={1}
                        max={250}
                        minDistance={1}
                        name="desired_pay"
                        id="desired_pay"
                        className="z-0 w-full h-2 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                        thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                        trackClassName="track-color"
                        withTracks={true}
                        defaultValue={[filterValues.min, filterValues.max]}
                        value={[filterValues.min, filterValues.max]}
                        onChange={(value) => {
                          updateSalary(value);
                        }}
                        onAfterChange={updateSalaryFilter}
                      />
                      {/* <div className="w-full line h-0.5 bg-gray-300 my-5"></div> */}
                      <div className="flex justify-between w-full my-5 md:my-2 xl:my-5">
                        <div className="flex my-5 salaryContainer ">
                          <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                            $
                            {calculateAnnualPay(
                              filterValues.min
                            )?.toLocaleString()}
                          </p>
                          <p className="mr-2">-</p>
                          <p className="px-4 py-1 mr-2 text-gray-500 rounded bg-blue-50">
                            $
                            {calculateAnnualPay(
                              filterValues.max
                            )?.toLocaleString()}
                          </p>
                          <p className="mr-2 lowercase">/yr</p>
                        </div>
                      </div>
                    </div>
                    <p className="my-5 md:my-3 xl:my-5 font-semibold">
                      job type
                    </p>
                    {jobType?.data?.response_data.map((item) => {
                      return (
                        <p
                          key={item.id}
                          className="my-5 md:my-4 xl:my-5 text-sm text-gray-500 flex flex-row items-center"
                        >
                          {" "}
                          <span>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded-xl checked:bg-blue-600 checked:border-transparent mr-1"
                              name="Job Type"
                              checked={filterValues.jobType.includes(item.id)}
                              onChange={(e) => updateType(e, item.id)}
                            />
                          </span>
                          {item.name}
                        </p>
                      );
                    })}
                    <p className="mt-5 font-semibold">radius</p>
                    <div className="flex justify-start space-x-5 radiusContainer">
                      <p className="my-5 md:my-2 xl:my-5 text-sm text-gray-500">
                        {filterValues.radius} miles
                      </p>
                      {/* <p className="my-5 text-sm text-gray-500">150 miles</p> */}
                    </div>
                    <ReactSlider
                      step={1}
                      min={1}
                      max={150}
                      minDistance={1}
                      name="desired_pay"
                      id="desired_pay"
                      className="z-0 w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                      thumbClassName="relative -bottom-1 w-5 h-5 cursor-grab bg-main rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px cursor-pointer"
                      trackClassName="bg-main"
                      // defaultValue={[
                      //   values.min_hourly_pay,
                      //   values.max_hourly_pay,
                      // ]}
                      value={filterValues.radius}
                      onChange={(value) => {
                        updateRadius(value);
                      }}
                      onAfterChange={updateRadiusFilter}
                    />
                    {/* <div className="w-full line h-0.5 bg-gray-300 mb-5"></div> */}
                    {/* <div
                className="py-2 mt-16 md:mt-8 xl:mt-16 font-semibold text-center rounded btn-main"
                onClick={() => {
                  setFilter(false);
                  getJobData(coordinates);
                }}
              >
                apply
              </div> */}
                  </div>
                </div>
              ) : (
                <div
                  className={`md:absolute top-0 lg:left-4 md:left-7 w-full md:w-72 md:top-24 p-4 px-5 font-semibold transition-all duration-300 bg-white rounded-lg shadow-lg filterPopupContainer  ${
                    filter ? "block" : "hidden"
                  }`}
                >
                  <div className="relative">
                    <AiOutlineClose
                      className="absolute top-1 right-1 text-gray-700 cursor-pointer hover:text-black"
                      size="1.3em"
                      onClick={() => setFilter(false)}
                    />
                  </div>
                  <p className="font-normal">
                    Make a FREE Profile For Advanced Filters
                  </p>
                  <button
                    onClick={setRedirect}
                    className="bg-red-500 rounded-md text-white px-10 py-3 block mt-3 text-center cursor-pointer"
                  >
                    Upgrade
                  </button>
                </div>
              ))}
            {/* filter popup end */}

            <Parallax speed={5}>
              <div className="fast">
                <div className="block rounded md:w-6/12 lg:w-2/5 xl:w-1/4 2xl:w-4/12 hiresListingContainer md:absolute md:top-24 md:right-2 lg:top-6 lg:right-20 z-0 bg-white search-page">
                  <div className="w-auto px-2 md:px-5 m-1 rounded hiresListing">
                    <Accordion allowZeroExpanded={true} preExpanded={["a"]}>
                      <AccordionItem uuid="a">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Hires Listing
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="block">
                            <button
                              onClick={() => {
                                setFilter(!filter);
                              }}
                            >
                              <img
                                src={filter ? filterActive : filterActive}
                                alt=""
                                width="100px"
                                className="relative w-20 md:w-24 right-2 md:right-0 z-10"
                              />
                            </button>
                          </div>
                          <div className="items-center justify-between my-2 text-gray-400 capitalize md:flex sortContainer">
                            <p>showing {listingData.length} results</p>
                            <p>
                              sort by:{" "}
                              <span className=" text-main">newest post</span>
                              <button className="px-1">
                                <MdKeyboardArrowDown
                                  color="gray"
                                  size="1em"
                                ></MdKeyboardArrowDown>
                              </button>
                            </p>
                          </div>
                          <div
                            style={{ height: "50vh" }}
                            className="grid grid-cols-1 gap-2 overflow-y-scroll capitalize md:grid-cols-2 custom-sidebar"
                          >
                            {listingData.map((item, index) => {
                              return (
                                <Link to={`/employees/${item.user_id}`}>
                                  <div
                                    key={index}
                                    className="rounded shadow-md profileCards relative flex flex-col h-full"
                                  >
                                    <div className="flex p-2 items-center">
                                      <div className="flex-none w-20">
                                        <img
                                          src={
                                            item.image
                                              ? item.image
                                              : "/images/employee-default.png"
                                          }
                                          alt=""
                                          className={`w-20 h-20 ${
                                            !item.name && "blurimage"
                                          }`}
                                        />
                                      </div>
                                      <div className="flex-auto pl-2">
                                        {item.is_premium == true && (
                                          <div>
                                            <img
                                              src={RohLogo}
                                              className="w-auto h-7 mx-auto"
                                            />
                                          </div>
                                        )}
                                        <p
                                          className={`my-2 font-semibold text-center ${
                                            !item.name && "blurtext"
                                          }`}
                                        >
                                          {item.name ? item.name : randomStr}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="p-2">
                                      <p className="my-2 text-gray-500 text-xs">
                                        {item.post}
                                      </p>
                                      {/* <div className="flex items-center">
                        <GoLocation color="gray" size="1em" />

                        <p className="mx-1 my-0 text-gray-500 ">location</p>
                      </div> */}
                                      <p className="my-2 font-bold text-main mb-12">
                                        {item.desired_pay &&
                                          `$${item.desired_pay}/Hr`}
                                      </p>
                                      <div
                                        className={
                                          listingData?.length > 2
                                            ? "absolute bottom-0 my-2 w-11/12"
                                            : "my-2 w-11/12"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          className="w-full py-1 font-semibold rounded btn-main"
                                          onClick={() =>
                                            history.push(
                                              `/employees/${item.user_id}`
                                            )
                                          }
                                        >
                                          View Profile
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>
      )}
    </div>
  );
};

export default HireSearch;
