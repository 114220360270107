import { useState, useEffect, useRef, useContext } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";

import usePatch from "../../hooks/usePatch";

import { UserContext } from "../../App";
import { MdDoneAll } from "react-icons/md";
import useDelete from "../../hooks/useDelete";

const PdfUploader = ({ resume, refreshResume }) => {
  const { userId } = useContext(UserContext);
  const [file_src, setFileSrc] = useState(null);
  const [file_error, setFileError] = useState(null);

  //to store selected file
  const [fileObj, setFile] = useState(null);
  const [file_form, setFileForm] = useState(null);

  const [uploadButton, enableUpload] = useState(false);
  const [doneUpload, setDoneUpload] = useState(false);
  const [deleteUrl, setDeleteUrl] = useState(null);

  const { data: deleteData } = useDelete(deleteUrl);

  //trigger click on hidden input field when
  //clicking on preview
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  // //for upload image
  // const uploadFile = (e) => {
  //     e.preventDefault();
  //     console.log(fileObj);
  //     const temp_form = new FormData();
  //     temp_form.append('resume', fileObj);
  //     setFileForm(temp_form);
  // }
  useEffect(() => {
    if (deleteData !== null) {
      refreshResume();
      setDoneUpload(false);
    }
  }, [deleteData]);

  useEffect(() => {
    if (deleteUrl) {
      setDeleteUrl(null);
    }
  }, [deleteUrl]);

  useEffect(() => {
    if (fileObj) {
      const temp_form = new FormData();
      temp_form.append("resume", fileObj);
      setFileForm(temp_form);
    }
  }, [fileObj]);

  //file selected event
  const handleChange = (e) => {
    setDoneUpload(false);
    if (!e.target.files[0].name?.match(/\.(pdf)$/)) {
      setFileError("Please select a PDF file");
      return;
    }
    if (e.target.files[0].size > 2e6) {
      setFileError("File should be less than 2 MB");
    } else {
      let allowedExtension = ["application/pdf"];
      let type = e.target.files[0].type;
      if (allowedExtension.indexOf(type) > -1) {
        setFileError("");
        setFileSrc(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        console.log(e.target.files[0]);
        e.target.value = null;
      } else {
        setFileError("Please select a PDF file");
      }
    }
  };

  //for reset file
  const resetFile = () => {
    setDoneUpload(false);
    setFile(null);
    setFileForm(null);
    enableUpload(false);
  };

  useEffect(() => {
    if (fileObj) {
      enableUpload(true);
    }
  }, [fileObj]);
  useEffect(() => {
    setFileSrc(resume);
  }, [resume]);

  const {
    data: file_data,
    error: file_errors,
    loading: loading_file,
  } = usePatch(`employees/${userId}`, file_form, "multipart/form-data");

  // for rerendering search component / Dashbord when upload is completed
  useEffect(() => {
    if (file_data) {
      refreshResume();
      resetFile();
      setFileSrc(file_data.data.resume);
      setDoneUpload(true);
    }
  }, [file_data]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-auto px-10 py-5 rounded-md shadow-lg">
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        onChange={handleChange}
      />
      <AiOutlinePaperClip
        onClick={handleClick}
        className="text-2xl font-semibold cursor-pointer text-main"
      />
      <p className="mb-1 font-semibold text-black">Attach your Resume</p>
      {file_src && (
        <embed
          type="application/pdf"
          className="w-full my-3"
          src={file_src + "#toolbar=0"}
          frameborder="0"
        ></embed>
        // controlsList="nodownload"
      )}
      <p className="pb-3 mb-1 text-sm text-gray-400">
        {!file_src && "You haven't uploaded your resume yet"}

        {file_src && (
          <>
            <a href={file_src} target="_blank" rel="noopener noreferrer">
              Download
            </a>
            <a
              onClick={() => setDeleteUrl(`employees/${userId}/resume`)}
              className="cursor-pointer ml-3"
            >
              Delete
            </a>
          </>
        )}
      </p>
      {file_error && (
        <div className="my-2 text-left text-red-500"> {file_error} </div>
      )}

      {!file_src && (
        <button onClick={handleClick} className="py-1 btn-sm btn-main">
          Choose
        </button>
      )}
      {doneUpload && <MdDoneAll className="text-xl text-green-600" />}
      {uploadButton && (
        <>
          <button className="py-1 btn-sm btn-main">
            {loading_file ? "Uploading.." : "Upload"}
          </button>
        </>
      )}
    </div>
  );
};

export default PdfUploader;
