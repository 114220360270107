import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import RohLogo from "../../images/ROH-logo.png";
import { IoMdGlobe } from "react-icons/io";
import { GoLocation } from "react-icons/go";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import defaultlogo from "../../images/JobDetails/hospital-default.jpg";
import ReactPaginate from "react-paginate";

const EmployerDetails = () => {
  const { emp_id } = useParams();
  const user_id = emp_id;
  const history = useHistory();
  const empDetailUrl = `employers/${user_id}/public`;
  const [empJobsUrl, setempJobsUrl] = useState(`employers/${emp_id}/jobs`);
  const [employerDetails, setEmployerDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const { data: employerData, error: empDataErr } = useGetData(empDetailUrl);
  const { data: employerJobs } = useGetData(empJobsUrl);

  useEffect(() => {
    if (employerData?.data) {
      setEmployerDetails(employerData?.data);
    }
  }, [employerData]);

  //Pagination for the job listing starts here
  const usersPerPage = 8;
  const myPostPageCount = Math.ceil(employerJobs?.count / usersPerPage);
  const myPostHandlePageData = (selected) => {
    setempJobsUrl(`employers/${user_id}/jobs?page=${selected}&page_size=8`);
  };

  const myPostChangePage = ({ selected }) => {
    setPageNumber(selected);
    myPostHandlePageData(selected + 1);
  };

  useEffect(() => {
    myPostHandlePageData(1);
  }, []);
  //Pagination for the job listing ends here

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [employerJobs]);

  useEffect(() => {
    if (employerJobs && employerData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [employerJobs, employerData]);

  //Employer id doesn't exit, set 404 redirect
  useEffect(() => {
    if (empDataErr) {
      window.location.href = "/404";
    }
  }, [empDataErr]);

  return (
    <div className="minHeight mb-5 container-wrapper">
      <div className="my-5 custom_shadow">
        <div className="flex flex-col justify-between py-6 space-y-6 md:px-5 md:space-y-0 md:flex-row">
          {!loading ? (
            <div className="block space-x-5 font-semibold text-gray-500 md:flex md:whitespace-pre md:justify-center md:text-left md:items-center details font_small_light">
              <img
                src={
                  employerDetails?.image
                    ? employerDetails?.image
                    : "/images/employee-default.png"
                }
                alt="Profile Image"
                className="block ml-5 md:ml-0"
                width="150px"
              />

              <div className="space-y-1 lg:mr-5">
                <h2 className="text-black font-xl-semibold flex items-center mt-3 md:mt-0">
                  {employerDetails?.company_name}

                  {employerDetails?.premium == true && (
                    <div className="block ml-2">
                      <img src={RohLogo} className="w-5 h-full" />
                    </div>
                  )}
                </h2>

                <div className="flex flex-col justify-start gap-2">
                  <p className="flex justify-start md:items-center">
                    <span style={{ marginTop: "2px" }} className="self-start">
                      <GoLocation
                        color="007fee"
                        className="mr-1"
                        size="1.2em"
                      />
                    </span>
                    <span className="whitespace-pre-wrap">
                      {employerDetails?.address}
                    </span>
                  </p>
                  {/* <p
                  style={{ marginLeft: "0" }}
                  className="flex items-center m-0"
                >
                  <span>
                    <HiOutlinePhone
                      color="007fee"
                      className="mr-1"
                      size="1.2em"
                    />
                  </span>
                  {employerDetails?.phone}
                </p>
                <p
                  style={{ marginLeft: "0" }}
                  className="flex items-center m-0"
                >
                  <span>
                    <HiOutlineMail
                      color="007fee"
                      className="mr-1"
                      size="1.2em"
                    />
                  </span>
                  {employerDetails?.email}
                </p> */}
                  <p
                    style={{ marginLeft: "0" }}
                    className="flex items-center m-0"
                  >
                    <span>
                      <IoMdGlobe color="007fee" className="mr-1" size="1.2em" />
                    </span>
                    {employerDetails?.website}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
          )}
        </div>
      </div>

      <div class="block">
        <div class="w-full px-5 py-3 jobDescription custom_shadow font-base-light">
          <h2 class="my-4 font-xl-semibold">Available Jobs</h2>
          {!loading ? (
            employerJobs?.results?.length > 0 ? (
              <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-3 lg:grid-cols-4">
                {employerJobs?.results?.map((single_emp_item, index) => {
                  let salarySubType = Object.values(
                    single_emp_item.salary_subtype
                      ? single_emp_item.salary_subtype
                      : {}
                  );
                  let salaryType = Object.values(
                    single_emp_item.salary_type
                      ? single_emp_item.salary_type
                      : {}
                  );

                  return (
                    <div
                      className="rounded shadow-md profileCards flex flex-col justify-between"
                      key={single_emp_item.id}
                    >
                      <div className="relative">
                        <Link to={`/job-details/${single_emp_item.id}`}>
                          {!single_emp_item.image &&
                          !single_emp_item.category_unverified_image &&
                          !single_emp_item.category_verified_image ? (
                            <img
                              className="object-cover w-full h-auto"
                              src={defaultlogo}
                              alt="Candidate Photo"
                            />
                          ) : (
                            <img
                              className="object-cover w-full h-auto"
                              src={
                                single_emp_item.image
                                  ? single_emp_item.image
                                  : single_emp_item.emp_id == null
                                  ? single_emp_item.category_unverified_image
                                  : single_emp_item.category_verified_image
                              }
                              alt="Candidate Photo"
                            />
                          )}
                        </Link>

                        <Link to={`/job-details/${single_emp_item.id}`}>
                          <div className="p-2">
                            <p className="mt-2 mb-3 font-semibold h-auto">
                              {single_emp_item.job_title
                                ? single_emp_item.job_title
                                : single_emp_item.position_label}
                            </p>
                          </div>
                        </Link>
                      </div>

                      <div className="p-2">
                        <Link to={`/job-details/${single_emp_item.id}`}>
                          <div className="flex items-top">
                            <div className="mt-1">
                              <GoLocation color="gray" size="1em" />
                            </div>
                            <p className="mx-1 my-0 text-gray-500">
                              {single_emp_item?.location_details?.address}
                            </p>
                          </div>
                        </Link>
                        <Link to={`/job-details/${single_emp_item.id}`}>
                          {salaryType?.[0] === "Fixed" && (
                            <p className="my-2 font-bold text-main text-sm">
                              {single_emp_item?.max_salary
                                ? "$" +
                                  single_emp_item.max_salary.toLocaleString() +
                                  "  "
                                : ""}
                              {single_emp_item?.max_salary ? "/" : ""}
                              {salarySubType?.[0]}
                            </p>
                          )}

                          {salaryType?.[0] !== "Fixed" && (
                            <p className="font-bold text-main">
                              {(single_emp_item?.min_salary ||
                                  single_emp_item?.max_salary) ? (
                                  <>
                                  {single_emp_item?.min_salary
                                      ? "$" + single_emp_item?.min_salary +"/Hr"
                                      : ""}
                                      {(single_emp_item?.min_salary && single_emp_item?.max_salary) && "-"}
                                    {single_emp_item?.max_salary
                                      ? "$" + single_emp_item?.max_salary +"/Hr"
                                      : ""}
                                  </>
                                ):(single_emp_item?.min_yearly_pay ||
                                  single_emp_item?.max_yearly_pay)?
                                  <>
                                  {single_emp_item?.min_yearly_pay
                                      ? "$" + single_emp_item?.min_yearly_pay +"/Yr"
                                      : ""}
                                      {(single_emp_item?.min_yearly_pay && single_emp_item?.max_yearly_pay) && "-"}
                                    {single_emp_item?.max_yearly_pay
                                      ? "$" + single_emp_item?.max_yearly_pay +"/Yr"
                                      : ""}
                                  </>:""
                                  }
                            </p>)}
                          {/* {salaryType?.[0] !== "Fixed" && (
                            <p className="my-2 font-bold text-main text-sm">
                              {single_emp_item?.max_salary &&
                              single_emp_item?.min_salary
                                ? `$${single_emp_item?.min_salary?.toLocaleString()}/Hr - $${single_emp_item?.max_salary?.toLocaleString()}/Hr`
                                : single_emp_item?.max_yearly_pay &&
                                  single_emp_item?.min_yearly_pay
                                ? `$${single_emp_item?.min_yearly_pay?.toLocaleString()}/Yr - $${single_emp_item?.max_yearly_pay?.toLocaleString()}/Yr`
                                : ""}
                            </p>
                          )} */}
                        </Link>
                        <div className="block">
                          <button
                            type="submit"
                            onClick={() => {
                              history.push(
                                `/job-details/${single_emp_item.id}`
                              );
                            }}
                            className="w-full py-1 my-2 font-semibold rounded btn-main"
                          >
                            View Job
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <h5 className="p-3 text-gray-600 font-semibold">
                You don't have any job posts yet!!
              </h5>
            )
          ) : (
            <h5 className="p-3 text-gray-600 font-semibold">Loading...</h5>
          )}

          {employerJobs?.count > 0 && (
            <div className="mt-12">
              <ReactPaginate
                pageCount={myPostPageCount}
                onPageChange={myPostChangePage}
                containerClassName={
                  "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                }
                previousLabel="<"
                previousLinkClassName={
                  "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                }
                nextLabel=">"
                nextLinkClassName={
                  "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                }
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployerDetails;
