import React from "react";
import { useState, useEffect, useCallback } from "react";
import { GoLocation } from "react-icons/go";
import useGetData from "../../../hooks/useGetData";
import ReactPaginate from "react-paginate";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineEllipsis } from "react-icons/ai";
import DeletePost from "../../modals/Employer/DeletePost";
import UnHirePost from "../../modals/Employer/UnHirePost";
import UnSavePost from "../../modals/Employer/UnSavePost";
import ReportJob from "../../modals/Employer/ReportJob";
import defaultlogo from "../../../images/JobDetails/hospital-default.jpg";
import { Link } from "react-router-dom";
import RemovePost from "../../modals/Employer/RemovePost";
import ClaimJobPost from "../../modals/Employer/ClaimJobPost";
import defaultBuilding from "../../../images/JobDetails/hospital-default.jpg";
import RohLogo from "../../../images/ROH-logo.png";

const EmployerJobList = () => {
  const user_id = localStorage.getItem("user_id");
  const emp_id = localStorage.getItem("user_id");
  const [pageNumber, setPageNumber] = useState(0);
  const [show, setShow] = useState("myposts");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [updateJobID, setUpdateJobID] = useState(0);
  const [updateEmployeeId, setUpdateEmployeeId] = useState(0);

  const [myJobsModal, setMyJobsModal] = useState(false);
  const [myJobsDropdown, setMyJobsDropdown] = useState(0);
  const [isDeleted, setisDeleted] = useState(false);

  const [hiredModal, setHiredModal] = useState(false);
  const [hiredDropdown, setHiredDropdown] = useState(0);

  const [savedModal, setSavedModal] = useState(false);
  const [savedDropdown, setSavedDropdown] = useState(0);

  const [suggestionModal, setSuggestionModal] = useState(false);
  const [suggestionDropdown, setSuggestionDropdown] = useState(0);

  const [reportModal, setReportModal] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [hireList, setHireList] = useState([]);
  const [saveList, setSaveList] = useState([]);
  const [suggestedList, setSuggestedList] = useState([]);

  const [claimModal, setClaimModal] = useState(false);
  const [singleJobDetail, setSingleJobDetail] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);

  /* My job list */
  const [myposturl, setMyPostUrl] = useState(`employers/${user_id}/jobs`);
  const { data: employersData } = useGetData(myposturl);
  const { data: reportList } = useGetData(`reports/jobpost`);
  // console.log("employersData", employersData);
  // console.log("hireList....", hireList.results);

  const usersPerPage = 6;
  const myPostPageCount = Math.ceil(employersData?.count / usersPerPage);
  const myPostHandlePageData = (selected) => {
    setMyPostUrl(`employers/${user_id}/jobs?page=${selected}`);
  };

  const myPostChangePage = ({ selected }) => {
    setPageNumber(selected);
    myPostHandlePageData(selected + 1);
  };
  useEffect(() => {
    myPostHandlePageData(1);
    hiredHandlePageData(1);
    savedHandlePageData(1);
    suggestedHandlePageData(1);
  }, [show]);

  /* Hired job list */
  const [hiredurl, setHiredUrl] = useState(
    `employers/${user_id}/hired-candidates`
  );
  const { data: hiredData } = useGetData(hiredurl);

  const hiredPageCount = Math.ceil(hiredData?.count / usersPerPage);
  console.log(hiredData?.count);
  const hiredHandlePageData = (selected) => {
    setHiredUrl(`employers/${user_id}/hired-candidates?page=${selected}`);
  };
  const hiredChangePage = ({ selected }) => {
    setPageNumber(selected);
    hiredHandlePageData(selected + 1);
  };

  /* Saved job list */
  const [savedurl, setSavedUrl] = useState(
    `employers/${user_id}/saved-employees`
  );
  const { data: savedData } = useGetData(savedurl);

  const savedPageCount = Math.ceil(savedData?.count / usersPerPage);
  const savedHandlePageData = (selected) => {
    setSavedUrl(`employers/${user_id}/saved-employees?page=${selected}`);
  };
  const savedChangePage = ({ selected }) => {
    setPageNumber(selected);
    savedHandlePageData(selected + 1);
  };

  /* Suggested Job list */
  const [suggestedurl, setSuggestedUrl] = useState(
    `employers/${emp_id}/claim-jobs/suggestions`
  );
  const { data: suggestedData } = useGetData(suggestedurl);

  const suggestedPageCount = Math.ceil(suggestedData?.count / usersPerPage);
  const suggestedHandlePageData = (selected) => {
    setSuggestedUrl(
      `employers/${emp_id}/claim-jobs/suggestions?page=${selected}`
    );
  };
  const suggestedChangePage = ({ selected }) => {
    setPageNumber(selected);
    suggestedHandlePageData(selected + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [employersData, hiredData, savedData, suggestedData]);

  const jobListRefresh = (id) => {
    const jobs = jobList?.results;
    const filteredjobs = jobs.filter((item) => item.id !== id);
    setJobList((prev) => ({ ...prev, results: filteredjobs }));
  };

  const hiredRefresh = (id) => {
    const hires = hireList?.results;
    const filteredHires = hires.filter((item) => item.employee !== id);
    setHireList((prev) => ({ ...prev, results: filteredHires }));
  };

  const savedRefresh = (id) => {
    const saved = saveList?.results;
    const filteredSave = saved.filter((item) => item.employee !== id);
    setSaveList((prev) => ({ ...prev, results: filteredSave }));
  };

  const suggestionRefresh = (id) => {
    const suggested = suggestedList?.results;
    const filteredSuggestion = suggested.filter((item) => item.id !== id);
    setSuggestedList((prev) => ({ ...prev, results: filteredSuggestion }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (employersData) {
      setJobList(employersData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [employersData]);

  useEffect(() => {
    if (hiredData) {
      setHireList(hiredData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [hiredData]);

  useEffect(() => {
    if (savedData) {
      setSaveList(savedData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [savedData]);

  useEffect(() => {
    if (suggestedData) {
      setSuggestedList(suggestedData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [suggestedData]);

  const deleteClick = (id) => {
    if (id !== myJobsDropdown) {
      setMyJobsDropdown(id);
      setUpdateJobID(id);
    } else {
      setMyJobsDropdown(null);
    }
  };

  const unHireClick = (employee) => {
    if (employee !== hiredDropdown) {
      setHiredDropdown(employee);
      setUpdateJobID(employee);
      setUpdateEmployeeId(employee);
    } else {
      setHiredDropdown(null);
    }
  };

  const unSaveClick = (employee) => {
    if (employee !== savedDropdown) {
      setSavedDropdown(employee);
      setUpdateJobID(employee);
      setUpdateEmployeeId(employee);
    } else {
      setSavedDropdown(null);
    }
  };

  const removeClick = (id) => {
    if (id !== suggestionDropdown) {
      setSuggestionDropdown(id);
      // setUpdateJobID(employee);
      // setUpdateEmployeeId(employee);
    } else {
      setSuggestionDropdown(null);
    }
  };
  /* Rerender the API when the action occured */

  // useEffect(() => {
  //   setUpdateJobList(`employers/${user_id}/jobs`);
  //   console.log("Triggered....");
  // }, [setUpdateJobList])

  // const customReload = (e) => {
  //   // setUpdateJobList(`employers/${user_id}/jobs`);
  //   // console.log("Triggered....");
  //   // setMyPostUrl(myposturl.filter(item => item.id !== id));
  // };

  /* Close the dropdown on outside click */
  function popupHide() {
    setMyJobsDropdown(null);
    setHiredDropdown(null);
    setSavedDropdown(null);
    setSuggestionDropdown(null);
  }
  useEffect(() => {
    if (
      myJobsDropdown ||
      hiredDropdown ||
      savedDropdown ||
      suggestionDropdown
    ) {
      window.addEventListener("click", popupHide);

      // unmound the eventlistner
      return () => {
        window.removeEventListener("click", popupHide);
      };
    }
  }, [myJobsDropdown, hiredDropdown, savedDropdown, suggestionDropdown]);

  return (
    <div className="p-5 bg-white rounded-lg shadow-lg">
      <h2 class="font-semibold mb-12 text-2xl">Applications</h2>
      <div className="flex items-center justify-items-start">
        <div
          className={`cursor-pointer px-2 py-1 sm:px-4 lg:px-6 sm:py-3 font-semibold target-ph rounded-t-md text-xs md:text-base ${
            show == "myposts" ? "bg-main text-white" : "bg-gray-200"
          }`}
          onClick={() => setShow("myposts")}
        >
          My Posts
        </div>
        <div
          className={`cursor-pointer px-2 py-1 sm:px-4 lg:px-6 sm:py-3 font-semibold target-ph rounded-t-md ml-1 md:ml-5 text-xs md:text-base ${
            show == "hired" ? "bg-main text-white" : "bg-gray-200"
          }`}
          onClick={() => setShow("hired")}
        >
          Hired
        </div>
        <div
          className={`cursor-pointer px-2 py-1 sm:px-4 lg:px-6 sm:py-3 font-semibold target-ph rounded-t-md ml-1 md:ml-5 text-xs md:text-base ${
            show == "saved" ? "bg-main text-white" : "bg-gray-200"
          }`}
          onClick={() => setShow("saved")}
        >
          Saved
        </div>
        <div
          className={`cursor-pointer px-2 py-1 sm:px-4 lg:px-6 sm:py-3 font-semibold target-ph rounded-t-md ml-1 md:ml-5 text-xs md:text-base ${
            show == "suggestedJobs" ? "bg-main text-white" : "bg-gray-200"
          }`}
          onClick={() => setShow("suggestedJobs")}
        >
          Suggested Jobs
        </div>
      </div>
      <div className="flex flex-col mb-10 md:flex-row md:space-x-3 lg:space-x-5">
        <div className="block w-full mb-6 md:mb-0 ">
          <div className="w-auto p-5 rounded-md custom_shadow">
            {show === "myposts" && (
              <>
                {!loading ? (
                  jobList?.results?.length > 0 ? (
                    <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                      {jobList?.results?.map((single_emp_item, index) => {
                        let salarySubType = Object.keys(
                          single_emp_item.salary_subtype
                        ).map((key) => {
                          return single_emp_item.salary_subtype[key];
                        });
                        let salaryType = Object.values(
                          single_emp_item.salary_type
                        );
                        return (
                          <div
                            className="rounded shadow-md profileCards flex flex-col justify-between"
                            key={single_emp_item.id}
                          >
                            <div className="relative">
                              <Link to={`/job-details/${single_emp_item.id}`}>
                                {!single_emp_item.image &&
                                !single_emp_item.category_unverified_image &&
                                !single_emp_item.category_verified_image ? (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={defaultBuilding}
                                    alt={single_emp_item.id}
                                    title={single_emp_item.id}
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={
                                      single_emp_item.image
                                        ? single_emp_item.image
                                        : single_emp_item.emp_id == null
                                        ? single_emp_item.category_unverified_image
                                        : single_emp_item.category_verified_image
                                    }
                                    alt={single_emp_item.id}
                                    title={single_emp_item.id}
                                    loading="lazy"
                                  />
                                )}
                              </Link>

                              <Link to={`/job-details/${single_emp_item.id}`}>
                                <div className="p-2">
                                  <p className="mt-2 mb-3 font-semibold h-auto">
                                    {single_emp_item.job_title
                                      ? single_emp_item.job_title
                                      : single_emp_item.position_label}
                                  </p>
                                </div>
                              </Link>

                              {/* action buttons */}
                              <div className="absolute top-0 right-1">
                                <DeletePost
                                  myJobsModal={myJobsModal}
                                  setMyJobsModal={setMyJobsModal}
                                  singleJobId={updateJobID}
                                  jobListRefresh={jobListRefresh}
                                  // customReload={customReload}
                                  // updateJobList={updateJobList}
                                />

                                <div>
                                  <button
                                    type="button"
                                    className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                    id="menu-button"
                                    onClick={() =>
                                      deleteClick(single_emp_item.id)
                                    }
                                  >
                                    <AiOutlineEllipsis
                                      className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                      size="2em"
                                    />
                                  </button>
                                </div>

                                <div
                                  className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                    myJobsDropdown === single_emp_item.id
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <div className="divide-y rounded-md shadow-lg">
                                    <button
                                      onClick={() => {
                                        history.push(
                                          `/profile/job-edit/${single_emp_item.id}`
                                        );
                                        setMyJobsDropdown(null);
                                      }}
                                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                                    >
                                      Edit Post
                                    </button>
                                    <button
                                      onClick={() => {
                                        setMyJobsModal(true);
                                        setMyJobsDropdown(null);
                                      }}
                                      className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                                    >
                                      Delete Post
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="p-2">
                              <Link to={`/job-details/${single_emp_item.id}`}>
                                <div className="flex items-top">
                                  <div className="mt-1">
                                    <GoLocation color="gray" size="1em" />
                                  </div>
                                  <p className="mx-1 my-0 text-gray-500">
                                    {single_emp_item?.location_details?.address}
                                  </p>
                                </div>
                              </Link>
                              <Link to={`/job-details/${single_emp_item.id}`}>
                                {salaryType[0] === "Fixed" && (
                                  <p className="my-2 font-bold text-main">
                                    {single_emp_item.max_salary
                                      ? "$" +
                                        single_emp_item.max_salary.toLocaleString()
                                      : ""}
                                    {single_emp_item.max_salary ? " /" : ""}
                                    {salarySubType[0]}
                                  </p>
                                )}
                                {salaryType[0] !== "Fixed" && (
                                  <p className="my-2 font-bold text-main">
                                    {single_emp_item.min_salary
                                      ? "$" +
                                        single_emp_item.min_salary.toLocaleString() +
                                        " /Hr"
                                      : ""}
                                    {single_emp_item.min_salary &&
                                    single_emp_item.max_salary
                                      ? " - "
                                      : ""}
                                    {single_emp_item.max_salary
                                      ? "$" +
                                        single_emp_item.max_salary.toLocaleString() +
                                        " /Hr"
                                      : ""}
                                  </p>
                                )}
                              </Link>
                              <div className="flex flex-col xl:flex-row xl:space-x-4">
                                <button
                                  type="submit"
                                  onClick={() => {
                                    history.push(
                                      `/job-details/${single_emp_item.id}`
                                    );
                                  }}
                                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                                >
                                  View Job
                                </button>

                                <button
                                  type="submit"
                                  onClick={() => {
                                    history.push(
                                      `/applications/${single_emp_item.id}`
                                    );
                                  }}
                                  className="w-full py-1 my-2 font-semibold rounded btn-sub"
                                >
                                  Candidates (
                                  {single_emp_item?.job_applications_count})
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <h5 className="p-3 text-gray-600 font-semibold">
                      You don't have any job posts yet!!
                    </h5>
                  )
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    Loading...
                  </h5>
                )}

                {jobList.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={myPostPageCount}
                      onPageChange={myPostChangePage}
                      containerClassName={
                        "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                      }
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}

            {show === "hired" && (
              <>
                {hireList?.results?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                    {hireList.results.map((single_hired_item, index) => (
                      <div
                        className="rounded shadow-md profileCards flex flex-col justify-between"
                        key={index}
                      >
                        <div className="relative">
                          <Link
                            to={`/employees/${single_hired_item.employee}?job_id=${single_hired_item?.job_id}`}
                          >
                            <img
                              className="object-cover w-full h-auto"
                              src={
                                !single_hired_item.image
                                  ? "/images/employee-default.png"
                                  : single_hired_item.image
                              }
                              alt={single_hired_item?.job_id}
                              title={single_hired_item?.job_id}
                              loading="lazy"
                            />
                          </Link>

                          <Link
                            to={`/employees/${single_hired_item.employee}?job_id=${single_hired_item?.job_id}`}
                          >
                            <div className="p-2">
                              <div className="flex space-x-4">
                                <p className="mt-2 mb-3 font-semibold h-auto">
                                  {single_hired_item.employee_name}{" "}
                                  {single_hired_item.employee_lastname}
                                </p>
                                {single_hired_item.is_owner_premium == true && (
                                    <img src={RohLogo} className="w-5 h-full" alt="ROH premium logo" title="ROH premium logo" loading="lazy" />
                                  )}
                              </div>
                            </div>
                          </Link>

                          {/* action buttons */}
                          <div className="absolute top-0 right-1">
                            <UnHirePost
                              hiredModal={hiredModal}
                              setHiredModal={setHiredModal}
                              singleEmployeeId={updateEmployeeId}
                              listRefresh={hiredRefresh}
                            />
                            <ReportJob
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              singleEmployeeId={updateEmployeeId}
                              reportList={reportList}
                            />

                            <div>
                              <button
                                type="button"
                                className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                id="menu-button"
                                onClick={() => {
                                  unHireClick(single_hired_item.employee);
                                }}
                              >
                                <AiOutlineEllipsis
                                  className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                  size="2em"
                                />
                              </button>
                            </div>

                            <div
                              className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                hiredDropdown === single_hired_item.employee
                                  ? "block"
                                  : "hidden"
                              }`}
                            >
                              <div className="divide-y rounded-md shadow-lg">
                                <button
                                  onClick={() => {
                                    setHiredDropdown(false);
                                    setHiredModal(true);
                                  }}
                                  className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                                >
                                  Remove Profile
                                </button>
                                <button
                                  onClick={() => {
                                    setReportModal(true);
                                    setHiredDropdown(false);
                                  }}
                                  className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                                >
                                  Report
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Link
                          to={`/employees/${single_hired_item.employee}?job_id=${single_hired_item?.job_id}`}
                        >
                          <div className="p-2">
                            <p className="text-gray-500 mb-2">
                              {single_hired_item.position}
                            </p>

                            <p className="my-2 font-bold text-main">
                              {single_hired_item?.desired_pay == null
                                ? `$0 /hr`
                                : `$${parseInt(
                                    single_hired_item?.desired_pay?.split(
                                      "."
                                    )[0]
                                  ).toLocaleString()} /hr`}
                            </p>

                            <p className="text-gray-700 font-medium mb-2">
                              <span className="font-bold normal-case">
                                Hired as :
                              </span>{" "}
                              {single_hired_item?.hired_position}
                            </p>
                            <div className="">
                              <button
                                type="submit"
                                // onClick={(e) => {
                                //   e.stopPropagation()
                                //   history.push(
                                //     `/employees/${single_hired_item.employee}?job_id=${single_hired_item?.job_id}`
                                //   );
                                // }}
                                className="w-full py-1 my-2 font-semibold rounded btn-main"
                              >
                                View Profile
                              </button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    You don't have any hired profiles yet!!
                  </h5>
                )}

                {hireList?.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={hiredPageCount}
                      onPageChange={hiredChangePage}
                      containerClassName={
                        "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                      }
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}

            {show === "saved" && (
              <>
                {saveList?.results?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                    {saveList.results.map((single_saved_item, index) => (
                      <div
                        className="rounded shadow-md profileCards flex flex-col justify-between"
                        key={index}
                      >
                        <div className="relative">
                          <Link to={`/employees/${single_saved_item.employee}`}>
                            <img
                              className="object-cover w-full h-auto"
                              src={
                                !single_saved_item.image
                                  ? "/images/employee-default.png"
                                  : single_saved_item.image
                              }
                              alt={single_saved_item.employee}
                              title={single_saved_item.employee}
                              loading="lazy"
                            />
                          </Link>

                          <Link to={`/employees/${single_saved_item.employee}`}>
                            <div className="p-2">
                              <div className="flex space-x-4">
                              <p className="mt-2 mb-3 font-semibold h-auto">
                                {single_saved_item.employee_name}{" "}
                                {single_saved_item.employee_lastname}
                              </p>
                                {single_saved_item.is_owner_premium == true && (
                                    <img src={RohLogo} className="w-5 h-full" alt="ROH premium logo" title="ROH premium logo" loading="lazy" />
                                  )}
                              </div>
                            </div>
                          </Link>

                          {/* action buttons */}
                          <div className="absolute top-0 right-1">
                            <UnSavePost
                              savedModal={savedModal}
                              setSavedModal={setSavedModal}
                              singleJobId={updateJobID}
                              listRefresh={savedRefresh}
                            />
                            <ReportJob
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              singleEmployeeId={updateEmployeeId}
                              reportList={reportList}
                            />
                            <div>
                              <button
                                type="button"
                                className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                id="menu-button"
                                onClick={() => {
                                  unSaveClick(single_saved_item.employee);
                                }}
                              >
                                <AiOutlineEllipsis
                                  className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                  size="2em"
                                />
                              </button>
                            </div>

                            <div
                              className={`absolute right-0 w-40 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                savedDropdown === single_saved_item.employee
                                  ? "block"
                                  : "hidden"
                              }`}
                            >
                              <div className="divide-y rounded-md shadow-lg">
                                <button
                                  onClick={() => {
                                    setSavedDropdown(false);
                                    setSavedModal(true);
                                  }}
                                  className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-t-md"
                                >
                                  Unsave Candidate
                                </button>
                                <button
                                  onClick={() => {
                                    setReportModal(true);
                                    setSavedDropdown(null);
                                  }}
                                  className="px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-b-md"
                                >
                                  Report
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Link to={`/employees/${single_saved_item.employee}`}>
                          <div className="p-2">
                            <p className="text-gray-500 mb-2">
                              {single_saved_item.position}
                            </p>
                            <p className="my-2 font-bold text-main">
                              {single_saved_item?.desired_pay == null
                                ? `$0 /hr`
                                : `$${parseInt(
                                    single_saved_item.desired_pay.split(".")[0]
                                  ).toLocaleString()} /hr`}
                            </p>
                            <div className="">
                              <button
                                type="submit"
                                onClick={() => {
                                  history.push(
                                    `/employees/${single_saved_item.employee}`
                                  );
                                }}
                                className="w-full py-1 my-2 font-semibold rounded btn-main"
                              >
                                View Profile
                              </button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    You don't have any saved profiles yet!!
                  </h5>
                )}

                {saveList?.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={savedPageCount}
                      onPageChange={savedChangePage}
                      containerClassName={
                        "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                      }
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}

            {show === "suggestedJobs" && (
              <>
                {suggestedList?.results?.length > 0 ? (
                  <div className="grid grid-cols-1 gap-12 capitalize md:grid-cols-2 lg:grid-cols-3">
                    {suggestedList.results.map(
                      (single_suggestedjob_item, index) => {
                        let salarySubType = Object.keys(
                          single_suggestedjob_item.salary_subtype
                        ).map((key) => {
                          return single_suggestedjob_item.salary_subtype[key];
                        });
                        let salaryType = Object.values(
                          single_suggestedjob_item.salary_type
                        );
                        return (
                          <div
                            className="rounded shadow-md profileCards flex flex-col justify-between"
                            key={index}
                          >
                            <div className="relative">
                              <Link
                                to={`/job-details/${single_suggestedjob_item.id}`}
                              >
                                {!single_suggestedjob_item.image &&
                                !single_suggestedjob_item.category_unverified_image &&
                                !single_suggestedjob_item.category_verified_image ? (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={defaultBuilding}
                                    alt={single_suggestedjob_item.id}
                                    title={single_suggestedjob_item.id}
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    className="object-cover w-full h-auto rounded-t-md"
                                    src={
                                      single_suggestedjob_item.image
                                        ? single_suggestedjob_item.image
                                        : single_suggestedjob_item.emp_id ==
                                          null
                                        ? single_suggestedjob_item.category_unverified_image
                                        : single_suggestedjob_item.category_verified_image
                                    }
                                    alt={single_suggestedjob_item.id}
                                    title={single_suggestedjob_item.id}
                                    loading="lazy"
                                  />
                                )}
                              </Link>

                              <Link
                                to={`/job-details/${single_suggestedjob_item.id}`}
                              >
                                <div className="p-2">
                                  <p className="mt-2 mb-3 font-semibold h-auto">
                                    {single_suggestedjob_item.job_title
                                      ? single_suggestedjob_item.job_title
                                      : single_suggestedjob_item.position_label}
                                  </p>
                                </div>
                              </Link>

                              {/* action buttons */}
                              <div className="absolute top-0 right-1">
                                <RemovePost
                                  suggestionModal={suggestionModal}
                                  setSuggestionModal={setSuggestionModal}
                                  singleJobDetail={singleJobDetail}
                                  listRefresh={suggestionRefresh}
                                />

                                <div>
                                  <button
                                    type="button"
                                    className="inline-flex justify-center w-full text-xl  text-main relative top-1 font-extrabold border shadow-lg"
                                    id="menu-button"
                                    onClick={() => {
                                      removeClick(single_suggestedjob_item.id);
                                    }}
                                  >
                                    <AiOutlineEllipsis
                                      className="text-main shadow-2xl bg-white text-xl font-extrabold h-7"
                                      size="2em"
                                    />
                                  </button>
                                </div>

                                <div
                                  className={`absolute right-0 w-44 mt-2 transition ease-out duration-100 origin-top-right bg-white rounded-md shadow-lg  focus:outline-none ${
                                    suggestionDropdown ===
                                    single_suggestedjob_item.id
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <div className="divide-y rounded-md shadow-lg">
                                    <button
                                      onClick={() => {
                                        setSuggestionDropdown(false);
                                        setSuggestionModal(true);
                                        setSingleJobDetail(
                                          single_suggestedjob_item
                                        );
                                      }}
                                      className="px-3 py-2 w-full text-left text-sm text-gray-700 hover:bg-main hover:text-white rounded-md"
                                    >
                                      Remove From The List
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="p-2">
                              <Link
                                to={`/job-details/${single_suggestedjob_item.id}`}
                              >
                                <div className="flex items-top">
                                  <div className="mt-1">
                                    <GoLocation color="gray" size="1em" />
                                  </div>
                                  <p className="mx-1 my-0 text-gray-500">
                                    {
                                      single_suggestedjob_item?.location_details
                                        ?.address
                                    }
                                  </p>
                                </div>
                              </Link>
                              <Link
                                to={`/job-details/${single_suggestedjob_item.id}`}
                              >
                                <div className="block">
                                  <Link
                                    to={`/job-details/${single_suggestedjob_item.id}`}
                                  >
                                    {salaryType[0] === "Fixed" && (
                                      <p className="my-2 font-bold text-main">
                                        {single_suggestedjob_item.max_salary
                                          ? "$" +
                                            single_suggestedjob_item.max_salary.toLocaleString()
                                          : ""}
                                        {single_suggestedjob_item.max_salary
                                          ? " /"
                                          : ""}
                                        {salarySubType[0]}
                                      </p>
                                    )}
                                    {salaryType[0] !== "Fixed" && (
                                      <p className="my-2 font-bold text-main">
                                        {single_suggestedjob_item.min_salary
                                          ? "$" +
                                            single_suggestedjob_item.min_salary.toLocaleString() +
                                            " /Hr"
                                          : ""}
                                        {single_suggestedjob_item.min_salary &&
                                        single_suggestedjob_item.max_salary
                                          ? " - "
                                          : ""}
                                        {single_suggestedjob_item.max_salary
                                          ? "$" +
                                            single_suggestedjob_item.max_salary.toLocaleString() +
                                            " /Hr"
                                          : ""}
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </Link>
                              <div className="flex flex-col xl:flex-row xl:space-x-4">
                                <button
                                  type="submit"
                                  onClick={() => {
                                    history.push(
                                      `/job-details/${single_suggestedjob_item.id}`
                                    );
                                  }}
                                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                                >
                                  View Job
                                </button>

                                <ClaimJobPost
                                  claimModal={claimModal}
                                  setClaimModal={setClaimModal}
                                  singleJobDetail={singleJobDetail}
                                  listRefresh={suggestionRefresh}
                                />

                                <button
                                  type="submit"
                                  onClick={() => {
                                    setClaimModal(true);
                                    setSingleJobDetail(
                                      single_suggestedjob_item
                                    );
                                  }}
                                  className="w-full py-1 my-2 font-semibold rounded btn-sub"
                                >
                                  Claim This Job
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <h5 className="p-3 text-gray-600 font-semibold">
                    You don't have any suggested jobs yet!!
                  </h5>
                )}

                {suggestedList?.count > 0 && (
                  <div className="mt-12">
                    <ReactPaginate
                      pageCount={suggestedPageCount}
                      onPageChange={suggestedChangePage}
                      containerClassName={
                        "paginationBttns flex justify-center col-start-2 self-end justify-self-start relative md:justify-self-center md:relative md:bottom-0 md:left-0"
                      }
                      previousLabel="<"
                      previousLinkClassName={
                        "previousBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      nextLabel=">"
                      nextLinkClassName={
                        "nextBttn cursor-pointer border-none bg-transparent py-2 px-1 text-gray-500 hover:text-purple-500 font-bold"
                      }
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="p-5 border-2 border-gray-100 rounded-md shadow-lg sidebar-outer inner-wrapper md:w-4/12">
                    <Sidebar />
                </div> */}
      </div>
      {/* <div className="block p-5 rounded custom_shadow">
        <div className="flex items-center justify-between mb-6">
          <h4 className="text-2xl font-semibold">More Profiles</h4>
          <div className="flex space-x-3 align-middle">
            <button type="submit">
              <MdKeyboardArrowLeft
                color="gray"
                size="1em"
              ></MdKeyboardArrowLeft>
            </button>
            <button type="submit">
              <MdKeyboardArrowRight
                color="gray"
                size="1em"
              ></MdKeyboardArrowRight>
            </button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 gap-5 capitalize md:grid-cols-4 lg:grid-cols-4">
            <div className="rounded shadow-md profileCards">
              <img src={staffNurse} alt="" width="100%" />
              <div className="p-2">
                <p className="my-2 font-semibold ">staff nurse and chemist</p>
                <p className="my-2 text-gray-500 ">Hospital / Company name</p>
                <div className="flex items-center">
                  <GoLocation color="gray" size="1em" />
                  <p className="mx-1 my-0 text-gray-500 ">location</p>
                </div>
                <p className="my-2 font-bold text-main">$22 /hr</p>
                <button
                  type="submit"
                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                >
                  View Profile
                </button>
              </div>
            </div>
            <div className="rounded shadow-md profileCards">
              <img src={staffNurse} alt="" width="100%" />
              <div className="p-2">
                <p className="my-2 font-semibold ">staff nurse and chemist</p>
                <p className="my-2 text-gray-500 ">Hospital / Company name</p>
                <div className="flex items-center">
                  <GoLocation color="gray" size="1em" />
                  <p className="mx-1 my-0 text-gray-500 ">location</p>
                </div>
                <p className="my-2 font-bold text-main">$22 /hr</p>
                <button
                  type="submit"
                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                >
                  View Profile
                </button>
              </div>
            </div>
            <div className="rounded shadow-md profileCards">
              <img src={staffNurse2} alt="" width="100%" />
              <div className="p-2">
                <p className="my-2 font-semibold">staff nurse and chemist</p>
                <p className="my-2 text-gray-500">Hospital / Company name</p>
                <div className="flex items-center">
                  <GoLocation color="gray" size="1em" />
                  <p className="mx-1 my-0 text-gray-500 ">location</p>
                </div>
                <p className="my-2 font-bold text-main">$22 /hr</p>
                <button
                  type="submit"
                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                >
                  View Profile
                </button>
              </div>
            </div>
            <div className="rounded shadow-md profileCards">
              <img src={staffNurse2} alt="" width="100%" />
              <div className="p-2">
                <p className="my-2 font-semibold">staff nurse and chemist</p>
                <p className="my-2 text-gray-500">Hospital / Company name</p>
                <div className="flex items-center">
                  <GoLocation color="gray" size="1em" />
                  <p className="mx-1 my-0 text-gray-500 ">location</p>
                </div>
                <p className="my-2 font-bold text-main">$22 /hr</p>
                <button
                  type="submit"
                  className="w-full py-1 my-2 font-semibold rounded btn-main"
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EmployerJobList;
